import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ɵConsole,
  ChangeDetectorRef,
  AfterContentChecked,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LoadingService, LoaderType } from "src/app/shared/services/loading";
import { StockTypesService } from "src/app/features/lookups/services/stock-types";
import { AuthService } from "src/app/core/auth/auth.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { LookupDto } from "src/app/features/lookups/models/lookup";
import { ConfirmService } from "src/app/shared/services/modal.service";
import { DeleteModal, DisplayModal } from "src/app/shared/models/modal";
import { StockTypeDto } from "src/app/features/lookups/models/stock-type";
import { Language } from "src/app/core/models/enums/language";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { Utils } from "src/app/shared/services/utils";
import { _DocumentType } from "src/app/core/models/enums/document-type";
import { numbersOnlyValidator } from "src/app/shared/services/custom-validators";
import { StocksService } from "src/app/features/stock/services/stocks.service";
import {
  AvailableStock,
  AvailableStocksVm,
} from "src/app/features/stock/models/available-stocks";
import { StockSliderComponent } from "src/app/features/stock/components/stock-slider/stock-slider.component";
import { TransactionLabelDetails } from "src/app/features/stock/models/transaction-label-details";
import { PopulateCmd } from "src/app/features/stock/models/populate.cmd";
import { CoupounService } from "../../services/coupoun.service";
import { Location } from '@angular/common';

@Component({
  selector: "mac-manage-coupon-management",
  templateUrl: "./manage-coupon-management.component.html",
  styleUrls: ["./manage-coupon-management.component.scss"],
  providers: [StockTypesService, StocksService],
})
export class ManageCouponManagementComponent implements OnInit, AfterContentChecked {
  populate: boolean;
  selectedStock: number[];
  availableStock: AvailableStock;
  stockTypes: StockTypeDto[];
  documentType: _DocumentType;
  form: FormGroup;
  documentTemplateVersions = [1, 2];
  lang: Language;
  availableStockHTML: string;
  reasons: LookupDto[] = [
    { id: -1, titles: ["حذف نهائي", "Permanent Delete"] },
    { id: 1, titles: ["آخر", "Other"] },
  ];
  transactionDetails: TransactionLabelDetails = {};
  status: boolean = false;
  couponId: string;
  coupon: any;

  @ViewChild(StockSliderComponent) slider: StockSliderComponent;

  get _DocumentType() {
    return _DocumentType;
  }
  get customReason() {
    return this.form.get("customReason");
  }
  get reason() {
    return this.form.get("reason");
  }
  get documentTemplateVersion() {
    return this.form.get("documentTemplateVersion");
  }
  get fromRange() {
    return this.form.get("fromRange");
  }
  get name() {
    return this.form.get("name");
  }
  get discount() {
    return this.form.get("discount");
  }

  constructor(
    private loader: LoadingService,
    private alert: AlertService,
    private router: Router,
    private translate: TranslateService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private stockTypesService: StockTypesService,
    private stocksService: StocksService,
    private confirmation: ConfirmService,
    private couponService: CoupounService,
    private _location: Location,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.coupon = JSON.parse(localStorage.getItem("EditCoupon"));
    this.lang =
      this.translate.currentLang == "ar" ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(
      (t) => (this.lang = t.lang == "ar" ? Language.Arabic : Language.English)
    );
    this.couponId = this.route.snapshot.paramMap.get("id");
    this.populate = (this.couponId && !!this.coupon) || false;
    //console.log(this.populate, this.coupon)
    this.loader.load(LoaderType.Nav);
    this.formInit();

    this.transactionDetails.alertClass = this.populate ? "success" : "danger";
    this.transactionDetails.operation = this.populate
      ? "STK_TITLE_POPULATE"
      : "STK_TITLE_DEPOPULATE";

    this.stockTypesService
      .get(this.lang)
      .then((result) => {
        this.stockTypes = result.list;
      })
      .finally(() => this.loader.load(LoaderType.Nav, false));

    if (!this.auth.currentUser.isSystemAccount) console.warn("not allowed!");
  }
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  onStatusChange(checked: boolean) {
    this.status = checked;
  }

  onSubmit() {
    if (this.populate) {
      this.couponService
        .update({
          id: this.couponId,
          name: this.name.value,
          discount: this.discount.value,
          status: this.status,
        })
        .then((m) => {
          if (m) {
            this._location.back();
          }
        });
    } else {
      this.couponService
        .create({
          name: this.name.value,
          discount: this.discount.value,
          status: this.status,
        })
        .then((m) => {
          if (m) {
            this._location.back();
          }
        });
    }
  }

  private formInit() {
    this.form = this.fb.group({
      name: [this.populate ? this.coupon.name : "", [Validators.required]],
      discount: [
        this.populate ? this.coupon.discount : "",
        [Validators.required, Validators.max(100), Validators.maxLength(3), numbersOnlyValidator],
      ],
    });
    if (this.coupon != null) {
      this.status = this.coupon.status;
    }
  }

  onCancel() {
    this.form.reset();
    this._location.back();
  }
}

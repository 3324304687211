import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';

import { merge, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { StockTotalsDataSource } from './../../helpers/stocks-totals-datasource';
import { StocksService } from '../../services/stocks.service';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { AccountStocksTotalsDto } from '../../models/stocks-totals';
import { exportToExcel } from 'src/app/shared/helpers/export-to-excel';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/features/accounts/services/accounts.service';
//import { StockType } from 'src/app/core/models/enums/stock-type';

@Component({
   selector: 'mac-stocks-totals',
   templateUrl: './stocks-totals.component.html',
   styleUrls: ['./stocks-totals.component.scss'],
   providers: [StocksService, StockTypesService, AccountsService]
})
export class StocksTotalsComponent implements OnInit {
   moment = moment;
   displayedColumns: string[] = ['accountTitles', 'received', 'transferedUndone', 'returned', 'transfered', 'issued', 'remainingStocks'];
   dataSource: StockTotalsDataSource;
   stockTypes: StockTypeDto[];
   accounts: LookupDto[];
   accountId: number;

   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   @ViewChild(MatSort, { static: true }) sort: MatSort;
   @ViewChild('filter', { static: true }) filter: ElementRef<HTMLInputElement>;
   @ViewChild('filterBtn') filterBtn: ElementRef;

   constructor(private loader: LoadingService,
      private stocksService: StocksService,
      private stockTypesService: StockTypesService,
      public auth: AuthService,
      private accountsService: AccountsService,
      private route: ActivatedRoute,
      private router: Router,
      private translate: TranslateService) { }

   ngOnInit() {
      this.loader.load(LoaderType.Nav);
      //this.accounts = this.auth.currentUser.isSystemAccount ? await (await this.accountsService.getChildrenAccountsLookup(0, false)).list : [];
      this.accountId = Number(this.route.snapshot.paramMap.get('accountId'));
      this.accountId = Number(this.accountId != 0 ? this.accountId : this.auth.currentUser.accountId);

      this.stockTypesService.getAllowed(this.auth.currentUser.accountId)
         .then(result => /*this.stockTypes = result.list.filter(x => x.id == StockType.License)*/this.stockTypes = result.list);


      // set paginator page size
      this.sort.active = 'accountTitles'; // pre-defined sort col
      this.sort.direction = 'asc';
      this.paginator.pageSize = 25;

      this.dataSource = new StockTotalsDataSource(this.stocksService, this.loader);
      this.dataSource.loadStockTotals(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, '', this.accountId);
      //this.dataSource.counter$.subscribe(counter => this.recordsCount = counter);
   }
   async ngAfterViewInit() {
      try {
         this.accounts = await (await this.accountsService.getChildrenAccountsLookup(!this.auth.currentUser.isSystemAccount ? this.auth.currentUser.accountId : 0)).list;
      } catch { }

      this.dataSource.counter$
         .pipe(
            tap((count) => {
               this.paginator.length = count;
            })
         ).subscribe();

      // when paginator event is invoked, retrieve the related data
      merge(this.paginator.page, this.sort.sortChange)
         .pipe(
            tap(() => this.dataSource.loadStockTotals(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, this.filter.nativeElement.value.trim(), this.accountId))
         ).subscribe(() => this.loader.load(LoaderType.Body));

      merge(fromEvent(this.filter.nativeElement, 'search'), fromEvent(this.filterBtn.nativeElement, 'click'))
         .pipe(
            tap(() => {
               this.paginator.pageIndex = 0;
               this.dataSource.loadStockTotals(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, this.filter.nativeElement.value.trim(), this.accountId)
            })
         ).subscribe(() => this.loader.load(LoaderType.Body));

      this.dataSource.connect().subscribe(() => {
         this.router.navigate([`/${this.translate.currentLang}/stocks/totals/${this.accountId}`], { replaceUrl: true });
         //this.location.replaceState(`/${this.translate.currentLang}/security/users/list/${this.accountId}`);
      });
   }

   // recordsCount: number;

   onExportToExcel(all: boolean = true) {
      this.loader.load(LoaderType.Body);
      if (all)//todo: check params
         this.stocksService.getStockTotals(0, this.paginator.length, this.sort.active, this.sort.direction)
            .toPromise()
            .then(data => {
               this.exportToExcel(data.accountsStocksTotals);
            }).finally(() => this.loader.load(LoaderType.Body, false));
      else
         this.dataSource.connect().subscribe(data => {
            this.exportToExcel(data);
            this.loader.load(LoaderType.Body, false);
         }).unsubscribe();
   }

   private exportToExcel(data: AccountStocksTotalsDto[]) {
      const wscols = [
         { width: 24 },
         { width: 16 },
         { width: 14 },
         { width: 14 },
         { width: 14 },
         { width: 14 },
         { width: 14 },
         { width: 14 }
      ];
      const autofilter = { ref: 'A1:H1' };
      const rows: string[][] = [];

      const headers = [
         this.translate.instant('ACC_FIELD_ACCOUNT_TITLE'),
         this.translate.instant('LKP_FIELD_STOCK_TYPE'),
         this.translate.instant('STK_LABEL_RECEIVED'),
         this.translate.instant('STK_LABEL_TRANSFERED'),
         this.translate.instant('STK_LABEL_ISSUED'),
         this.translate.instant('STK_LABEL_RETURNED'),
         this.translate.instant('STK_LABEL_TRANSFERED_UNDONE'),
         this.translate.instant('STK_LABEL_REMAINING')
      ];

      rows.push(headers);
      for (let row of data) {
         for (let stockTypeData of row.stockTypesStocksTotals) {
            rows.push([
               row.accountTitles[0],
               //this.stockTypes.find(st => st.id == stockTypeData.stockTypeId) ? this.stockTypes.find(st => st.id == stockTypeData.stockTypeId).titles[0] : "N/A",
               stockTypeData.stockType.titles[0],
               stockTypeData.received.toString(),
               stockTypeData.transfered.toString(),
               stockTypeData.issued.toString(),
               stockTypeData.returned.toString(),
               stockTypeData.transferedUndone.toString(),
               stockTypeData.remainingStocks.toString()
            ]);
         }
      }

      exportToExcel(rows, wscols, autofilter, this.translate.currentLang == 'ar', 'Stock-Totals');
   }

   filterWithAccount(id) {
      //console.log(id);
      this.accountId = id;
      //this.paginator.pageIndex = 0;
      this.paginator.page.emit({ pageIndex: 0, pageSize: this.paginator.pageSize, length: this.paginator.length });
      // this.dataSource.loadUsers(0, this.paginator.pageSize, this.sort.active, this.sort.direction, '', this.accountId);

   }

}


import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component } from '@angular/core';
import { filter, map, throwIfEmpty } from 'rxjs/operators';
import { BreadcrumbService } from './shared/services/breadcrumb.service';
import { LoadingService } from './shared/services/loading';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './core/auth/auth.service';
import { StaticConfig } from './features/lookups/services/config';
// import { AlertService2 } from './shared/services/alert.service2';
// import * as AppConfig from '../assets/config.json'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [StaticConfig]
})
export class AppComponent {

  loading: boolean = true;
  styleLoading: boolean = true;

  constructor(
    private loader: LoadingService,
    private titleService: Title,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService){
    //private config: StaticConfig) {

    this.translate.setDefaultLang('ar');
    //this.config.get();
    //const _lang = this.route.snapshot.paramMap.get('lang');
    ////console.log(_lang);
    //this.translate.use(_lang);
  }

  ngOnInit() {
    // const _config = config.get('apiBaseUrl');
    //console.log(AppConfig.apiBaseUrl);
    //await this.config.get();
    AuthService.authActivity.subscribe(loggedin => {
      // //console.warn(loggedin);
      // if (!loggedin) {
      //   //console.log('should log out now');
      // }
    });

    // this.loader.styleLoading.subscribe(status => {
    //   this.styleLoading = status;
    // })

    LoadingService.style.subscribe(status => {
      this.styleLoading = status;
    });

    this.loader.navLoading.subscribe(status => {
      this.loading = status;
    });

    // this.router
    //   .events.pipe(
    //     filter(event => event instanceof NavigationEnd),
    //     map(() => {
    //       //console.log('ev');
    //       let _titles = [];
    //       let _titlesLinks: string[][] = [[]];
    //       let child = this.route.firstChild;
    //       let _url = `/${this.translate.currentLang}/`;
    //       let level = 0;

    //       const _lang = child.snapshot.paramMap.get('lang');
    //       //console.log(_lang, this.translate.currentLang);
    //      // if (this.translate.currentLang != _lang)
    //         this.translate.use(_lang);

    //       //console.log(child);
    //       if (this.router.url == _url) {
    //         const transalted = this.translate.instant(child.snapshot.firstChild.data['title']);
    //         //console.log(transalted)
    //         _titles.push(transalted);
    //       } else if (this.router.url == '/login') {
    //         const transalted = this.translate.instant(child.snapshot.data['title']);
    //         //console.log(transalted)
    //         // this.translate.get(child.snapshot.data['title'])
    //         // .subscribe(val => {
    //         //   //console.log(val);
    //         //   _titles.push(val);
    //         // })
    //         _titles.push(this.translate.instant(child.snapshot.data['title']));
    //       }
    //       else {

    //         while (child.firstChild) {
    //           child = child.firstChild;
    //           ////console.log(child);
    //           if (child.snapshot.data['title']) {
    //             if (level <= 1)
    //               _titles.push(this.translate.instant(child.snapshot.data['title']));

    //             _url += child.snapshot.url[0] + '/';
    //             _titlesLinks.push([this.translate.instant(child.snapshot.data['title']), _url]);
    //           }
    //           level++;
    //         }
    //       }
    //       //console.warn(_titlesLinks);
    //       this.breadcrumbService.setLinks(_titlesLinks);
    //       return _titles.join(' | ');
    //     })
    //   ).subscribe((ttl: string) => {
    //     //console.warn(ttl);
    //     this.titleService.setTitle(ttl);
    //   });
  }
  ngAfterViewInit() {
  //  AlertService2.init();
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { merge, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';

import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { transactionTypeLocale } from '../../models/transaction-type.enum';
import { TransactionsDataSource } from '../../helpers/transactions-datasource';
import { TransactionDto } from '../../models/transactions';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { exportToExcel } from 'src/app/shared/helpers/export-to-excel';
import { StocksService } from '../../services/stocks.service';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { Language } from 'src/app/core/models/enums/language';
import { TransactionType } from 'src/app/core/models/enums/transaction-type';
//import { StockType } from 'src/app/core/models/enums/stock-type';
import { LicTransactionsPrintComponent } from '../../components/lic-transactions-print/lic-transactions-print.component';
import { TrpTransactionsPrintComponent } from '../../components/trp-transactions-print/trp-transactions-print.component';
import { _DocumentType } from 'src/app/core/models/enums/document-type';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AccountsService } from 'src/app/features/accounts/services/accounts.service';
import { AccountDto } from 'src/app/features/accounts/models/account';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { PaginationQuery } from 'src/app/features/issuing/_shared/models/issuing.models';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from 'src/app/shared/services/utils';
import { AccountType } from 'src/app/core/models/enums/account-type';

@Component({
   selector: 'mac-transactions',
   templateUrl: './transactions.component.html',
   styleUrls: ['./transactions.component.scss'],
   providers: [StocksService, StockTypesService, AccountsService]
})
export class TransactionsComponent implements OnInit {
   moment = moment;
   transactionTypeLocale = transactionTypeLocale;
   displayedColumns: string[] = ['created', 'fromAccountTitles', 'toAccountTitles', 'fromRange', 'toRange', 'count', 'transactionType', 'stockType', 'action'];
   dataSource: TransactionsDataSource;
   stockTypes: StockTypeDto[];
   accounts: LookupDto[];
   transactionTypes: LookupDto[] = [];
   searchForm: FormGroup;
   lang: Language;
   query: any = null;

   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   @ViewChild(MatSort, { static: true }) sort: MatSort;
   @ViewChild('filter', { static: true }) filter: ElementRef;
   @ViewChild('filterBtn') filterBtn: ElementRef;

   @ViewChild(MatSelect, { static: true }) stockType: MatSelect;

   @ViewChild('licPrintElm') licPrintElm: LicTransactionsPrintComponent;
   @ViewChild('trpPrintElm') trpPrintElm: TrpTransactionsPrintComponent;


   get TransactionType() { return TransactionType; };
   get Object() { return Object; };
   //get StockType() { return StockType; };

   constructor(private loader: LoadingService,
      private stocksService: StocksService,
      private stockTypesService: StockTypesService,
      private translate: TranslateService,
      public auth: AuthService,
      private fb: FormBuilder,
      private accountsService: AccountsService,
      private router: Router,
      private route: ActivatedRoute
   ) { }

   ngOnInit() {
      this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
      this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

      this.formInit();

      this.loader.load(LoaderType.Nav);
      this.stockTypesService.getAllowed().then(result => {
         this.stockTypes = result.list;
         // 
         //this.stockTypes = result.list;
         //this.stockTypes.unshift({ id: 0, titles: [["الكل", "All"][this.lang]] });
      });


      //console.log(Object.keys(TransactionType));

      const arr = Object.keys(TransactionType).reduce((arr, key) => {
         if (!arr.includes(key) && isNaN(<number><any>key)) {
            arr.push(TransactionType[key]);
         }
         return arr;
      }, []);

      // const x: LookupDto = {id}
      this.transactionTypes = arr.map(elm => <LookupDto>{
         id: elm,
         titles: [this.translate.instant(transactionTypeLocale(elm))]
      }).sort((a, b) => b.id - a.id);

      this.transactionTypes = this.transactionTypes.filter(x => x.id != TransactionType.Issue)

      if (!this.auth.currentUser.isSystemAccount)
         this.transactionTypes = this.transactionTypes.filter(x => x.id != TransactionType.Populate && x.id != TransactionType.Depopulate)

      //console.log(this.transactionTypes);

      // arr.forEach(element => {

      //    //console.log(TransactionType[element]);
      //    //console.log(element);
      // });

      // //console.log(Object.keys(TransactionType).reduce((arr, key) => {
      //    if (!arr.includes(key) && !isNaN(<number><any>key)) {
      //       arr.push(TransactionType[key]);
      //    }
      //    return arr;
      // }, []));

      // //console.log(TransactionType.Depopulate);
      // //console.log(TransactionType[TransactionType.Depopulate]);

      // Object.keys(TransactionType).forEach(x => {
      //    //console.log(x);
      //    //console.log(TransactionType[x]);
      // })

      //if system account, accounts will be fetched from server, otherwise current account details will be fetched from local auth service
      if (this.auth.currentUser.isSystemAccount) {
         this.accountsService.getChildrenAccountsLookup(0, false)
            .then(result => {
               this.accounts = result.list;// populateAccountsSelect(result.list);

               //add system accounts (stock, printing-press)
               this.accounts.unshift({ id: AccountType.PrimaryStock, titles: [this.translate.instant('STK_LABEL_PRIMARY_STOCK')] });
               this.accounts.unshift({ id: AccountType.PrintingPress, titles: [this.translate.instant('STK_LABEL_PRINTING_PRESS')] });

            }).catch(e => this.searchForm.get('accountId').disable());
      } else {
         this.accounts = [<LookupDto>{ id: this.auth.currentUser.accountId, titles: [this.auth.currentUser.accountTitles[this.lang]] }];
         this.searchForm.patchValue({ accountId: this.auth.currentUser.accountId });
         this.searchForm.get('accountId').disable();
      }

      this.route.queryParamMap.subscribe(p => {
         //console.warn(p);

         if (!p.has('pageIndex')) {
            //console.warn('ready to reload!');
            this.advancedSearchMode = false;
            this.activeFilters = false;
            this.filter.nativeElement.value = '';
            this.paginator.page.emit({ pageIndex: 0, pageSize: this.paginator.pageIndex, length: this.paginator.length });
         }
      });

      //let query = null;

      if (this.route.snapshot.queryParams.query) {
         this.query = this.route.snapshot.queryParams.query;
         this.filter.nativeElement.value = this.query;
      }
      else if (this.route.snapshot.queryParams.accountId
         || this.route.snapshot.queryParams.stockTypeId
         || this.route.snapshot.queryParams.transactionType
         || this.route.snapshot.queryParams.fromRange
         || this.route.snapshot.queryParams.toRange
         || this.route.snapshot.queryParams.fromDate
         || this.route.snapshot.queryParams.toDate) {
         this.activeFilters = true;
         this.searchForm.patchValue(this.route.snapshot.queryParams);

         //fix int for ng-select
         this.searchForm.patchValue({ accountId: !isNaN(this.route.snapshot.queryParams.accountId) ? parseInt(this.route.snapshot.queryParams.accountId) : null });

         // this.searchForm.patchValue({countryId: 2});
         // //console.log(this.searchForm.value);
         this.query = this.searchForm.value;
      }

      this.fetchData(this.query);

      // this.sort.active = 'created';
      // this.sort.direction = 'desc';
      // this.paginator.pageSize = 25;
      // this.dataSource = new TransactionsDataSource(this.stocksService, this.loader);
      // this.dataSource.loadTransactions(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
   }


   private fetchData(query) {
      this.paginator.pageIndex = this.route.snapshot.queryParams.pageIndex ? this.route.snapshot.queryParams.pageIndex : 0;
      this.paginator.pageSize = this.route.snapshot.queryParams.pageSize ? this.route.snapshot.queryParams.pageSize : 25;
      this.sort.active = this.route.snapshot.queryParams.sort ? this.route.snapshot.queryParams.sort : 'created';
      this.sort.direction = this.route.snapshot.queryParams.dir ? this.route.snapshot.queryParams.dir : 'desc';
      this.dataSource = new TransactionsDataSource(this.stocksService, this.loader, this.translate);
      this.dataSource.loadTransactions({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction }, query);
   }

   ngAfterViewInit() {
      this.dataSource.counter$
         .pipe(
            tap((count) => {
               this.paginator.length = count;
            })
         ).subscribe();

      merge(this.paginator.page, this.sort.sortChange/*, this.stockType.valueChange*/)
         .pipe(
            tap(() => {
               this.dataSource.loadTransactions({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction },
                  this.advancedSearchMode || this.activeFilters ? this.searchForm.value : this.filter.nativeElement.value.trim())
            })

            //this.dataSource.loadTransactions(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, this.filter.nativeElement.value.trim(), this.stockType.value))
         ).subscribe(() => this.loader.load(LoaderType.Body));


      merge(fromEvent(this.filter.nativeElement, 'search'), fromEvent(this.filterBtn.nativeElement, 'click'))
         .pipe(
            tap(() => {
               this.advancedSearchMode = false;
               this.activeFilters = false;
               this.searchForm.reset();
               this.paginator.pageIndex = 0;
               this.dataSource.loadTransactions({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction }, this.filter.nativeElement.value.trim())
            })
         ).subscribe(() => this.loader.load(LoaderType.Body));

      // fromEvent(this.filter.nativeElement, 'change')
      //    .pipe(
      //       tap(() => {
      //          this.paginator.pageIndex = 0;
      //          this.dataSource.loadTransactions(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, this.filter.nativeElement.value.trim(), this.stockType.value)
      //       })
      //    ).subscribe(() => this.loader.load(LoaderType.Body));

      this.dataSource.connect()
         .subscribe(() => {
            let pagination: PaginationQuery = { pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction };
            let query = this.activeFilters ? this.searchForm.value : this.filter.nativeElement.value.trim();
            this.query = query;

            if (this.activeFilters)
               this.router.navigate(['.'], { relativeTo: this.route, queryParams: { ...pagination, ...query }, replaceUrl: true });
            else
               this.router.navigate(['.'], { relativeTo: this.route, queryParams: { ...pagination, query }, replaceUrl: true });
         });
   }

   print(row: TransactionDto) {
      switch (row.stockType.documentType) {
         case _DocumentType.DrivingLicense:
            return this.licPrintElm.print(row);

         case _DocumentType.TripTikDocument:
            return this.trpPrintElm.print(row);
      }
   }

   onExportToExcel(all: boolean = true) {
      this.loader.load(LoaderType.Body);
      if (all)
         this.stocksService[(this.query == null || typeof this.query == 'string') ? 'getTransactions' : 'searchTransactions']({ pageIndex: 0, pageSize: this.paginator.length, sort: this.sort.active, dir: this.sort.direction }, <any>this.query)
            .toPromise()
            .then(data => {
               this.exportToExcel(data.stockTransactions);
            }).finally(() => this.loader.load(LoaderType.Body, false));
      else
         this.dataSource.connect().subscribe(data => {
            this.exportToExcel(data);
            this.loader.load(LoaderType.Body, false);
         }).unsubscribe();
   }

   private exportToExcel(data: TransactionDto[]) {
      const wscols = [
         { width: 14 },
         { width: 24 },
         { width: 24 },
         { width: 14 },
         { width: 14 },
         { width: 14 },
         { width: 18 },
         { width: 18 }
      ];
      const autofilter = { ref: 'A1:H1' };
      const rows: string[][] = [];

      const headers = [
         this.translate.instant('_LABEL_DATE'),
         `${this.translate.instant('_LABEL_FROM')} ${this.translate.instant('ACC_LABEL_ACCOUNT')}`,
         `${this.translate.instant('_LABEL_TO')} ${this.translate.instant('ACC_LABEL_ACCOUNT')}`,
         `${this.translate.instant('_LABEL_FROM')} ${this.translate.instant('_LABEL_SERIAL')}`,
         `${this.translate.instant('_LABEL_TO')} ${this.translate.instant('_LABEL_SERIAL')}`,
         this.translate.instant('STK_LABEL_QUANTITY'),
         this.translate.instant('STK_LABEL_TRANSACTION_TYPE'),
         this.translate.instant('LKP_FIELD_STOCK_TYPE')
      ];

      rows.push(headers);
      for (let row of data) {
         rows.push([
            moment(row.created).format('DD/MM/YYYY'),
            row.fromAccountTitles ? row.fromAccountTitles[0] :
               this.translate.instant(row.transactionType == TransactionType.Populate ?
                  'STK_LABEL_PRINTING_PRESS' : 'STK_LABEL_PRIMARY_STOCK'),
            row.toAccountTitles ? row.toAccountTitles[0] :
               this.translate.instant(row.transactionType == TransactionType.Depopulate ?
                  'STK_LABEL_PRINTING_PRESS' : 'STK_LABEL_PRIMARY_STOCK'),
            row.fromRange.toString(),
            row.toRange.toString(),
            (row.toRange - row.fromRange + 1).toString(),
            this.translate.instant(transactionTypeLocale(row.transactionType)),
            row.stockType.titles[0]
         ]);
      }

      exportToExcel(rows, wscols, autofilter, this.translate.currentLang == 'ar', 'Stock-Transactions');
   }

   toggleAdvancedSearch() {
      this.advancedSearchMode = !this.advancedSearchMode;
      this.filter.nativeElement.value = '';
   }


   onSubmit() {
      if (this.fromDate.value && typeof this.fromDate.value == 'object')
         this.fromDate.setValue(Utils.convertToServerDateTimeString(this.fromDate.value));

      if (this.toDate.value && typeof this.toDate.value == 'object')
         this.toDate.setValue(Utils.convertToServerDateTimeString(this.toDate.value));

      //swap dates
      if (this.fromDate.value && this.toDate.value && new Date(this.fromDate.value) > new Date(this.toDate.value)) {
         let _temp = this.fromDate.value;
         this.fromDate.setValue(this.toDate.value);
         this.toDate.setValue(_temp);
      }

      // //console.log(this.searchForm.value);

      this.activeFilters = true;


      this.paginator.pageIndex = 0;
      this.paginator.page.emit({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, length: this.paginator.length });
   }

   get fromDate() { return this.searchForm.get('fromDate'); };
   get toDate() { return this.searchForm.get('toDate'); };
   // searchForm: FormGroup;
   activeFilters: boolean;
   advancedSearchMode: boolean;

   resetSearch() {
      this.advancedSearchMode = false;
      if (this.activeFilters) {
         this.activeFilters = false;
         this.paginator.pageIndex = 0;
         this.paginator.page.emit({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, length: this.paginator.length });
      }

      this.activeFilters = false;

      setTimeout(() => {
         this.searchForm.reset();
         this.query = null;
      }, 500);
   }

   private formInit() {
      //use same validators to filter inputs values
      this.searchForm = this.fb.group({
         fromRange: [''],   //regex to match english letters only
         toRange: [''],
         transactionType: [''],
         stockTypeId: [''],
         accountId: [null],
         fromDate: [''],
         toDate: ['']
      });
   }
}
import { DefaultAlertAction } from './../../../../shared/models/default-alert-action';
import { nonEnglishCharactersValidator, nonArabicCharactersValidator, numbersOnlyValidator, comparePasswords } from 'src/app/shared/services/custom-validators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from './../../../../core/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { UsersService } from '../../../security/services/users.service';
import * as _ from 'lodash'
import { Utils } from 'src/app/shared/services/utils';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { UserProfileVm } from 'src/app/features/security/models/user';

@Component({
  selector: 'mac-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  providers: [UsersService]
})
export class UserProfileComponent implements OnInit {

  imageURL: string;
  defaultAvatar: string = 'assets/images/user.svg';
  updateMode: boolean;
  changingPassword: boolean;
  cashedProfileData: {};
  profileForm: FormGroup;

  constructor(private fb: FormBuilder,
    private usersService: UsersService,
    private authService: AuthService,
    private alert: AlertService,
    private loader: LoadingService) { }

  ngOnInit() {
    this.formInit();
    this.profileForm.disable();
    this.fetchUserProfile();
  }

  fetchUserProfile() {
    this.loader.load(LoaderType.Nav);
    this.usersService.getUserProfile().then(p => {
      ////console.log(p);
      if (p.avatar)
        this.imageURL = `data:image/png;base64,${p.avatar}`;

      this.cashedProfileData = {
        names: p.names,
        username: p.username,
        email: p.email,
        phoneNumber: p.phoneNumber,
        avatar: this.imageURL
      }
      this.profileForm.patchValue(this.cashedProfileData);
    }).finally(() => this.loader.load(LoaderType.Nav, false));
  }
 
  get names() { return this.profileForm.get('names') as FormArray; }
  get username() { return this.profileForm.get('username'); }
  get email() { return this.profileForm.get('email'); }
  get phoneNumber() { return this.profileForm.get('phoneNumber'); }
  get avatar() { return this.profileForm.get('avatar'); }
  get password() { return this.profileForm.get('password'); }
  get newPassword() { return this.profileForm.get('newPass.password'); }
  get newPasswordConfirm() { return this.profileForm.get('newPass.passwordConfirm'); }

  changePassword() {
    this.changingPassword = !this.changingPassword;
    this.changingPassword ?
      this.password.enable() :
      this.password.disable();
  }

  update() {
    this.updateMode = !this.updateMode;
    this.updateMode ? this.profileForm.enable() : this.profileForm.disable();
    this.username.disable();
    this.password.disable();
    this.changingPassword = false;
    !this.updateMode ? this.profileForm.patchValue(this.cashedProfileData) : null;
    this.imageURL = !this.updateMode ? this.cashedProfileData["avatar"] : this.imageURL;
  }

  onSubmit() {
    //const img = this.getImageString();
 
    if (!this.changingPassword) {
      this.password.setErrors(null);
      this.newPassword.setErrors(null);
      this.newPasswordConfirm.setErrors(null);
    }

    if (!this.profileForm.valid)
      return Utils.validateAllFormFields(this.profileForm);

    this.loader.load(LoaderType.Body);
    let profile = _.pick(this.profileForm.value, ['id', 'username', 'names', 'email', 'phoneNumber', 'avatar']) as UserProfileVm;

    if (this.changingPassword) {
      profile.password = this.password.value;
      profile.newPassword = this.newPassword.value;
    }

    profile.avatar = this.imageURL != this.defaultAvatar ? this.imageURL : null;

    this.usersService.updateUserProfile(profile)
      .then(r => {
        if (r) {
          AuthService.userMeta.next({ id: profile.id, name: profile.names[0], avatar: profile.avatar });
          this.alert.success(DefaultAlertAction.UPDATING);
          this.updateMode = false;
          this.profileForm.disable();
          this.username.disable();
          this.password.disable();
          this.changingPassword = false;
        } else
          this.alert.failure(DefaultAlertAction.UPDATING);
      })
      .finally(() => this.loader.load(LoaderType.Body, false));
  }

  preview($event) {
    ////console.warn($event);
    //const img = document.getElementById("licensePhoto") as HTMLImageElement;
    const tgt = $event.target || window.event.srcElement,
      files = tgt.files;

    // FileReader support
    if (FileReader && files && files.length) {
      const fr = new FileReader();
      fr.onload = () => {
        this.imageURL = fr.result.toString();
        //img.setAttribute("src", fr.result.toString());
      };
      fr.readAsDataURL(files[0]);
    }
    // Not supported
    else {
      //console.error('not supported!');
    }
  }

  getImageString() {
    if (this.imageURL == this.defaultAvatar)
      return null;

    const img: HTMLImageElement = document.getElementById("img") as HTMLImageElement;
    const canvas = document.createElement("canvas");
    canvas.getContext("2d").drawImage(img, 0, 0, 300, 300);
    return canvas.toDataURL("image/png", 1);
  }

  removeImage() {
    this.imageURL = this.defaultAvatar;
  }

  private formInit() {
    this.profileForm = this.fb.group({
      names: this.fb.array([
        ['', [Validators.required, nonEnglishCharactersValidator, Validators.minLength(3), Validators.maxLength(100)]],
        ['', [Validators.required, nonArabicCharactersValidator, Validators.minLength(3), Validators.maxLength(100)]]
      ]),
      username: [{ value: '', disabled: true }],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, numbersOnlyValidator]],
      avatar: [null],
      password: ['', [Validators.required]],
      newPass: this.fb.group({
        password: ['', [Validators.required, Validators.minLength(6)]],  //todo: regex for password pattern
        passwordConfirm: ['']
      }, { validator: comparePasswords })
    });

    this.imageURL = this.defaultAvatar;
    this.updateMode = false;
    this.changingPassword = false;
  }
}

import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, FormArray } from '@angular/forms';
import { CitiesService } from 'src/app/features/lookups/services/cities';
import { CountriesService } from 'src/app/features/lookups/services/countries';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { AccountTypesService } from 'src/app/features/lookups/services/account-types';
import { AccountsService } from '../../services/accounts.service';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { UsersService } from 'src/app/features/security/services/users.service';
import { Utils } from 'src/app/shared/services/utils';
import { nonArabicCharactersValidator, nonEnglishCharactersValidator, usernameValidator, numbersOnlyValidator, comparePasswords, phoneNumberValidator, emailValidator } from 'src/app/shared/services/custom-validators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Language } from 'src/app/core/models/enums/language';
import { PostAccountCmd, AccountMetaDto, AccountDto } from '../../models/account';
import { AuthService } from 'src/app/core/auth/auth.service';
import { populateAccountsSelect } from 'src/app/shared/helpers/populate-accounts-select';
import { AccountType } from 'src/app/core/models/enums/account-type';
import { FileLoaderService } from 'src/app/shared/services/file-loader.service';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { _DocumentType } from 'src/app/core/models/enums/document-type';
import { UserDto, UserMetaVm } from 'src/app/features/security/models/user';
import { SecurityService } from 'src/app/features/security/services/security.service';
//import { StockType } from 'src/app/core/models/enums/stock-type';

@Component({
  selector: 'mac-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss'],
  providers: [
    CountriesService,
    CitiesService,
    StockTypesService,
    AccountTypesService,
    AccountsService,
    UsersService,
    FileLoaderService
  ]
})
export class ManageAccountComponent implements OnInit {
  dto: AccountDto;
  accountForm: FormGroup;
  countries: any[];
  cities: any[];
  _stockTypes: StockTypeDto[] = [];
  uroStockTypes: StockTypeDto[] = [];
  accountTypes: any[];
  //parentAccounts: any[];
  accountId: number;
  lang: Language;
  loading: boolean;
  //langIndex: number;
  _parentAccounts: AccountMetaDto[] = [];
  users: UserDto[] = [];
  documentTypes: _DocumentType[];
  accountTypeId: number;

  badgeClasses = [
    'branches bg-primary',
    'agent bg-success',
    'distributor bg-danger',
    'Sub-Distributor bg-orange'
  ];

  get AccountType() { return AccountType; };
  get accountType() { return this.accountForm.get('accountTypeId'); }
  //get parentAccount() { return this.accountForm.get('parentId'); }
  get titles() { return this.accountForm.get('titles') as FormArray }
  get country() { return this.accountForm.get('countryId'); }
  get city() { return this.accountForm.get('cityId'); }
  get stockTypes() { return this.accountForm.get('stockTypes'); }
  get email() { return this.accountForm.get('email'); }
  get phoneNumber() { return this.accountForm.get('phoneNumber'); }
  // get primaryUser() { return this.accountForm.get('primaryUser'); }
  get username() { return this.accountForm.get('adminAccount.username'); }
  get password() { return this.accountForm.get('adminAccount.pass.password'); }
  get passwordConfirm() { return this.accountForm.get('adminAccount.pass.passwordConfirm'); }
  get attachments() { return this.accountForm.get('attachments') as FormArray; };

  constructor(private fb: FormBuilder,
    private countriesService: CountriesService,
    private citiesService: CitiesService,
    private stockTypesService: StockTypesService,
    private accountTypeService: AccountTypesService,
    private service: AccountsService,
    private loader: LoadingService,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    public translate: TranslateService,
    public auth: AuthService,
    private fileLoader: FileLoaderService) { }

  async ngOnInit() {

    document.querySelector('mat-card').classList.remove('overflow-hidden');

    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.formInit();
    this.accountForm.disable();
    this.loader.load(LoaderType.Nav);
    this.accountId = Number(this.route.snapshot.paramMap.get("id"));

    //uro stock-types
    this.uroStockTypes = await (await this.stockTypesService.getURO(this.lang)).list;

    this.users = (await this.usersService.getAccountUsers(this.accountId, this.lang)).users;
    //console.log(this.users);



    // const promises = [
    //   this.countriesService.get(this.translate.currentLang == 'ar' ? Language.Arabic : Language.English)
    //     .then(result => this.countries = result.countries),
    //   this.stockTypesService.getAllowed()
    //     .then(result => this._stockTypes = result.stockTypes),
    //   this.accountId && this.auth.isSystemAccount ?
    //     this.accountTypeService.get()
    //       .then(result => this.accountTypes = result.accountTypes.map(at => { at.titles = [at.titles[this.langIndex]]; return at; }))
    //     : this.accountTypeService.getAllowed()
    //       .then(result => this.accountTypes = result.accountTypes)
    // ];

    // Promise.all(promises).then()

    // Promise.all([
    //   this.countriesService.get(this.translate.currentLang == 'ar' ? Language.Arabic : Language.English)
    //     .then(result => this.countries = result.countries),
    //   this.stockTypesService.getAllowed()
    //     .then(result => this._stockTypes = result.stockTypes),
    //   this.accountId && this.auth.isSystemAccount ?
    //     this.accountTypeService.get()
    //       .then(result => this.accountTypes = result.accountTypes.map(at => { at.titles = [at.titles[this.langIndex]]; return at; }))
    //     : this.accountTypeService.getAllowed()
    //       .then(result => this.accountTypes = result.accountTypes)
    // ]).then(() => {

    //   if (this.accountId) {
    //     this.service.getAccount(this.accountId)
    //       .then(result => {
    //         //console.log(result);
    //         this._parentAccounts = result.parentAccounts;
    //         this.accountForm.patchValue({
    //           parentId: result.account.parentId,
    //           accountTypeId: result.account.accountTypeId,
    //           //accountType: result.account.accountType,
    //           titles: result.account.titles,
    //           countryId: result.account.city ? result.account.city.countryId : null,
    //           cityId: result.account.cityId,
    //           stockTypes: result.account.stockTypes,
    //           email: result.account.email,
    //           phoneNumber: result.account.phoneNumber
    //         });
    //       });
    //   }

    //   this.accountForm.enable();
    //   if (!this.accountId) {
    //     //defalut selects
    //     this.accountType.setValue(this.accountTypes[0].id);
    //     if (this.accountTypes.length == 1)
    //       this.accountType.disable();
    //   } else {
    //     //can't be edited
    //     //todo: unless it's a system account
    //     //this.accountType.disable();

    //   }

    // }).finally(() =>  this.loader.load(LoaderType.Nav, false));


    this.countries = (await this.countriesService.get(this.lang)).list;

    //this._stockTypes = await this.fetchStockTypes(!this.auth.isSystemAccount ? this.accountId : 0); // (await this.stockTypesService.getAllowed(this.accountId)).stockTypes;
    //this._stockTypes = await this.fetchStockTypes(this.accountId ? this.parentAccount.value : this.auth.accountId); // (await this.stockTypesService.getAllowed(this.accountId)).stockTypes;
    this.accountTypes = this.accountId && this.auth.currentUser.isSystemAccount ?
      (await this.accountTypeService.get()).list.map(at => { at.titles = [at.titles[this.lang]]; return at; })
      : (await this.accountTypeService.getAllowed()).list;





    if (this.accountId) {
      //update model, get account details from server and update form values

      var result = await this.service.getAccount(this.accountId);
      this.dto = result.account;

      this.documentTypes = this.dto.stockTypes.map(x => x.documentType).filter(this.onlyUnique);
      //console.log(this.documentTypes);
      this.accountTypeId = result.account.accountTypeId;

      //console.warn(result);
      this._parentAccounts = result.parentAccounts;
      this.accountForm.patchValue({
        //parentId: result.account.parentId,
        accountTypeId: result.account.accountTypeId,
        titles: result.account.titles,
        countryId: result.account.city ? result.account.city.countryId : null,
        cityId: result.account.cityId,
        stockTypes: result.account.stockTypes.map(x => x.id),
        email: result.account.email,
        phoneNumber: result.account.phoneNumber,
        isSMSEnabled: result.account.isSMSEnabled
      });

      if (result.account.city)
        this.cities =
          (await this.citiesService.get(result.account.city.countryId, this.lang)).list;


      if (this.dto.attachments)
        this.dto.attachments.forEach(async (att, i) => {
          att.url = await this.fileLoader.loadAsync(<string>att.url);
        });

      //console.log(this.accountForm.value);
    }
    //this._stockTypes = await (await this.fetchStockTypes(this.accountId/* ? this.parentAccount.value : this.auth.currentUser.accountId*/)).list.filter(x => x.id == StockType.License);
    //this._stockTypes = await (await this.fetchStockTypes(this.dto.parentId/* ? this.parentAccount.value : this.auth.currentUser.accountId*/)).list;
    this._stockTypes = await (await this.fetchStockTypes(this.accountId/* ? this.parentAccount.value : this.auth.currentUser.accountId*/));

    this.accountForm.enable();
    if (!this.accountId) {
      
      //defalut selects
      this.accountType.setValue(this.accountTypes[0].id);
      if (this.accountTypes.length == 1)
        this.accountType.disable();
    } else {
      //temp: disable for all
      if (!this.auth.currentUser.isSystemAccount)
        this.accountType.disable();
      //can't be edited
      //todo: unless it's a system account
      //this.accountType.disable();

    }

    // //not a branch
    // if (this.auth.currentUser.isSystemAccount && this.accountId && this.accountType.value != AccountType.Branch) {
    //   this.parentAccounts = await this.fetchParentAccounts();

    // //this._stockTypes = await this.fetchStockTypes();
    //}


    this.loader.load(LoaderType.Nav, false);
  }

  private onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getAccountType(accountTypeId: number) {
    const prefix = 'ACC_LABEL_ACCOUNT_';
    switch (accountTypeId) {
      case 1:
        return this.translate.instant(prefix + "BRANCH");

      case 2:
        return this.translate.instant(prefix + "AGENT");

      case 3:
        return this.translate.instant(prefix + "DISTRIBUTOR");

      case 4:
        return this.translate.instant(prefix + "SUB_DISTRIBUTOR");
    }
  }

  getBadgeClass(accountTypeId: number) {
    //const i = this.accountTypes.findIndex(id => id == accountTypeId);
    return this.badgeClasses[accountTypeId >= this.badgeClasses.length ? this.badgeClasses.length - 1 : accountTypeId - 1];
  }

  fetchCities(countryId) {
    //console.log(countryId);
    this.city.reset();
    this.citiesService.get(countryId, this.lang)
      .then(result => {
        this.cities = result.list;
      });
  }


  // private async fetchParentAccounts() {
  //   const result = await this.service
  //     .getPossibleParentAccounts(this.accountId, this.accountType.value);

  //   return result.list;
  //   ////console.warn(result);
  //   // return populateAccountsSelect(result.list);
  //   // return result.list.map(dto => {
  //   //   let _titles = [];
  //   //   dto.titles.forEach((t, i) => {
  //   //     _titles.push(`<span class='${(i != dto.titles.length - 1) ? 'grey' : ''}'">${t}</span>`);
  //   //   });
  //   //   dto.titles = [_titles.join('&nbsp;//&nbsp;')];
  //   //   return dto;
  //   // });
  // }

  // onSelectAccountType() {
  //   ////console.log(d);
  //   ////console.log('onSelectAccountType');


  //   ////console.log(this.accountType.value);
  //   //fetch accounts

  //   if (!this.accountId)
  //     return;

  //   this.parentAccount.reset();
  //   this.stockTypes.disable();
  //   this.parentAccount.disable();

  //   //if branch display all stock types, ignore parents
  //   if (this.accountType.value == 1 || !this.accountId) {
  //     this.loader.load(LoaderType.Body);
  //     this.fetchStockTypes(0)
  //       .then(result => {
  //         this._stockTypes = result;
  //         this.stockTypes.enable();
  //         this.parentAccount.enable();
  //       })
  //       .finally(() => this.loader.load(LoaderType.Body, false));
  //   } else if (this.accountId) {
  //     this.loader.load(LoaderType.Body);
  //     this.fetchParentAccounts()
  //       .then(result => {
  //         this.parentAccounts = result;
  //         this.parentAccount.enable();
  //       })
  //       .finally(() => this.loader.load(LoaderType.Body, false));
  //   }
  // }

  //this.parentAccount.value
  private async fetchStockTypes(accountId: number) {
    // return accountId ? await (await this.stockTypesService.getAllowed(accountId)).list
    //   : await (await this.stockTypesService.get(this.lang)).list;
    // //console.log(this.uroStockTypes);

    //console.log(this.accountId);
    //console.log(accountId);

    const stockTypes = await (await this.stockTypesService.getAllowed(!this.auth.currentUser.isSystemAccount || this.accountType.value != AccountType.Branch ? this.auth.currentUser.parentAccountId : 0)).list;
    //console.log(stockTypes);

    if (stockTypes.filter(x => x.documentType == _DocumentType.TripTikDocument).length > 0)
      stockTypes.push(...this.uroStockTypes.filter(x => x.documentType == _DocumentType.TripTikDocument && x.isUnifiedRelease && stockTypes.map(y => y.id).indexOf(x.id) == -1));

    //console.log(stockTypes);
    return stockTypes;
  }

  // onSelectParentAccount() {
  //   this.loader.load(LoaderType.Body);
  //   this.fetchStockTypes(this.parentAccount.value)
  //     .then(result => {
  //       this._stockTypes = result;
  //       if (this._stockTypes.length == 1)
  //         this.stockTypes.setValue([this._stockTypes[0].id]);

  //       this.stockTypes.enable();
  //     }).finally(() => this.loader.load(LoaderType.Body, false));
  // }

  onSubmit() {
    ////console.warn(this.accountForm);
    if (this.accountId) {
      this.username.setErrors(null);
      this.password.setErrors(null);
      this.passwordConfirm.setErrors(null);
    }

    //ignore parent
    //if (!this.accountId || !this.auth.currentUser.isSystemAccount || this.accountType.value == 1) {
    //this.parentAccount.setErrors(null);
    //}

    if (!this.accountForm.valid)
      return Utils.validateAllFormFields(this.accountForm);

    //post to server 
    this.loader.load(LoaderType.Body);
    const cmd = this.accountForm.value as PostAccountCmd;
    //ignore parent
    cmd.parentId = null;
    //cmd.accountTypeId = this.auth.currentUser.isSystemAccount ? 
    //todo post valid account type only

    cmd.accountTypeId = this.accountType.value;

    if (!this.accountId) {
      cmd.accountId = 0;
      cmd.username = this.username.value;
      cmd.password = this.password.value;

      this.service.createAccount(cmd)
        .then(r => {
          if (r) {
            this.alert.success('_ACTION_ADDING');
            this.router.navigate([this.translate.currentLang, 'accounts', 'list']);
          } else this.alert.failure('_ACTION_ADDING');
        })
        .finally(() => {
          this.loader.load(LoaderType.Body, false);
        });
    }
    else {
      cmd.accountId = this.accountId;
      this.service.updateAccount(cmd)
        .then(r => {
          if (r) {
            this.alert.success('_ACTION_UPDATING');
            this.router.navigate([this.translate.currentLang, 'accounts', 'list']);
          } else this.alert.failure('_ACTION_UPDATING');
        })
        .finally(() => {
          this.loader.load(LoaderType.Body, false);
          //this.router.navigate([this.translate.currentLang, 'accounts', 'list']);
        });
    }
  }

  private formInit() {
    this.accountForm = this.fb.group({
      //parentId: [null, [Validators.required]],
      accountTypeId: ['', [Validators.required]],
      titles: this.fb.array([
        ['', [Validators.required, nonEnglishCharactersValidator, Validators.minLength(3), Validators.maxLength(50)]],
        ['', [Validators.required, nonArabicCharactersValidator, Validators.minLength(3), Validators.maxLength(50)]]
      ]),
      countryId: [null, [Validators.required]],
      cityId: [null, [Validators.required]],
      stockTypes: ['', [Validators.required]],
      email: ['', [/*Validators.required, */Validators.email]],
      phoneNumber: ['', [Validators.required, phoneNumberValidator]],
      isSMSEnabled: [true],
      // primaryUser: ['', [Validators.required]],
      adminAccount: this.fb.group({
        username: this.fb.control('',
          [Validators.required, usernameValidator, Validators.minLength(5), Validators.maxLength(50)],
          this.usersService.validateUsernameNotTaken.bind(this)), //todo: regex for username pattern
        pass: this.fb.group({
          password: ['', [Validators.required, Validators.minLength(6)]],  //todo: regex for password pattern
          passwordConfirm: ['']
        },
          { validator: comparePasswords })
      }),
      attachments: this.fb.array([])
    });
  }



  //attachments

  private attachmentFormInit() {
    this.attachments.push(this.fb.group({
      name: ['', [Validators.required]],
      file: ['', [Validators.required]],
      fileName: ['']
    }));
  }

  addAttachment() {
    this.attachmentFormInit();
  }

  removeAttachment(i) {
    this.attachments.removeAt(i);
  }

  preview($event, group: FormGroup) {
    const tgt = $event.target || window.event.srcElement,
      files = Array.isArray($event) ? $event : tgt.files;

    if (FileReader && files && files.length) {
      const fr = new FileReader();
      fr.onload = () => {
        //console.log(fr);
        if (fr.result.toString().length <= 2 * 2 ** 20) {
          group.get('file').setValue(fr.result.toString());
          group.get('fileName').setValue(files[0].name);
        }
        else
          alert('file limit is 2 MB.');
      };
      fr.readAsDataURL(files[0]);
    }
    else {
      //console.error('not supported!');
    }
  }

  fileDropped(file, group: FormGroup): void {
    //console.log(file);
    group.get('file').setValue(file.url);
    group.get('fileName').setValue(file.name);
  }

}

import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { CurrenciesService } from '../../services/currencies';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { NGXLogger } from 'ngx-logger';
import { DeleteModal } from 'src/app/shared/models/modal';
import { Utils } from 'src/app/shared/services/utils';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { numbersOnlyValidator, nonEnglishCharactersValidator, nonArabicCharactersValidator, englishOnlyValidator, standardLocalizedControlValidators } from 'src/app/shared/services/custom-validators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/core/models/enums/role';
import { Language } from 'src/app/core/models/enums/language';
import { CurrencyDto } from '../../models/currency';

@Component({
  selector: 'mac-currencies',
  templateUrl: './currencies.component.html',
  styleUrls: ['./currencies.component.scss'],
  providers: [CurrenciesService]
})
export class CurrenciesComponent implements OnInit {
  displayedColumns: string[] = ['abbreviation', 'titles', 'tools'];
  dataSource: MatTableDataSource<CurrencyDto>;
  editingRowId: number = null;
  deletingRowId: number = null;
  model: CurrencyDto;
  lang: Language = 0;
  form: FormGroup;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  get abbreviation() { return this.form.get('abbreviation'); }
  get titles() { return this.form.get('titles') as FormArray; }
  get Role() { return Role; };

  constructor(private service: CurrenciesService,
    private alert: AlertService,
    private loader: LoadingService,
    private confirmService: ConfirmService,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private translate: TranslateService,
    private fb: FormBuilder,
    public auth: AuthService) { }

  ngOnInit() {
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    if (!this.auth.currentUser.isInRole(Role.LOOKUPS_UPDATE)
      && !this.auth.currentUser.isInRole(Role.LOOKUPS_DELETE))
      this.displayedColumns.splice(this.displayedColumns.length - 1, 1);

    this.loader.load(LoaderType.Nav);
    this.formInit();
    this.service.get(null)
      .then(result => {
        this.dataSource = new MatTableDataSource(result.list);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
      .finally(() => this.loader.load(LoaderType.Nav, false));
  }
  updateMode: boolean = false; //todo: manage all disabled controls during updating process!  
  add() {
    if (this.dataSource.data.findIndex(i => i.id == 0) > -1)
      return;

    this.model = { id: 0, abbreviation: '', titles: ['', ''] };
    this.dataSource.data.unshift(this.model);
    this.editingRowId = 0;
    this.form.patchValue({
      abbreviation: '',
      titles: ['', '']
    });

    this.abbreviation.reset();
    this.titles.controls.forEach(c => c.reset());

    this.deletingRowId = null;
    this.dataSource.filter = "";
    //this.updateMode = false;
    this.paginator.firstPage();
  }

  tryDelete(row: CurrencyDto) {
    let modal = this.confirmService.confirm(new DeleteModal({ type: 'LKP_TITLE_CURRENCY', title: row.titles[this.lang] }));
    modal.then(m => {
      if (m.indexOf('CONFIRMED') > -1) {
        //delete handler
        this.delete(row.id);
      }
    });
  }

  delete(id) {
    this.loader.load(LoaderType.Body);
    if (this.editingRowId == 0) {
      this.dataSource.data.shift();
      this.dataSource.filter = "";
    }
    this.editingRowId = null;
    this.deletingRowId = id;

    this.service.delete(id).then(status => {
      if (status) {
        const i = this.dataSource.data.findIndex(o => o.id == id);
        this.dataSource.data.splice(i, 1);
        this.dataSource.filter = "";
        this.alert.success(DefaultAlertAction.DELETING);
      } else this.alert.failure(DefaultAlertAction.DELETING);
    }).catch(e => {
      ////console.log(e);
      //this.alert.error();
    }).finally(() => {
      this.deletingRowId = null;
      this.loader.load(LoaderType.Body, false)
    });
  }

  edit(id) {
    if (this.editingRowId == 0) {
      this.dataSource.data.shift();
      this.dataSource.filter = "";
    }
    this.editingRowId = id;
    this.model = id > 0 ? [...this.dataSource.data].find(c => c.id == id) : null;

    this.form.patchValue({
      id: id > 0 ? id : 0,
      abbreviation: id > 0 ? this.model.abbreviation : '',
      titles: id > 0 ? this.model.titles : ['', '']
    });

    //this.updateMode = !id ? true : false;
  }

  onSubmit() {
    if (!this.form.valid)
      return Utils.validateAllFormFields(this.form);

    this.loader.load(LoaderType.Body);
    if (this.deletingRowId == null) {
      const cmd = this.form.value as CurrencyDto;
      if (this.editingRowId == 0) {
        this.service.create(cmd).then(id => {
          if (id) {
            //this.dataSource.data[0] = this.form.value as ICountryDto;
            this.dataSource.data[0].id = id;
            this.dataSource.data[0].abbreviation = this.form.value.abbreviation;
            this.dataSource.data[0].titles = this.form.value.titles;
            this.alert.success(DefaultAlertAction.ADDING);
          } else this.alert.error(DefaultAlertAction.ADDING);
        }).catch(e => this.edit(null))
          .finally(() => {
            this.editingRowId = null;
            this.deletingRowId = null;
            this.dataSource.filter = "";
            this.loader.load(LoaderType.Body, false);
          })
      } else {
        cmd.id = this.editingRowId;
        this.service.update(cmd)
          .then(status => {
            if (status) {
              const i = this.dataSource.data.findIndex(as => as.id == this.editingRowId);
              ////console.log(this.dataSource.data[i], this.form.value);
              //this.dataSource.data[i] = this.form.value as ICountryDto;

              this.dataSource.data[i].abbreviation = this.form.value.abbreviation;
              this.dataSource.data[i].titles = this.form.value.titles;
              this.alert.success(DefaultAlertAction.UPDATING);
            } else this.alert.failure(DefaultAlertAction.UPDATING);
          }).catch(e => {
            ////console.log(e);
            //this.alert.error();
          }).finally(() => {
            this.editingRowId = null;
            this.loader.load(LoaderType.Body, false)
          });
      }
    }
  }

  applyFilter(e: any) {
    this.dataSource.filter = e.target.value.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.edit(null);
  }

  private formInit() {
    this.form = this.fb.group({
      id: [''],
      abbreviation: ['', [Validators.required, englishOnlyValidator, Validators.minLength(2), Validators.maxLength(3)]],
      titles: this.fb.array([
        ['', standardLocalizedControlValidators(Language.Arabic)],
        ['', standardLocalizedControlValidators(Language.English)]
      ])
    });
  }
}


import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class LangMatPaginatorIntl extends MatPaginatorIntl {

  constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }



  getAndInitTranslations() {
    this.translate.get(['_PAGINATOR_ITEMS_PER_PAGE',
      '_PAGINATOR_NEXT_PAGE',
      '_PAGINATOR_PREVIOUS_PAGE',
      '_PAGINATOR_OF_LABEL',
      '_PAGINATOR_FIRST_PAGE',
      '_PAGINATOR_LAST_PAGE'])
      .subscribe(translation => {
        this.itemsPerPageLabel = translation['_PAGINATOR_ITEMS_PER_PAGE'];
        this.nextPageLabel = translation['_PAGINATOR_NEXT_PAGE'];
        this.previousPageLabel = translation['_PAGINATOR_PREVIOUS_PAGE'];
        this.firstPageLabel = translation['_PAGINATOR_FIRST_PAGE'];
        this.lastPageLabel = translation['_PAGINATOR_LAST_PAGE'];

        this.changes.next();
      });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.translate.instant('_PAGINATOR_OF_LABEL')} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.translate.instant('_PAGINATOR_OF_LABEL')} ${length}`;
  }
}





// const arabicRangeLabel = (page: number, pageSize: number, length: number) => {
//   if (length == 0 || pageSize == 0) { return `0 من ${length}`; }

//   length = Math.max(length, 0);

//   const startIndex = page * pageSize;

//   // If the start index exceeds the list length, do not try and fix the end index to the end.
//   const endIndex = startIndex < length ?
//       Math.min(startIndex + pageSize, length) :
//       startIndex + pageSize;

//   return `${startIndex + 1} - ${endIndex} من ${length}`;
// }


// export function getLangPaginatorIntl() {

//   const paginatorIntl = new MatPaginatorIntl();

//   paginatorIntl.itemsPerPageLabel = 'عدد العناصر بالصفحة: ';
//   paginatorIntl.nextPageLabel = 'الصفحة التالية';
//   paginatorIntl.previousPageLabel = 'الصفحة السابقة';
//   paginatorIntl.firstPageLabel = 'الصفحة الأولى';
//   paginatorIntl.lastPageLabel = 'الصفحة الأخيرة';
//   paginatorIntl.getRangeLabel = arabicRangeLabel;

//   return paginatorIntl;
// }
import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { CustomsPortsVm, CustomsPortDto } from '../models/customs-port';

@Injectable()
export class CustomsPortsService {
    constructor(private api: ApiHandler) { }

    get(countryId = 0, lang = null) {
        return this.api.get<CustomsPortsVm>(EndPoint.CUSTOMS_PORTS, countryId, { lang }).toPromise();
    }

    create(dto: CustomsPortDto) {
        return this.api.create<CustomsPortDto, number>(EndPoint.CUSTOMS_PORTS, dto).toPromise();
    }

    update(dto: CustomsPortDto) {
        return this.api.update(EndPoint.CUSTOMS_PORTS, dto).toPromise();
    }

    delete(id: number) {
        return this.api.delete(EndPoint.CUSTOMS_PORTS, id).toPromise();
    }
}
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';

import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { exportToExcel } from 'src/app/shared/helpers/export-to-excel';
import { Language } from 'src/app/core/models/enums/language';
import { Gender } from 'src/app/core/models/enums/gender';
import { PrintObject } from 'src/app/core/models/enums/print-object';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { DeleteModal, UpdateModal } from 'src/app/shared/models/modal';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/core/models/enums/role';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CountriesService } from 'src/app/features/lookups/services/countries';
import { CountryDto } from 'src/app/features/lookups/models/country';
import { populateAccountsSelect } from 'src/app/shared/helpers/populate-accounts-select';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { AccountsService } from 'src/app/features/accounts/services/accounts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TripTiksDataSource } from '../../services/trip-tiks.datasource';
import { TripTiksService } from '../../services/trip-tiks.service';
import { TripTikDto } from '../../models/trip-tik';
import { PrintLogDto, PaginationQuery, TryRevokeDocumentCommand } from '../../../_shared/models/issuing.models';
import { StaticConfig } from 'src/app/features/lookups/services/config';
import { ConfigObject } from 'src/app/core/models/enums/config-object';
import { Utils } from 'src/app/shared/services/utils';
//import { StockType } from 'src/app/core/models/enums/stock-type';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { VehicleTypesService } from 'src/app/features/lookups/services/vehicle-types';
import { VehicleMakesService } from 'src/app/features/lookups/services/vehicle-makes';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { IssuingService } from '../../../_shared/services/issuing.service';
import { ThemeService } from 'ng2-charts';
import { _DocumentType } from 'src/app/core/models/enums/document-type';
import { AccountType } from 'src/app/core/models/enums/account-type';
import { VehicleModelsService } from 'src/app/features/lookups/services/vehicle-models';

@Component({
   selector: 'mac-trip-tiks',
   templateUrl: './trip-tiks.component.html',
   styleUrls: ['./trip-tiks.component.scss'],
   providers: [
      TripTiksService,
      IssuingService,
      CountriesService,
      AccountsService,
      StockTypesService,
      VehicleMakesService,
      VehicleModelsService,
      VehicleTypesService
   ]
})
export class TripTiksComponent implements OnInit {
   moment = moment;
   displayedColumns: string[] = ['serial', 'cName', 'cPhoneNumber', 'vPlateID', 'stockType', 'accountTitles', 'issueDate'/*, 'printings'*/, 'tools'];
   dataSource: TripTiksDataSource;
   lang: Language;
   advancedSearchMode: boolean;
   countries: CountryDto[] = [];
   accounts: LookupDto[] = [];
   searchForm: FormGroup;
   activeFilters: boolean;
   vehicleManufactureYearRange: number[] = [];
   vehicleMakes: LookupDto[] = [];
   vehicleModels: LookupDto[] = [];
   vehicleTypes: LookupDto[] = [];
   stockTypes: StockTypeDto[] = [];
   query: any = null;

   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   @ViewChild(MatSort, { static: true }) sort: MatSort;
   @ViewChild('filter', { static: true }) filter: ElementRef;
   @ViewChild('filterBtn') filterBtn: ElementRef;

   get Role() { return Role; };
   get Gender() { return Gender; };
   get PrintObject() { return PrintObject; };
   get Object() { return Object; };
   get fromDate() { return this.searchForm.get('fromDate'); };
   get toDate() { return this.searchForm.get('toDate'); };
   get Math() { return Math; };

   constructor(private loader: LoadingService,
      private tripTiksService: TripTiksService,
      private issuingService: IssuingService,
      public translate: TranslateService,
      private confirmService: ConfirmService,
      private alert: AlertService,
      public auth: AuthService,
      private fb: FormBuilder,
      private countriesService: CountriesService,
      private accountsService: AccountsService,
      private stockTypesService: StockTypesService,
      private vehicleMakesService: VehicleMakesService,
      private vehicleModelsService: VehicleModelsService,
      private vehicleTypesService: VehicleTypesService,
      private router: Router,
      private route: ActivatedRoute) { }

   async ngOnInit() {
      document.querySelector('mat-card').classList.remove('overflow-hidden');
      this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
      this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

      this.loader.load(LoaderType.Nav);
      this.formInit();

      //accounts
      //if system account, accounts will be fetched from server, otherwise current account details will be fetched from local auth service

      if (this.auth.currentUser.isSystemAccount || this.auth.currentUser.accountTypeId == AccountType.ReleaseCenter) {
         this.accountsService.getChildrenAccountsLookup(!this.auth.currentUser.isSystemAccount ? this.auth.currentUser.accountId : 0, false)
            .then(result => {
               this.accounts = result.list;//populateAccountsSelect(result.list);
            }).catch(e => this.searchForm.get('accountId').disable());
      } else {
         this.accounts = [<LookupDto>{ id: this.auth.currentUser.accountId, titles: [this.auth.currentUser.accountTitles[this.lang]] }];
         this.searchForm.patchValue({ accountId: this.auth.currentUser.accountId });
         this.searchForm.get('accountId').disable();
      }

      const startYearConfig = StaticConfig.getConfigKeyValuePairs(ConfigObject.VehicleManufactureYearRange);
      this.vehicleManufactureYearRange = Utils.spreadRange([Number(startYearConfig[0].value), (new Date().getUTCFullYear() + 1)], true);

      //console.warn(this.route.snapshot.queryParams);
      this.route.queryParamMap.subscribe(p => {
         //console.warn(p);

         if (!p.has('pageIndex')) {
            //console.warn('ready to reload!');
            this.advancedSearchMode = false;
            this.activeFilters = false;
            this.filter.nativeElement.value = '';
            this.paginator.page.emit({ pageIndex: 0, pageSize: this.paginator.pageIndex, length: this.paginator.length });
         }
      });

      //let query = null;
      if (this.route.snapshot.queryParams.query) {
         this.query = this.route.snapshot.queryParams.query;
         this.filter.nativeElement.value = this.query;
      }
      else if (this.route.snapshot.queryParams.accountId
         || this.route.snapshot.queryParams.fromDate
         || this.route.snapshot.queryParams.toDate
         || this.route.snapshot.queryParams.stockTypeId
         || this.route.snapshot.queryParams.cResidenceCountryId
         || this.route.snapshot.queryParams.cIdentityOrPassportID
         || this.route.snapshot.queryParams.cPhoneNumber
         || this.route.snapshot.queryParams.vPlateID
         || this.route.snapshot.queryParams.vTypeId
         || this.route.snapshot.queryParams.vMakeId
         || this.route.snapshot.queryParams.vModelId
         || this.route.snapshot.queryParams.vChassisID
         || this.route.snapshot.queryParams.vManufactureYear
         || this.route.snapshot.queryParams.cName
         || this.route.snapshot.queryParams.vRegistrationCountryId
         || this.route.snapshot.queryParams.serial
         || this.route.snapshot.queryParams.isIssued
         || this.route.snapshot.queryParams.isDamaged) {
         this.activeFilters = true;
         this.searchForm.patchValue(this.route.snapshot.queryParams);

         //fix int for ng-select
         this.searchForm.patchValue({ accountId: !isNaN(this.route.snapshot.queryParams.accountId) ? parseInt(this.route.snapshot.queryParams.accountId) : null });
         this.searchForm.patchValue({ cResidenceCountryId: !isNaN(this.route.snapshot.queryParams.cResidenceCountryId) ? parseInt(this.route.snapshot.queryParams.cResidenceCountryId) : null });
         this.searchForm.patchValue({ vMakeId: !isNaN(this.route.snapshot.queryParams.vMakeId) ? parseInt(this.route.snapshot.queryParams.vMakeId) : null });
         this.searchForm.patchValue({ vModelId: !isNaN(this.route.snapshot.queryParams.vModelId) ? parseInt(this.route.snapshot.queryParams.vModelId) : null });
         // this.searchForm.patchValue({ vRegistrationCountryId: !isNaN(this.route.snapshot.queryParams.vRegistrationCountryId) ? parseInt(this.route.snapshot.queryParams.vRegistrationCountryId) : null });

         //multi-select
         let _vRegistrationCountryId;
         if (this.route.snapshot.queryParams.vRegistrationCountryId && this.route.snapshot.queryParams.vRegistrationCountryId.indexOf(',') > -1)
            _vRegistrationCountryId = this.route.snapshot.queryParams.vRegistrationCountryId.split(',');
         else if (this.route.snapshot.queryParams.vRegistrationCountryId)
            _vRegistrationCountryId = [this.route.snapshot.queryParams.vRegistrationCountryId];

         if (_vRegistrationCountryId)
            this.searchForm.patchValue({ vRegistrationCountryId: _vRegistrationCountryId.map(x => parseInt(x)) });

         this.query = this.searchForm.value;
      }

      this.fetchData(this.query);

      await Promise.all([
         this.stockTypesService.getAllowed()
            .then(result => this.stockTypes = result.list.filter(x => x.documentType == _DocumentType.TripTikDocument)),
         this.countriesService.get(this.lang)
            .then(result => this.countries = result.list),
         this.vehicleMakesService.get(this.lang)
            .then(result => this.vehicleMakes = result.list),
         this.onSelectVehicleMake(this.searchForm.get('vMakeId').value),
         this.vehicleTypesService.get(this.lang)
            .then(result => this.vehicleTypes = result.list)
      ]);
   }

   ngAfterViewInit() {
      this.dataSource.counter$
         .pipe(
            tap((count) => {
               this.paginator.length = count;
            })
         ).subscribe();

      merge(this.paginator.page, this.sort.sortChange)
         .pipe(
            tap(() => {
               this.dataSource.load({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction },
                  this.advancedSearchMode || this.activeFilters ? this.searchForm.value : this.filter.nativeElement.value.trim())
            })
         ).subscribe(() => this.loader.load(LoaderType.Body));

      merge(fromEvent(this.filter.nativeElement, 'search'), fromEvent(this.filterBtn.nativeElement, 'click'))
         .pipe(
            tap(() => {
               this.advancedSearchMode = false;
               this.activeFilters = false;
               this.searchForm.reset();
               this.paginator.pageIndex = 0;
               this.dataSource.load({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction }, this.filter.nativeElement.value.trim())
            })
         ).subscribe(() => this.loader.load(LoaderType.Body));

      this.dataSource.connect()
         .subscribe(() => {
            let pagination: PaginationQuery = { pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction };
            let query = this.activeFilters ? this.searchForm.value : this.filter.nativeElement.value.trim();
            this.query = query;

            if (typeof query == 'object') {
               query.vRegistrationCountryId = query.vRegistrationCountryId ? query.vRegistrationCountryId.toString() : null;
            }

            if (this.activeFilters)
               this.router.navigate(['.'], { relativeTo: this.route, queryParams: { ...pagination, ...query }, replaceUrl: true });
            else
               this.router.navigate(['.'], { relativeTo: this.route, queryParams: { ...pagination, query }, replaceUrl: true });
         });
   }

   fetchData(query) {
      this.paginator.pageIndex = this.route.snapshot.queryParams.pageIndex ? this.route.snapshot.queryParams.pageIndex : 0;
      this.paginator.pageSize = this.route.snapshot.queryParams.pageSize ? this.route.snapshot.queryParams.pageSize : 25;
      this.sort.active = this.route.snapshot.queryParams.sort ? this.route.snapshot.queryParams.sort : 'created';
      this.sort.direction = this.route.snapshot.queryParams.dir ? this.route.snapshot.queryParams.dir : 'desc';
      this.dataSource = new TripTiksDataSource(this.tripTiksService, this.loader);
      this.dataSource.load({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction }, query);
   }

   toggleAdvancedSearch() {
      this.advancedSearchMode = !this.advancedSearchMode;
      this.filter.nativeElement.value = '';
   }

   filterList(list: PrintLogDto[], printObject: PrintObject) {
      return list.filter(l => l.printObject == printObject)
   }

   printObjectsCount(list: PrintLogDto[], printObject: PrintObject) {
      return list.filter(l => l.printObject == printObject).length;
   }

   delete(dto: TripTikDto) {
      if (dto.accountId != this.auth.currentUser.accountId
         || !this.auth.currentUser.isInRole(Role.TRIPTIKS_DELETE))
         return;

      //confirm if not yet issued
      if (!dto.issued) {
         let modal = this.confirmService.confirm(new DeleteModal({
            type: 'TRP_TITLE_TRIPTIK',
            title: `${dto.serial.toString()}/${dto.cName}`,
            note: 'TRP_CONFIRM_DELETE_NOTE'
         }));

         modal.then(async m => {
            if (m.indexOf('CONFIRMED') > -1)
               this._delete(dto.id);
         });
      }
   }

   tryRevoke(dto: TripTikDto) {
      if (dto.accountId != this.auth.currentUser.accountId
         || !this.auth.currentUser.isInRole(Role.TRIPTIKS_REVOKE_REQUESTS_CREATE))
         return;

      let modal;
      //try-revoke
      //try-revoke confirm if issued && permited to try-revoke
      if (!dto.revokeRequestId) {
         modal = this.confirmService.confirm(new DeleteModal({
            type: 'ISS_TITLE_DOCUMENT_REVOKE_REQUEST',
            title: `${dto.stockType.titles[0]} / ${dto.serial.toString()}`,
            action: '_ACTION_REQUEST2',
            submitText: '_ACTION_REQUEST2',
            confirmQuestion: 'ISS_DOCUMENT_REVOKE_REQUEST_CONFIRM_REQUEST_CONFIRMATION_QUESTION',
            note: 'ISS_DOCUMENT_REVOKE_REQUEST_CONFIRM_REQUEST_NOTE',
            dangerNote: ' ',
            inputNote: 'ISS_TITLE_DOCUMENT_REVOKE_REQUEST_REASON',
            requestType: true,
            attachment: '_TITLE_ATTACHMENTS'
         }));
      } else {
         //cancel
         modal = this.confirmService.confirm(new UpdateModal({
            type: 'ISS_TITLE_DOCUMENT_REVOKE_REQUEST',
            title: `${dto.stockType.titles[0]} / ${dto.serial.toString()}`,
            action: '_ACTION_REVOKE_REQUEST_CANCEL',
            submitText: '_ACTION_REVOKE_REQUEST_CANCEL',
            confirmQuestion: 'ISS_DOCUMENT_REVOKE_REQUEST_CONFIRM_REQUEST_CANCEL_CONFIRMATION_QUESTION',
            note: 'ISS_DOCUMENT_REVOKE_REQUEST_CONFIRM_REQUEST_CANCEL_NOTE',
            dangerNote: ' '
         }));
      }
      modal.then(async m => {
         if (m.indexOf('CONFIRMED') > -1) {
            (!dto.revokeRequestId) ?
               this._tryRevoke(dto.id, dto.stockType.id, m[1])
               : this._cancelTryRevoke(dto.revokeRequestId)
         }
      });
   }

   async onSelectVehicleMake(id?: number) {
      // //console.log(id);
      this.searchForm.get('vModelId').disable();
      const obj: LookupDto = <LookupDto>{ id: 0, titles: [this.translate.instant("_LABEL_ALL")] };
      const arr = id ? await (await this.vehicleModelsService.get(id, null, this.lang)).list
         : [];
      this.vehicleModels = [obj, ...arr];
      this.searchForm.get('vModelId').enable();
      if (!this.searchForm.get('vModelId').value)
         this.searchForm.get('vModelId').setValue(this.vehicleModels[0].id);
   }

   private _tryRevoke(objectId: string, stockTypeId: number, attributes: any/*, reason: string*/) {
      const reason = attributes.inputNote,
         isDamaged = attributes.isDamaged == "true",
         attachment = attributes.attachment;

      const cmd: TryRevokeDocumentCommand = { id: objectId, stockTypeId, reason, isDamaged, attachment }
      ////console.log(cmd);
      this.loader.load(LoaderType.Body);
      this.issuingService.tryRevokeDocument(cmd)
         .then(result => {
            if (result) {
               this.alert.success(DefaultAlertAction.REQUESTING);
               const i = this.dataSource.TripTiksSubject.value.findIndex(i => i.id == objectId);
               this.dataSource.TripTiksSubject.value[i].revokeRequestId = result;
               this.dataSource.TripTiksSubject.next(this.dataSource.TripTiksSubject.value);

            } else this.alert.failure(DefaultAlertAction.REQUESTING);
         })
         .finally(() => this.loader.load(LoaderType.Body, false));
   }

   private _cancelTryRevoke(id: number) {
      this.loader.load(LoaderType.Body);
      this.issuingService.cancelTryRevokeDocument(id)
         .then(result => {
            if (result) {
               this.alert.success(DefaultAlertAction.REVOKE_REQUESTING_CANCEL);
               const i = this.dataSource.TripTiksSubject.value.findIndex(i => i.revokeRequestId == id);
               this.dataSource.TripTiksSubject.value[i].revokeRequestId = null;
               this.dataSource.TripTiksSubject.next(this.dataSource.TripTiksSubject.value);

            } else this.alert.failure(DefaultAlertAction.REVOKE_REQUESTING_CANCEL);
         })
         .finally(() => this.loader.load(LoaderType.Body, false));
   }

   private _delete(id: string) {
      this.loader.load(LoaderType.Body);
      this.tripTiksService.delete(id)
         .then(result => {
            if (result) {
               this.alert.success(DefaultAlertAction.DELETING);
               this.dataSource.TripTiksSubject.next(this.dataSource.TripTiksSubject.value.filter(d => d.id != id));
               this.dataSource.countSubject.next(this.dataSource.countSubject.value - 1);

            } else this.alert.failure(DefaultAlertAction.DELETING);
         })
         .finally(() => this.loader.load(LoaderType.Body, false));
   }

   onExportToExcel(all: boolean = true) {
      this.loader.load(LoaderType.Body);
      if (all)
         this.tripTiksService[(this.query == null || typeof this.query == 'string') ? 'getAll' : 'search']({ pageIndex: 0, pageSize: this.paginator.length, sort: this.sort.active, dir: this.sort.direction }, <any>this.query)
            .toPromise()
            .then(data => {
               this.exportToExcel(data.list);
            }).finally(() => this.loader.load(LoaderType.Body, false));
      else
         this.dataSource.connect().subscribe(data => {
            this.exportToExcel(data);
            this.loader.load(LoaderType.Body, false);
         }).unsubscribe();
   }

   private exportToExcel(data: TripTikDto[]) {
      const wscols = [
         { width: 12 },
         { width: 28 },
         { width: 12 },
         { width: 18 },
         { width: 24 },
         { width: 28 },
         { width: 18 },
         { width: 18 },
         { width: 18 },
         { width: 18 }
      ];
      const autofilter = { ref: 'A1:J1' };
      const rows: string[][] = [];

      const headers = [
         this.translate.instant('_LABEL_SERIAL'),
         this.translate.instant('_FIELD_NAME'),
         this.translate.instant('_FIELD_PHONE_NO'),
         this.translate.instant('TRP_FIELD_PLATE_ID'),
         this.translate.instant('TRP_FIELD_TRIPTIK_TYPE'),
         this.translate.instant('ACC_FIELD_ACCOUNT_TITLE'),
         this.translate.instant('ISS_LABEL_ISSUE_DATE'),
         this.translate.instant('ISS_LABEL_PRINT_OBJECT_PRINTED_COUNT', { param: this.translate.instant('TRP_LABEL_PRINT_OBJECT_FRONT_COVER') }),
         this.translate.instant('ISS_LABEL_PRINT_OBJECT_PRINTED_COUNT', { param: this.translate.instant('TRP_LABEL_PRINT_OBJECT_INNER_PAGES') }),
         this.translate.instant('ISS_LABEL_PRINT_OBJECT_PRINTED_COUNT', { param: this.translate.instant('TRP_LABEL_PRINT_OBJECT_BACK_COVER') })
      ];

      rows.push(headers);
      for (let row of data) {
         rows.push([
            row.serial.toString(),
            row.cName ? row.cName : 'N/A',
            row.cPhoneNumber ? row.cPhoneNumber : 'N/A',
            row.vPlateID ? row.vPlateID : 'N/A',
            row.stockType.titles[0],
            row.accountTitles[0],
            row.issued ? moment(row.issued).format('DD/MM/YYYY') : '',
            this.printObjectsCount(row.printLogs, PrintObject.TripTikFrontCover).toString(),
            this.printObjectsCount(row.printLogs, PrintObject.TripTikInnerPages).toString(),
            this.printObjectsCount(row.printLogs, PrintObject.TripTikBackCover).toString()
         ]);
      }
      ////console.log(rows);
      setTimeout(() => {
         exportToExcel(rows, wscols, autofilter, this.translate.currentLang == 'ar', 'Trip-Tiks');

      }, 1000);


   }

   onSubmit() {
      if (this.fromDate.value && typeof this.fromDate.value == 'object')
         this.fromDate.setValue(Utils.convertToServerDateTimeString(this.fromDate.value));

      if (this.toDate.value && typeof this.toDate.value == 'object')
         this.toDate.setValue(Utils.convertToServerDateTimeString(this.toDate.value));

      //swap dates
      if (this.fromDate.value && this.toDate.value && new Date(this.fromDate.value) > new Date(this.toDate.value)) {
         let _temp = this.fromDate.value;
         this.fromDate.setValue(this.toDate.value);
         this.toDate.setValue(_temp);
      }

      this.activeFilters = true;
      this.paginator.pageIndex = 0;
      this.paginator.page.emit({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, length: this.paginator.length });
   }

   resetSearch() {
      this.advancedSearchMode = false;
      if (this.activeFilters) {
         this.activeFilters = false;
         this.paginator.pageIndex = 0;
         this.paginator.page.emit({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, length: this.paginator.length });
      }

      this.activeFilters = false;

      setTimeout(() => {
         this.searchForm.reset();
         this.query = null;
      }, 500);
   }

   private formInit() {
      this.searchForm = this.fb.group({
         cName: [''],   //regex to match english letters only
         cPhoneNumber: [''],  //regex to match numbers only
         accountId: [null],
         serial: [''], //regex to match numbers only
         fromDate: [''],
         toDate: [''],
         stockTypeId: [''],
         cResidenceCountryId: [null],
         cIdentityOrPassportID: [''],
         vPlateID: [''],
         vRegistrationCountryId: [null],
         vTypeId: [''],
         vMakeId: [null],
         vModelId: [null],
         vChassisID: [''],
         vManufactureYear: [''],
         isIssued: [''],
         isDamaged: ['']
      });
   }
}

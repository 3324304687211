import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { StocksService } from '../services/stocks.service';
import { catchError, finalize } from 'rxjs/operators';
import { UndoRequestsVm, UndoRequestDto } from '../models/undo-requests';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';


export class UndoRequestsDataSource implements DataSource<UndoRequestDto> {

   // add variables to hold the data and number of total records retrieved asynchronously
   // BehaviourSubject type is used for this purpose
   public undoRequestsSubject = new BehaviorSubject<UndoRequestDto[]>([]);

   // to show the total number of records
   private countSubject = new BehaviorSubject<number>(0);
   public counter$ = this.countSubject.asObservable();

   constructor(private stocksService: StocksService,
      private loader: LoadingService) {
   }

   loadUndoRequests(pageIndex: number, pageSize: number, sort: string, direction: string, stockTypeId: number = 0, filter: string = '') {
      this.stocksService.getUndoRequests(pageIndex, pageSize, sort, direction, stockTypeId, filter)
         .pipe(
            catchError(() => of([])),
            finalize(() => { })
         )
         .subscribe((result: UndoRequestsVm) => {
            this.undoRequestsSubject.next(result.undoRequests);
            this.countSubject.next(result.recordsCount);
            this.loader.load(LoaderType.Nav, false);
            this.loader.load(LoaderType.Body, false);
         });
   }

   connect(): Observable<UndoRequestDto[]> {
      return this.undoRequestsSubject.asObservable();
   }

   disconnect(collectionViewer: CollectionViewer): void {
      this.undoRequestsSubject.complete();
      this.countSubject.complete();
   }
}

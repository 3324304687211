import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { result } from 'lodash';
import { _DocumentType } from 'src/app/core/models/enums/document-type';
import { Language } from 'src/app/core/models/enums/language';
//import { StockType } from 'src/app/core/models/enums/stock-type';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { IssuingsPerMonthDataSetDto } from 'src/app/features/statistics/models/statistics.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LoaderType, LoadingService } from 'src/app/shared/services/loading';
import { Utils } from 'src/app/shared/services/utils';
import { DocumentValidityVm, IssuedDocumentsQuery } from '../../models/issuing.models';
import { IssuingService } from '../../services/issuing.service';

@Component({
  selector: 'mac-issued-documents-query',
  templateUrl: './issued-documents-query.component.html',
  styleUrls: ['./issued-documents-query.component.scss'],
  providers: [StockTypesService, IssuingService]
})
export class IssuedDocumentsQueryComponent implements OnInit {

  form: FormGroup;
  stockTypes: StockTypeDto[] = [];
  lang: Language;
  dto: DocumentValidityVm;
  dataSource: MatTableDataSource<DocumentValidityVm>;
  displayedColumns: string[] = ['name', 'issueDate', 'expiryDate', 'status'];

  get _DocumentType() { return _DocumentType; };

  get stockType() { return this.form.get('stockTypeId'); };
  get serial() { return this.form.get('serial'); };
  get passportIDorIdentityID() { return this.form.get('passportIDorIdentityID'); };
  get vChassisID() { return this.form.get('vChassisID'); };
  get vPlateID() { return this.form.get('vPlateID'); };

  constructor(private fb: FormBuilder,
    private stockTypesService: StockTypesService,
    private issuingService: IssuingService,
    public translate: TranslateService,
    private loader: LoadingService,
    private alert: AlertService) { }

  ngOnInit(): void {
    this.loader.load(LoaderType.Nav);
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);
    this.formInit();

    this.stockTypesService.get(this.lang)
      .then((result) => this.stockTypes = result.list/*.filter(x => x.documentType == _DocumentType.TripTikDocument)*/)
      .finally(() => this.loader.load(LoaderType.Nav, false));
  }

  documentType: _DocumentType;

  onSelectStockType(stockTypeId: number) {
    const stockType = this.stockTypes.find(x => x.id == stockTypeId);
    this.documentType = stockType.documentType;
    //console.log(stockTypeId);

    if (this.documentType == _DocumentType.TripTikDocument) {
      this.passportIDorIdentityID.clearValidators()
      this.passportIDorIdentityID.setErrors(null);
    } else {
      this.passportIDorIdentityID.setValidators(Validators.required);
      this.passportIDorIdentityID.setErrors(null);
    }
  }

  private formInit() {
    this.form = this.fb.group({
      stockTypeId: ['', [Validators.required]],
      serial: ['', [Validators.required]],
      passportIDorIdentityID: ['', [Validators.required]],
      vPlateID: [''],
      vChassisID: ['']
    })
  }


  reset() {
    this.isPosted = false;
    this.form.reset();
    this.dataSource = null;
  }


  isPosted: boolean = false;
  isCloneAvailable: boolean = false;

  onSubmit() {
    //console.log(this.form.value);

    if (this.documentType == _DocumentType.TripTikDocument) {
      this.passportIDorIdentityID.clearValidators()
      this.passportIDorIdentityID.setErrors(null);
    } else {
      this.passportIDorIdentityID.setValidators(Validators.required);
      this.passportIDorIdentityID.updateValueAndValidity();
    }

    //this.passportIDorIdentityID.setErrors(null);

    if (!this.form.valid)
      return Utils.validateAllFormFields(this.form);



    // if (!this.form.valid)
    //   return;


    if (!this.passportIDorIdentityID.value && !this.vChassisID.value && !this.vPlateID.value) {
      this.alert.failure("_MESSAGE_FILL_SEARCH_CRITERIA", false);
      return;
    }

    const query = this.form.value as IssuedDocumentsQuery;







    //console.log(query);

    this.loader.load(LoaderType.Body);
    // this.issuingService.searchForExpiredDocument(this.stockType.value, this.serial.value, this.passportIDorIdentityID.value)
    this.issuingService.searchForIssuedDocument(this.stockType.value, this.serial.value, query)
      .then(result => {
        //console.log(result);

        this.dataSource = result ? new MatTableDataSource([result]) : null;
        if (this.dataSource)
          this.isCloneAvailable = result.stockType.documentType == _DocumentType.DrivingLicense
            || !result.isValid;

        if (this.isCloneAvailable && this.displayedColumns.length == 4)
          this.displayedColumns.push('tools')

        if (!this.isCloneAvailable && this.displayedColumns.length == 5)
          this.displayedColumns.pop();

        // this.dto = result;
        // if (!this.dto) {
        //   this.dataSource = null;
        //  // this.alert.failure("_MESSAGE_NO_SEARCH_RESULTS", false);
        // }

      }).catch(e => { console.error(e); this.dataSource = null; })
      .finally(() => {
        this.loader.load(LoaderType.Body, false);
        this.isPosted = true;
      })
  }

}

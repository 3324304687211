import { Component, OnInit } from '@angular/core';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CountryDto } from 'src/app/features/lookups/models/country';
import { CountriesService } from 'src/app/features/lookups/services/countries';
import { Language } from 'src/app/core/models/enums/language';
import { TranslateService } from '@ngx-translate/core';
import { BloodGroup } from 'src/app/core/models/enums/blood-group';
import { IntLicensePermitClass } from 'src/app/core/models/enums/license-permit-class';
import { Gender } from 'src/app/core/models/enums/gender';
import { StocksService } from 'src/app/features/stock/services/stocks.service';
import * as moment from 'moment';
import { Utils } from 'src/app/shared/services/utils';
import { FileLoaderService } from 'src/app/shared/services/file-loader.service';
import * as _ from 'lodash';
import { AuthService } from 'src/app/core/auth/auth.service';
import { TripTiksClaimsService } from '../../services/trip-tiks-claims.service';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { CurrencyDto } from 'src/app/features/lookups/models/currency';
//import { StockType } from 'src/app/core/models/enums/stock-type';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { CurrenciesService } from 'src/app/features/lookups/services/currencies';
import { CustomsPortsService } from 'src/app/features/lookups/services/customs-ports.service';
import { PostTripTikClaimCommand } from '../../models/trip-tik-claim.model';
import { Role } from 'src/app/core/models/enums/role';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { PursuingZonesService } from 'src/app/features/lookups/services/pursuing-zones';
import { _DocumentType } from 'src/app/core/models/enums/document-type';
import { addressValidator, phoneNumberValidator, triptikNameValidator } from 'src/app/shared/services/custom-validators';
import { CustomsPortDto } from 'src/app/features/lookups/models/customs-port';

@Component({
  selector: 'mac-manage-trip-tik-claim',
  templateUrl: './manage-trip-tik-claim.component.html',
  styleUrls: ['./manage-trip-tik-claim.component.scss'],
  providers: [
    CountriesService,
    PursuingZonesService,
    StockTypesService,
    CurrenciesService,
    CustomsPortsService,
    TripTiksClaimsService,
    FileLoaderService]
})
export class ManageTripTikClaimComponent implements OnInit {
  lang: Language;
  form: FormGroup;
  // countries: CountryDto[] = [];
  pursuingZones: LookupDto[] = [];
  stockTypes: StockTypeDto[] = [];
  customsPorts: CustomsPortDto[] = [];
  _customsPorts: CustomsPortDto[] = [];
  claimsCountries: LookupDto[] = [];
  currencies: CurrencyDto[] = [];
  availableSerials: number[];
  //cashedVm: IntLicenseVm;
  //dto: IntLicenseDetailsDto = new IntLicenseDetailsDto();
  updateMode: boolean;
  _loadingGif: string = 'assets/images/Ring-Preloader.gif';
  dateFormat = 'L';
  mode: 'ADD' | 'UPDATE';


  get moment() { return moment };
  get Object() { return Object };
  get BloodGroup() { return BloodGroup };
  get IntLicensePermitClass() { return IntLicensePermitClass };
  get Gender() { return Gender };

  get entranceID() { return this.form.get('entranceID') };
  get entranceDate() { return this.form.get('entranceDate') };
  get serial() { return this.form.get('serial') };
  get stockType() { return this.form.get('stockTypeId') };
  get country() { return this.form.get('countryId') };
  get customsPort() { return this.form.get('customsPortId') };
  get pursuingZone() { return this.form.get('pursuingZoneId') };
  get value() { return this.form.get('value') };
  get currency() { return this.form.get('currencyId') };
  get holderName() { return this.form.get('holderName') };
  get holderAddress() { return this.form.get('holderAddress') };
  get holderPhone() { return this.form.get('holderPhone') };

  constructor(private loader: LoadingService,
    private fb: FormBuilder,
    private countriesService: CountriesService,
    private pursuingZonesService: PursuingZonesService,
    private customsPortsService: CustomsPortsService,
    private stockTypesService: StockTypesService,
    private currenciesService: CurrenciesService,
    private triptiksClaimsService: TripTiksClaimsService,
    public translate: TranslateService,
    private alert: AlertService,
    private router: Router,
    private auth: AuthService) { }

  async ngOnInit() {
    moment.locale('en-gb');
    document.querySelector('mat-card').classList.remove('overflow-hidden');
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.loader.load(LoaderType.Nav);
    this.formInit();

    //const tripTikClaimId = this.route.snapshot.paramMap.get('id');
    // this.countries = await (await this.countriesService.get(this.lang)).list;



    await Promise.all([
      this.stockTypesService.getAllowed()
        .then(result => this.stockTypes = result.list.filter(x => x.documentType == _DocumentType.TripTikDocument)),
      this.customsPortsService.get(this.lang)
        .then(result => {
          this._customsPorts = !this.auth.currentUser.isAdministrator ?
            result.list.filter(x => this.auth.currentUser.claimsCountries.indexOf(x.countryId) > -1)
            : result.list;

          this.claimsCountries = [...new Map(this._customsPorts.map(item =>
            [item['countryId'], item])).values()].map(x => <LookupDto><any>{
              id: x.countryId,
              titles: x.countryTitles
            });


          //bind ports later


        }),
      // this.countriesService.get(this.lang)
      //   .then(result => this.countries = result.list),
      this.pursuingZonesService.get(this.lang)
        .then(result => this.pursuingZones = result.list),
      this.currenciesService.get(this.lang)
        .then(result => {
          this.currencies = result.list;
          this.currency.setValue(this.currencies[0].id);
        })
    ]);


    // if (tripTikClaimId) {
    //   this.updateMode = this.mode == 'UPDATE';
    //   this.dto = await (await this.tripTiksClaimsService.get(tripTikClaimId)).intLicense;

    //   if (!this.dto || (this.updateMode && this.dto.issued)) {    //will ignore if cloning
    //     this.router.navigate([`/${this.translate.currentLang}/not-found`]);
    //     return;
    //   }
    //   if (this.updateMode) {
    //     this.availableSerials.unshift(this.dto.serial);
    //   } else {
    //     if (this.availableSerials.length > 0)
    //       this.form.patchValue({ serial: this.availableSerials[0] });
    //     else {
    //       this.alert.failure("LIC_ALERT_NO_STOCK_AVAILABLE", false);
    //       this.form.disable();
    //       return;
    //     }
    //   }

    //   this.dto.serial = this.updateMode ? this.dto.serial : this.availableSerials[0];
    //   this.form.patchValue(this.dto);

    // } else {
    // if (this.availableSerials.length > 0)
    //   this.form.patchValue({ serial: this.availableSerials[0] });
    // else {
    //   this.alert.failure("LIC_ALERT_NO_STOCK_AVAILABLE", false);
    //   this.form.disable();
    //}
    //}
    this.loader.load(LoaderType.Nav, false);
  }

  fetchCustomsPorts(countryId) {
    this.customsPort.reset();

    this.customsPorts = this._customsPorts.filter(x => countryId == x.countryId)
    // this.customsPortsService.get(countryId, this.lang)
    //   .then(result => {
    //     this.customsPorts = result.list;
    //   });
  }

  private formInit() {
    this.form = this.fb.group({
      entranceID: ['', [Validators.required, Validators.maxLength(10)]],
      entranceDate: ['', [Validators.required]],
      serial: ['', [Validators.required, Validators.maxLength(10)]],
      stockTypeId: ['', [Validators.required]],
      countryId: [null, [Validators.required]],
      customsPortId: [null, [Validators.required]],
      pursuingZoneId: ['', [Validators.required]],
      value: [''],
      currencyId: [''],
      holderName: ['', [Validators.required, triptikNameValidator, Validators.minLength(5), Validators.maxLength(30)]],
      holderAddress: ['', [addressValidator]],
      holderPhone: ['', [phoneNumberValidator]]
    });
  }

  onSubmit() {

    // if (!this.auth.currentUser.isInRole(Role.TRIPTIKS_CLAIMS_CREATE))
    //   return;

    //console.log(this.form.value);

    if (this.entranceDate.value)
      this.entranceDate.setValue(moment(this.entranceDate.value).format());

    for (let c in this.form.controls) {
      // //console.log(c);
      // //console.log(this.form.controls[c].value);
      // //console.log(typeof this.form.controls[c].value);
      if (this.form.controls[c].value && typeof this.form.controls[c].value == 'string')
        this.form.controls[c].setValue(this.form.controls[c].value.trim());

    }

    if (!this.form.valid)
      return Utils.validateAllFormFields(this.form);

    this.loader.load(LoaderType.Body);

    const cmd = this.form.value as PostTripTikClaimCommand;

    this.triptiksClaimsService.create(cmd)
      .then(id => {
        if (id) {
          this.alert.success(DefaultAlertAction.ADDING);
          setTimeout(() => {
            this.router.navigate(['/', this.translate.currentLang, 'trip-tiks-claims']);
          }, 1000);
        } else this.alert.failure(DefaultAlertAction.ADDING);
      })
      .finally(() => this.loader.load(LoaderType.Body, false));
  }
}
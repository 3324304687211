import * as moment from "moment";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap, shareReplay } from 'rxjs/operators'
import { Subject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { Language } from '../models/enums/language';
import { EndPoint } from '../models/enums/end-point';
import { Role } from '../models/enums/role';
import { Login } from '../models/login';
import { AccountType } from '../models/enums/account-type';
import { environment } from "src/environments/environment";
// import { AppConfig } from '../services/app-config.service';
//import * as AppConfig from '../../../assets/config.json';

// export interface JwtToken {
//     id: string,
//     username: string,
//     user_titles: string[],
//     account_id: number,
//     account_titles: string[],
//     account_type_id: number,
//     account_type_titles: string[],
//     rol: string[],
//     exp: number,
// }

const httpOptions = <HttpOptions>{
    headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Accept": "application/json",
        "Accept": "*/*",
        "Authorization": ""
    }),
    body: {},
    responseType: "json",
    observe: "body",
    withCredentials: false,
    reportProgress: false,
    params: null
};
class HttpOptions {
    headers?: HttpHeaders | { [header: string]: string | string[]; };
    observe?: "body";
    params?: HttpParams | { [param: string]: string | string[]; };
    reportProgress?: boolean;
    responseType?: "json";
    withCredentials?: boolean;
}



export interface JwtToken {
    userId: string,
    isSystemAccount: boolean,
    isAdministrator: boolean,
    username: string,
    userTitles: string[],
    accountId: number,
    parentAccountId?: number,
    accountTitles: string[],
    accountTypeId: number,
    accountTypeTitles: string[],
    claimsCountries: number[],
    //isClaimsApplicable: boolean,
    roles: number[],
    expiry: number,
    intLicenseAllowedValidity: number,
    isInRole: (role: Role) => boolean,
    isInAnyOfRoles: (roles: Role[]) => boolean
}

@Injectable()
export class AuthService {
    lang: Language;
    static authActivity = new Subject<boolean>();
    static userMeta = new Subject<{ id: any, name: any, avatar: any }>();

    constructor(private http: HttpClient) {
        this.lang = Language.Arabic;// translate.currentLang == 'en' ? Language.English : Language.Arabic;
    }

    login(login: Login) {
        return this.http.post(`${environment.apiBaseUrl}/${EndPoint.AUTH}`, login)
            .pipe(tap(res => this.setSession(res)), shareReplay()).toPromise();
    }

    forgotPassword(recoverPassword: { email: string }) {
        return this.http.post<boolean>(`${environment.apiBaseUrl}/${EndPoint.FORGOT_PASSWORD}`, recoverPassword).toPromise();
    }

    resetPassword(resetPassword: { id: string, token: string, password: string }) {
        return this.http.post<boolean>(`${environment.apiBaseUrl}/${EndPoint.RESET_PASSWORD}`, resetPassword).toPromise();
    }

    private setSession(authResult) {
        const expiresAt = moment(authResult.expiry);

        localStorage.setItem('jwt_token;' + environment.apiBaseUrl, authResult.token);
        localStorage.setItem('expires_at;' + environment.apiBaseUrl, JSON.stringify(expiresAt.valueOf()));
        AuthService.authActivity.next(true);
    }

    static logout() {
        localStorage.removeItem('jwt_token;' + environment.apiBaseUrl);
        localStorage.removeItem('expires_at;' + environment.apiBaseUrl);
        AuthService.authActivity.complete();
        window.location.href = "/";
    }

    get currentUser(): JwtToken {
        const token = localStorage.getItem('jwt_token;' + environment.apiBaseUrl);

        //console.log(dToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"])
        if (token) {
            const dToken = jwt_decode(token);
            // //console.log(dToken);
            // //console.log(typeof dToken["claims_countries"])
            //console.log(typeof dToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]);
            return <JwtToken>{
                userId: dToken["id"],
                isSystemAccount: dToken["is_system_account"] == "True",
                isAdministrator: dToken["is_administrator"] == "True",
                username: dToken["username"],
                userTitles: <[]>dToken["user_titles"],
                accountId: dToken["account_id"],
                parentAccountId: dToken["parent_account_id"],
                accountTitles: <[]>dToken["account_titles"],
                accountTypeId: dToken["account_type_id"],
                claimsCountries: (typeof dToken["claims_countries"] == "object" ?
                    <[]>dToken["claims_countries"]
                    : [dToken["claims_countries"]]).map(r => Number(r)),
                //isClaimsApplicable: dToken["is_c_applicable"] == "True",
                intLicenseAllowedValidity: Number(dToken["intlicense_allowed_vyears"]),
                accountTypeTitles: <[]>dToken["account_type_titles"],
                roles: typeof dToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] == "object" ?
                    (<[]>dToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]).map(r => Number(r))
                    : [dToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]],
                isInRole: (role: Role) => this.currentUser.roles.indexOf(role) > -1 /*additional URO rule*/ && (role != Role.ACCOUNTS_READ || this.currentUser.accountTypeId != AccountType.ReleaseCenter),
                isInAnyOfRoles: (roles: Role[]) => this.currentUser.roles.some(x => roles.includes(x)),
                expiry: dToken["exp"]
            }
        }
        return null;
        //return _token ? jwt_decode(_token) as JwtToken : null;
    }

    // get userId() {
    //     return this.token ? this.token.id : null;
    // }

    // get userTitles() {
    //     return this.token ? this.token.user_titles : ['', ''];
    // }

    // get username() {
    //     return this.token ? this.token.username : null;
    // }

    // get accountId() {
    //     return this.token ? this.token.account_id : null;
    // }

    // get accountTypeId() {
    //     return this.token ? this.token.account_type_id : null;
    // }

    // get accountTypeTitles() {
    //     return this.token ? this.token.account_type_titles : ['', ''];
    // }

    // get isSystemAccount() {
    //     //todo: retrive value from token
    //     return this.accountId == 1;
    // }

    // get accountTitles() {
    //     return this.token ? this.token.account_titles : ['', ''];
    // }

    // get roles() {
    //     return this.token ?
    //         (<[]>this.token["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]).map(r => Number(r))
    //         : [];
    // }

    public isAuthenticated() {
        return moment().isBefore(this.getExpiration());
    }

    // public isInRole(role: Role) {
    //     return this.token.roles.indexOf(role) > -1;
    //     // return this.roles.indexOf(role) > -1;
    // }

    isLoggedOut() {
        return !this.isAuthenticated();
    }

    getExpiration() {
        const expiration = localStorage.getItem("expires_at;" + environment.apiBaseUrl);
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    isTokenExpired() {
        return this.getExpiration() <= moment();
    }
}
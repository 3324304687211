import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { StocksService } from '../services/stocks.service';
import { StocksTotalsVm, AccountStocksTotalsDto } from '../models/stocks-totals';
import { catchError, finalize, filter } from 'rxjs/operators';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { populateAccountsSelect } from 'src/app/shared/helpers/populate-accounts-select';


export class StockTotalsDataSource implements DataSource<AccountStocksTotalsDto> {
   private StockTotalsSubject = new BehaviorSubject<AccountStocksTotalsDto[]>([]);

   private countSubject = new BehaviorSubject<number>(0);
   public counter$ = this.countSubject.asObservable();

   constructor(private stocksService: StocksService,
      private loader: LoadingService) { }

   loadStockTotals(pageIndex: number, pageSize: number, sort: string, direction: string, filter: string = '', accountId: number = 0) {
      this.stocksService.getStockTotals(pageIndex, pageSize, sort, direction, filter, accountId)
         .pipe(
            catchError(() => of([])),
            finalize(() => { })
         )
         .subscribe((result: StocksTotalsVm) => {
            result.accountsStocksTotals.forEach(accountStocksTotals => {
               accountStocksTotals.parentAccounts = populateAccountsSelect([accountStocksTotals.parentAccounts])[0];
            });
            this.StockTotalsSubject.next(result.accountsStocksTotals);
            this.countSubject.next(result.recordsCount);
            this.loader.load(LoaderType.Nav, false);
            this.loader.load(LoaderType.Body, false);
         }
         );
   }

   connect(): Observable<AccountStocksTotalsDto[]> {
      return this.StockTotalsSubject.asObservable();
   }

   disconnect(collectionViewer: CollectionViewer): void {
      this.StockTotalsSubject.complete();
      this.countSubject.complete();
   }
}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { merge, fromEvent } from "rxjs";
import { tap } from "rxjs/operators";
import * as moment from "moment";
import * as _ from "lodash";

import { LoadingService, LoaderType } from "src/app/shared/services/loading";
import { exportToExcel } from "src/app/shared/helpers/export-to-excel";
import { Language } from "src/app/core/models/enums/language";
import { Gender } from "src/app/core/models/enums/gender";
import { PrintObject } from "src/app/core/models/enums/print-object";
import { ConfirmService } from "src/app/shared/services/modal.service";
import { DeleteModal, UpdateModal } from "src/app/shared/models/modal";
import { AlertService } from "src/app/shared/services/alert.service";
import { DefaultAlertAction } from "src/app/shared/models/default-alert-action";
import { AuthService } from "src/app/core/auth/auth.service";
import { Role } from "src/app/core/models/enums/role";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CountriesService } from "src/app/features/lookups/services/countries";
import { CountryDto } from "src/app/features/lookups/models/country";
import { IntLicensePermitClass } from "src/app/core/models/enums/license-permit-class";
import { populateAccountsSelect } from "src/app/shared/helpers/populate-accounts-select";
import { LookupDto } from "src/app/features/lookups/models/lookup";
import { AccountsService } from "src/app/features/accounts/services/accounts.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  PaginationQuery,
  PrintLogDto,
  TryRevokeDocumentCommand,
} from "../../../_shared/models/issuing.models";
import { IssuingService } from "../../../_shared/services/issuing.service";
//import { StockType } from 'src/app/core/models/enums/stock-type';
import { parseInt } from "lodash";
import { Utils } from "src/app/shared/services/utils";
import { IntLicensesService } from "../../../int-licenses/services/int-licenses.service";
import { IntLicensesDataSource } from "../../../int-licenses/services/int-licenses.datasource";
import { IntLicenseDto } from "../../../int-licenses/models/int-license";
import { CoupounService } from "../../services/coupoun.service";
import { CouponDetailDataSource } from "../../services/coupo-detail.datasource";
import { CouponDto } from "../../models/coupon";

@Component({
  selector: "mac-view-license-detail",
  templateUrl: "./view-license-detail.component.html",
  styleUrls: [
    "../../../../../../../node_modules/@ng-select/ng-select/themes/material.theme.css",
    "./view-license-detail.component.scss",
  ],
  providers: [
    IntLicensesService,
    IssuingService,
    CountriesService,
    AccountsService,
  ],
})
export class ViewLicenseDetailComponent implements OnInit {
  moment = moment;
  displayedColumns: string[] = ["name", "discount", "status", "customerName"];
  dataSource: CouponDetailDataSource;
  lang: Language;
  advancedSearchMode: boolean;
  countries: CountryDto[] = [];
  accounts: LookupDto[] = [];
  query: any = null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("filter", { static: true }) filter: ElementRef;
  @ViewChild("filterBtn") filterBtn: ElementRef;

  get Role() {
    return Role;
  }
  get Gender() {
    return Gender;
  }
  get PrintObject() {
    return PrintObject;
  }
  get IntLicensePermitClass() {
    return IntLicensePermitClass;
  }
  get Object() {
    return Object;
  }
  get Math() {
    return Math;
  }

  constructor(
    private loader: LoadingService,
    private intLicensesService: IntLicensesService,
    private issuingService: IssuingService,
    public translate: TranslateService,
    private confirmService: ConfirmService,
    private alert: AlertService,
    public auth: AuthService,
    private fb: FormBuilder,
    private countriesService: CountriesService,
    private accountsService: AccountsService,
    private router: Router,
    private route: ActivatedRoute,
    private couponService: CoupounService
  ) {
    //console.log("insomniac");
  }

  ngOnInit() {
    document.querySelector("mat-card").classList.remove("overflow-hidden");
    this.lang =
      this.translate.currentLang == "ar" ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(
      (t) => (this.lang = t.lang == "ar" ? Language.Arabic : Language.English)
    );

    this.loader.load(LoaderType.Nav);
    this.formInit();

    this.countriesService
      .get(this.lang)
      .then((result) => (this.countries = result.list));

    //if system account, accounts will be fetched from server, otherwise current account details will be fetched from local auth service
    if (this.auth.currentUser.isSystemAccount) {
      this.accountsService
        .getChildrenAccountsLookup(0, false)
        .then((result) => {
          this.accounts = result.list; // populateAccountsSelect(result.list);
        })
        .catch((e) => this.searchForm.get("accountId").disable());
    } else {
      this.accounts = [
        <LookupDto>{
          id: this.auth.currentUser.accountId,
          titles: [this.auth.currentUser.accountTitles[this.lang]],
        },
      ];
      this.searchForm.patchValue({
        accountId: this.auth.currentUser.accountId,
      });
      this.searchForm.get("accountId").disable();
    }

    this.route.queryParamMap.subscribe((p) => {
      //console.warn(p);

      if (!p.has("pageIndex")) {
        //console.warn('ready to reload!');
        this.advancedSearchMode = false;
        this.activeFilters = false;
        this.filter.nativeElement.value = "";
        this.paginator.page.emit({
          pageIndex: 0,
          pageSize: this.paginator.pageIndex,
          length: this.paginator.length,
        });
      }
    });

    //let query = null;

    if (this.route.snapshot.queryParams.query) {
      this.query = this.route.snapshot.queryParams.query;
      this.filter.nativeElement.value = this.query;
    } else if (
      this.route.snapshot.queryParams.accountId ||
      this.route.snapshot.queryParams.countryId ||
      this.route.snapshot.queryParams.fromDate ||
      this.route.snapshot.queryParams.toDate ||
      this.route.snapshot.queryParams.gender ||
      this.route.snapshot.queryParams.name ||
      this.route.snapshot.queryParams.nationalityId ||
      this.route.snapshot.queryParams.passportID ||
      this.route.snapshot.queryParams.permitClass ||
      this.route.snapshot.queryParams.phoneNumber ||
      this.route.snapshot.queryParams.serial ||
      this.route.snapshot.queryParams.isIssued ||
      this.route.snapshot.queryParams.isDamaged
    ) {
      this.activeFilters = true;
      this.searchForm.patchValue(this.route.snapshot.queryParams);

      //fix int for ng-select
      this.searchForm.patchValue({
        accountId: !isNaN(this.route.snapshot.queryParams.accountId)
          ? parseInt(this.route.snapshot.queryParams.accountId)
          : null,
      });
      this.searchForm.patchValue({
        countryId: !isNaN(this.route.snapshot.queryParams.countryId)
          ? parseInt(this.route.snapshot.queryParams.countryId)
          : null,
      });
      this.searchForm.patchValue({
        nationalityId: !isNaN(this.route.snapshot.queryParams.nationalityId)
          ? parseInt(this.route.snapshot.queryParams.nationalityId)
          : null,
      });

      // this.searchForm.patchValue({countryId: 2});
      // //console.log(this.searchForm.value);
      this.query = this.searchForm.value;
    }

    this.fetchData(this.query);
  }

  ngAfterViewInit() {
    this.dataSource.counter$
      .pipe(
        tap((count) => {
          this.paginator.length = count;
        })
      )
      .subscribe();

    merge(this.paginator.page, this.sort.sortChange)
      .pipe(
        tap(() => {
          this.dataSource.loadCouponDetails(
            this.route.snapshot.params.id,
            {
              pageIndex: this.paginator.pageIndex,
              pageSize: this.paginator.pageSize,
              sort: this.sort.active,
              dir: this.sort.direction,
            },
            this.advancedSearchMode || this.activeFilters
              ? this.searchForm.value
              : this.filter.nativeElement.value.trim()
          );
        })
      )
      .subscribe(() => this.loader.load(LoaderType.Body));

    merge(
      fromEvent(this.filter.nativeElement, "search"),
      fromEvent(this.filterBtn.nativeElement, "click")
    )
      .pipe(
        tap(() => {
          this.advancedSearchMode = false;
          this.activeFilters = false;
          this.searchForm.reset();
          this.paginator.pageIndex = 0;
          this.dataSource.loadCouponDetails(
            this.route.snapshot.params.id,
            {
              pageIndex: this.paginator.pageIndex,
              pageSize: this.paginator.pageSize,
              sort: this.sort.active,
              dir: this.sort.direction,
            },
            this.filter.nativeElement.value.trim()
          );
        })
      )
      .subscribe(() => this.loader.load(LoaderType.Body));

    this.dataSource.connect().subscribe(() => {
      let pagination: PaginationQuery = {
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        sort: this.sort.active,
        dir: this.sort.direction,
      };
      this.query = this.activeFilters
        ? this.searchForm.value
        : this.filter.nativeElement.value.trim();
      let query = this.query;

      if (this.activeFilters)
        this.router.navigate(["."], {
          relativeTo: this.route,
          queryParams: { ...pagination, ...query },
          replaceUrl: true,
        });
      else
        this.router.navigate(["."], {
          relativeTo: this.route,
          queryParams: { ...pagination, query },
          replaceUrl: true,
        });
    });
  }

  fetchData(query) {
    this.paginator.pageIndex = this.route.snapshot.queryParams.pageIndex
      ? this.route.snapshot.queryParams.pageIndex
      : 0;
    this.paginator.pageSize = this.route.snapshot.queryParams.pageSize
      ? this.route.snapshot.queryParams.pageSize
      : 25;
    this.sort.active = this.route.snapshot.queryParams.sort
      ? this.route.snapshot.queryParams.sort
      : "created";
    this.sort.direction = this.route.snapshot.queryParams.dir
      ? this.route.snapshot.queryParams.dir
      : "desc";
    this.dataSource = new CouponDetailDataSource(this.couponService, this.loader);
    this.dataSource.loadCouponDetails(
      this.route.snapshot.params.id,
      {
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        sort: this.sort.active,
        dir: this.sort.direction,
      },
      query
    );
  }

  toggleAdvancedSearch() {
    this.advancedSearchMode = !this.advancedSearchMode;
    this.filter.nativeElement.value = "";
  }

  filterList(list: PrintLogDto[], printObject: PrintObject) {
    return list.filter((l) => l.printObject == printObject);
  }

  printObjectsCount(list: PrintLogDto[], printObject: PrintObject) {
    return list.filter((l) => l.printObject == printObject).length;
  }

  onUpdate(coupon: any) {
    localStorage.setItem("EditCoupon", JSON.stringify(coupon));
    this.router.navigate(["en/issuing/coupon-management/add", coupon.id]);
  }

  cancel(dto: IntLicenseDto) {
    if (
      !dto.issued ||
      !this.auth.currentUser.isSystemAccount ||
      !this.auth.currentUser.isInRole(Role.INT_LICENSES_DELETE)
    )
      return;

    let modal = this.confirmService.confirm(
      new DeleteModal({
        type: "LIC_TITLE_LICENSE",
        title: `${dto.serial.toString()}/${dto.name}`,
        note: "LIC_CONFIRM_CANCEL_NOTE",
      })
    );

    modal.then(async (m) => {
      if (m.indexOf("CONFIRMED") > -1) this._cancel(dto.id);
    });
  }

  delete(dto: IntLicenseDto) {
    if (
      dto.accountId != this.auth.currentUser.accountId ||
      !this.auth.currentUser.isInRole(Role.INT_LICENSES_DELETE)
    )
      return;

    //delete confirm if not yet issued
    if (!dto.issued) {
      let modal = this.confirmService.confirm(
        new DeleteModal({
          type: "LIC_TITLE_LICENSE",
          title: `${dto.serial.toString()}/${dto.name}`,
          note: "LIC_CONFIRM_DELETE_NOTE",
        })
      );

      modal.then(async (m) => {
        if (m.indexOf("CONFIRMED") > -1) this._delete(dto.id);
      });
    }
  }

  private _delete(id: string) {
    this.loader.load(LoaderType.Body);
    this.intLicensesService
      .delete(id)
      .then((result) => {
        if (result) {
          this.alert.success(DefaultAlertAction.DELETING);
          this.dataSource.couponDetailSubject.next(
            this.dataSource.couponDetailSubject.value.filter((d) => d.id != id)
          );
          this.dataSource.countSubject.next(
            this.dataSource.countSubject.value - 1
          );
        } else this.alert.failure(DefaultAlertAction.DELETING);
      })
      .finally(() => this.loader.load(LoaderType.Body, false));
  }

  private _cancel(id: string) {
    this.loader.load(LoaderType.Body);
    this.issuingService
      .cancelDocument(id)
      .then((result) => {
        if (result) {
          this.alert.success(DefaultAlertAction.DELETING); //todo: change message
          this.dataSource.couponDetailSubject.next(
            this.dataSource.couponDetailSubject.value.filter((d) => d.id != id)
          );
          this.dataSource.countSubject.next(
            this.dataSource.countSubject.value - 1
          );
        } else this.alert.failure(DefaultAlertAction.DELETING);
      })
      .finally(() => this.loader.load(LoaderType.Body, false));
  }

  onExportToExcel(all: boolean = true) {
    this.loader.load(LoaderType.Body);
    if (all)
      this.couponService[
        this.query == null || typeof this.query == "string"
          ? "getCoupons"
          : "search"
      ](
        {
          pageIndex: 0,
          pageSize: this.paginator.length,
          sort: this.sort.active,
          dir: this.sort.direction,
        },
        <any>this.query
      )
        .toPromise()
        .then((data) => {
          this.exportToExcel(data.list);
        })
        .finally(() => this.loader.load(LoaderType.Body, false));
    else
      this.dataSource
        .connect()
        .subscribe((data) => {
          this.exportToExcel(data);
          this.loader.load(LoaderType.Body, false);
        })
        .unsubscribe();
  }

  private exportToExcel(data: CouponDto[]) {
    const wscols = [
      { width: 12 },
      { width: 28 },
      { width: 12 },
      { width: 18 },
      { width: 28 },
      { width: 18 },
      { width: 18 },
      { width: 18 },
      { width: 18 },
    ];
    const autofilter = { ref: "A1:I1" };
    const rows: string[][] = [];

    const headers = [
      this.translate.instant("LIC_FIELD_LICENSE_NO"),
      this.translate.instant("_FIELD_NAME"),
      this.translate.instant("_LABEL_GENDER"),
      this.translate.instant("_FIELD_PHONE_NO"),
      this.translate.instant("ACC_FIELD_ACCOUNT_TITLE"),
      this.translate.instant("ISS_FIELD_PASSPORT_NUMBER"),
      this.translate.instant("ISS_LABEL_ISSUE_DATE"),
      this.translate.instant("ISS_LABEL_PRINT_OBJECT_PRINTED_COUNT", {
        param: this.translate.instant("LIC_LABEL_PRINT_OBJECT_LICENSE"),
      }),
      this.translate.instant("ISS_LABEL_PRINT_OBJECT_PRINTED_COUNT", {
        param: this.translate.instant("LIC_LABEL_PRINT_OBJECT_STICKER"),
      }),
    ];

    rows.push(headers);
    for (let row of data) {
      rows.push([
        row.id,
        row.name,
        row.discount.toString(),
        row.status ? "Active" : "InActive",
      ]);
    }

    exportToExcel(
      rows,
      wscols,
      autofilter,
      this.translate.currentLang == "ar",
      "Coupons"
    );
  }

  onSubmit() {
    // console.warn(this.searchForm.value);
    //var d = new Date()
    if (this.fromDate.value && typeof this.fromDate.value == "object")
      this.fromDate.setValue(
        Utils.convertToServerDateTimeString(this.fromDate.value)
      );

    if (this.toDate.value && typeof this.toDate.value == "object")
      this.toDate.setValue(
        Utils.convertToServerDateTimeString(this.toDate.value)
      );

    //swap dates
    if (
      this.fromDate.value &&
      this.toDate.value &&
      new Date(this.fromDate.value) > new Date(this.toDate.value)
    ) {
      let _temp = this.fromDate.value;
      this.fromDate.setValue(this.toDate.value);
      this.toDate.setValue(_temp);
    }

    //console.log(this.fromDate.value, this.toDate.value);

    this.activeFilters = true;

    this.paginator.pageIndex = 0;
    this.paginator.page.emit({
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length,
    });
  }

  get fromDate() {
    return this.searchForm.get("fromDate");
  }
  get toDate() {
    return this.searchForm.get("toDate");
  }
  searchForm: FormGroup;
  activeFilters: boolean;

  resetSearch() {
    this.advancedSearchMode = false;
    if (this.activeFilters) {
      this.activeFilters = false;
      this.paginator.pageIndex = 0;
      this.paginator.page.emit({
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length,
      });
    }

    this.activeFilters = false;

    setTimeout(() => {
      this.searchForm.reset();
      this.query = null;
    }, 500);
  }

  private formInit() {
    //use same validators to filter inputs values
    this.searchForm = this.fb.group({
      name: [""], //regex to match english letters only
      countryId: [null],
      nationalityId: [null],
      gender: [""],
      phoneNumber: [""], //regex to match numbers only
      passportID: [""],
      accountId: [null],
      permitClass: [""],
      serial: [""], //regex to match numbers only
      fromDate: [""],
      toDate: [""],
      isIssued: [""],
      isDamaged: [""],
    });
  }
}

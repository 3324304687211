export enum ConfigObject {
    VehicleUpholsteryTypes = 1,
    VehicleOptions,
    VehicleSeatsCountRange,
    VehicleManufactureYearRange,
    VehicleCylindersCountRange,
    VehicleSpareTiresCountOptions,
    IntLicenseMaxPrintLimit,
    TripTikMaxPrintLimit,
    TripTikPrintEmptyValue
}
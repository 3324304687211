import { TransactionsVm, TransactionDto } from './../../features/stock/models/transactions';
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { transactionTypeLocale } from 'src/app/features/stock/models/transaction-type.enum';

export function exportToExcel(rows: string[][], wscols: any, autofilter: any, rtl: boolean, type: string) {

    //const [formatted, wscols] = _transactions(data, translate);

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rows);
    ws["!cols"] = wscols;
    ws["!autofilter"] = autofilter;// { ref: 'A1:G1' };

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    if (!wb.Workbook) wb.Workbook = {};
    if (!wb.Workbook.Views) wb.Workbook.Views = [];
    if (!wb.Workbook.Views[0]) wb.Workbook.Views[0] = {};
    wb.Workbook.Views[0].RTL = rtl;

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `MAC_${type}_Export_${moment(new Date()).format('DD_MM_YYYY')}.xlsx`);
}

// function _transactions(data: TransactionDto[], translate: TranslateService) {
//     let result: [string[][], any[]] = [[], []];
//     const headers = [
//         translate.instant('_LABEL_DATE'),
//         `${translate.instant('_LABEL_FROM')} ${translate.instant('ACC_LABEL_ACCOUNT')}`,
//         `${translate.instant('_LABEL_TO')} ${translate.instant('ACC_LABEL_ACCOUNT')}`,
//         `${translate.instant('_LABEL_FROM')} ${translate.instant('STK_LABEL_QUANTITY')}`,
//         `${translate.instant('_LABEL_TO')} ${translate.instant('STK_LABEL_QUANTITY')}`,
//         translate.instant('STK_LABEL_TRANSACTION_TYPE'),
//         translate.instant('LKP_FIELD_STOCK_TYPE')
//     ];

//     result[0].push(headers);
//     for (let row of data) {
//         result[0].push([
//             moment(row.created).format('DD/MM/YYYY'),
//             row.fromAccountTitles ? row.fromAccountTitles[0] : '',
//             row.toAccountTitles ? row.toAccountTitles[0] : '',
//             row.fromRange.toString(),
//             row.toRange.toString(),
//             translate.instant(transactionTypeLocale(row.transactionType)),
//             row.stockType.titles[0]
//         ]);
//     }

//     var wscols = [
//         { width: 14 },
//         { width: 16 },
//         { width: 16 },
//         { width: 14 },
//         { width: 14 },
//         { width: 18 },
//         { width: 18 }
//     ];

//     result[1] = wscols;

//     { ref: 'A1:G1' };
//     return result;
// }

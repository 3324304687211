//import { VehicleColorDto } from 'src/app/core/nswag.api';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { VehicleColorsService } from '../../services/vehicle-colors';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { DeleteModal } from 'src/app/shared/models/modal';
import { Language } from 'src/app/core/models/enums/language';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { Utils } from 'src/app/shared/services/utils';
import { nonEnglishCharactersValidator, nonArabicCharactersValidator, standardLocalizedControlValidators } from 'src/app/shared/services/custom-validators';
import { LookupDto } from '../../models/lookup';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/core/models/enums/role';


@Component({
  selector: 'mac-vehicle-colors',
  templateUrl: './vehicle-colors.component.html',
  styleUrls: ['./vehicle-colors.component.scss'],
  providers: [VehicleColorsService]
})
export class VehicleColorsComponent implements OnInit {

  displayedColumns: string[] = ['titles', 'tools'];
  dataSource: MatTableDataSource<LookupDto>;
  editingRowId: number = null;
  deletingRowId: number = null;
  model: LookupDto = { id: 0, titles: ['', ''] };
  submitted: boolean;
  loading: boolean;
  langIndex: number = 0;
  form: FormGroup;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  get titles() { return this.form.get('titles') as FormArray; }
  get Role() { return Role; };


  constructor(private service: VehicleColorsService,
    public auth: AuthService,
    private alert: AlertService,
    private loader: LoadingService,
    private confirm: ConfirmService,
    private fb: FormBuilder,
    private translate: TranslateService) { }

  ngOnInit() {
    this.langIndex = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.langIndex = t.lang == 'ar' ? Language.Arabic : Language.English);
    this.loader.load(LoaderType.Nav);
    this.formInit();
    this.service.get(null)
      .then(result => {
        this.dataSource = new MatTableDataSource(result.list);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
      .finally(() => this.loader.load(LoaderType.Nav, false));
  }

  // private _loading(status: boolean = true) {
  //   this.loading = status;
  // }

  add() {
    if (this.dataSource.data.findIndex(i => i.id == 0) > -1)
      return;

    this.model = { id: 0, titles: ['', ''] };
    this.dataSource.data.unshift(this.model);

    this.form.patchValue({ titles: ['', ''] });

    this.titles.controls.forEach(c => c.reset());

    this.editingRowId = 0;

    this.deletingRowId = null;
    this.dataSource.filter = "";
    this.paginator.firstPage();
  }

  tryDelete(row: LookupDto) {
    let modal = this.confirm.confirm(new DeleteModal({ type: 'LKP_TITLE_VEHICLE_COLOR', title: row.titles[0] }));
    modal.then(m => {
      if (m.indexOf('CONFIRMED') > -1) {
        //delete handler
        this.delete(row.id);
      }
    });
  }

  delete(id) {
    this.loader.load(LoaderType.Body);
    if (this.editingRowId == 0) {
      this.dataSource.data.shift();
      this.dataSource.filter = "";
    }
    this.editingRowId = null;
    this.deletingRowId = id;

    this.service.delete(id).then(r => {
      if (r) {
        const i = this.dataSource.data.findIndex(o => o.id == id);
        this.dataSource.data.splice(i, 1);
        this.dataSource.filter = "";
        this.alert.success(DefaultAlertAction.DELETING);
      } else this.alert.failure(DefaultAlertAction.DELETING);
    }).catch(e => {
      ////console.log(e);
      //this.alert.error();
    }).finally(() => {
      this.loader.load(LoaderType.Body, false);
      this.deletingRowId = null;
    });
  }

  edit(id) {
    if (this.editingRowId == 0) {
      this.dataSource.data.shift();
      this.dataSource.filter = "";
    }
    this.editingRowId = id;
    this.model = id > 0 ? [...this.dataSource.data].find(c => c.id == id) : null;

    this.form.patchValue({
      titles: id > 0 ? this.model.titles : ['', '']
    });
  }

  onSubmit() {
    if (!this.form.valid)
      return Utils.validateAllFormFields(this.form);

    this.loader.load(LoaderType.Body);
    if (this.deletingRowId == null) {
      if (this.editingRowId == 0) {
        this.service.create(this.form.value).then(id => {
          if (id) {
            this.model.id = <number>id;
            //push new item to array instead
            this.model.titles = this.form.value.titles;
            this.alert.success(DefaultAlertAction.ADDING);
          } else this.alert.failure(DefaultAlertAction.ADDING);
        }).catch(e => this.edit(null))
          .finally(() => {
            this.editingRowId = null;
            this.deletingRowId = null;
            this.dataSource.filter = "";
            this.loader.load(LoaderType.Body, false);
          })
      } else {
        const cmd = this.form.value as LookupDto;
        cmd.id = this.editingRowId;
        this.service.update(cmd)
          .then(status => {
            if (status) {
              const i = this.dataSource.data.findIndex(as => as.id == this.editingRowId);
              this.dataSource.data[i].titles = this.form.value.titles;
              this.alert.success(DefaultAlertAction.UPDATING);
            } else this.alert.failure(DefaultAlertAction.UPDATING);
          }).catch(err => {
            // //console.log(err);
            //this.alert.error();
          }).finally(() => {
            this.loader.load(LoaderType.Body, false);
            this.editingRowId = null;
            this.submitted = false;
          });
      }
    }
  }

  applyFilter(e: any) {
    this.dataSource.filter = e.target.value.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.edit(null);
  }

  private formInit() {
    this.form = this.fb.group({
      titles: this.fb.array([
        ['', standardLocalizedControlValidators(Language.Arabic)],
        ['', standardLocalizedControlValidators(Language.English)]
      ])
    });
  }
}

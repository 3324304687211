import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { StocksService } from '../services/stocks.service';
import { TransactionsVm, TransactionDto, StockTransactionsSearchQuery } from '../models/transactions';
import { catchError, finalize, filter } from 'rxjs/operators';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { PaginationQuery } from '../../issuing/_shared/models/issuing.models';
import { populateAccountsSelect } from 'src/app/shared/helpers/populate-accounts-select';
import { lookup } from 'dns';
import { LookupDto } from '../../lookups/models/lookup';
import { TranslateService } from '@ngx-translate/core';


export class TransactionsDataSource implements DataSource<TransactionDto> {
   // add variables to hold the data and number of total records retrieved asynchronously
   // BehaviourSubject type is used for this purpose
   private TransactionsSubject = new BehaviorSubject<TransactionDto[]>([]);

   // to show the total number of records
   private countSubject = new BehaviorSubject<number>(0);
   public counter$ = this.countSubject.asObservable();

   constructor(private stocksService: StocksService, private loader: LoadingService, private translate: TranslateService) {

   }

   //loadTransactions(pageIndex: number, pageSize: number, sort: string, direction: string, filter: string = ''/*, stockTypeId: number = 0*/) {
   loadTransactions(pagination: PaginationQuery, query: StockTransactionsSearchQuery | string = null) {
      //this.stocksService.getTransactions(pageIndex, pageSize, sort, direction, filter/*, stockTypeId*/)
      //console.log(query);
      this.stocksService[(query == null || typeof query == 'string') ? 'getTransactions' : 'searchTransactions'](pagination, <any>query)
         .pipe(
            catchError(() => of([])),
            finalize(() => { })
         )
         .subscribe((result: TransactionsVm) => {
            const systemAccountLabel = this.translate.instant('ACC_SYSTEM_ACCOUNT');
            result.stockTransactions.forEach(transaction => {
               transaction.fromAccountParentAccounts = transaction.fromAccountParentAccounts ?
                  populateAccountsSelect([transaction.fromAccountParentAccounts])[0]
                  : <LookupDto>{ id: 0, titles: [systemAccountLabel] };
               transaction.toAccountParentAccounts = transaction.toAccountParentAccounts ?
                  populateAccountsSelect([transaction.toAccountParentAccounts])[0]
                  : <LookupDto>{ id: 0, titles: [systemAccountLabel] };
            });
            this.TransactionsSubject.next(result.stockTransactions);
            this.countSubject.next(result.recordsCount);
            this.loader.load(LoaderType.Nav, false);
            this.loader.load(LoaderType.Body, false);
         }
         );
   }

   connect(): Observable<TransactionDto[]> {
      return this.TransactionsSubject.asObservable();
   }

   disconnect(collectionViewer: CollectionViewer): void {
      this.TransactionsSubject.complete();
      this.countSubject.complete();
   }
}

import { SecurityGroupsVm, SecurityRolesVm, SecurityGroupDto, SecurityGroupsLookupVm, UsersSecurityGroupsVm } from './../models/security-groups.model';
import { Injectable } from '@angular/core';
import { ApiHandler } from '../../../core/api-handler';
import { Language } from 'src/app/core/models/enums/language';
import { EndPoint } from 'src/app/core/models/enums/end-point';

@Injectable()
export class SecurityService {
    constructor(private api: ApiHandler) { }

    public getAccountGroups(lang: Language = null) {
        return this.api.get<SecurityGroupsVm>(EndPoint.SECURITY_GROUPS, null, lang).toPromise();
    }

    public getAccountRoles(lang: Language = null) {
        return this.api.get<SecurityRolesVm>(EndPoint.SECURITY_ROLES, null, lang).toPromise();
    }

    public createAccountGroup(dto: SecurityGroupDto) {
        return this.api.create<SecurityGroupDto, string>(EndPoint.SECURITY_GROUPS, dto).toPromise();
    }

    public updateAccountGroup(dto: SecurityGroupDto) {
        return this.api.update(EndPoint.SECURITY_GROUPS, dto).toPromise();
    }

    public deleteAccountGroup(id: string) {
        return this.api.delete(EndPoint.SECURITY_GROUPS, id).toPromise();
    }

    public getAccountGroupsLookup(lang: Language = null) {
        return this.api.get<SecurityGroupsLookupVm>(EndPoint.SECURITY_GROUPS_LOOKUP, null, lang).toPromise();
    }

    public getUsersSecurityGroups() {
        return this.api.get<UsersSecurityGroupsVm>(EndPoint.SECURITY_USERS_GROUPS).toPromise();
    }

    public updateUsersSecurityGroups(dto: UsersSecurityGroupsVm) {
        return this.api.update(EndPoint.SECURITY_USERS_GROUPS, dto).toPromise();
    }


    public isClaimsApplicable(id: string) {
        return this.api.get<boolean>(EndPoint.SECURITY_CLAIMS_APPLICABLE, [id]).toPromise();
    }




    // validation

    //todo: convert to generic method, change language parameter to be global enum
    public validateGroupTitle(title: string, language: number) {
        //todo: implement async validation generic on one controller
    }
}
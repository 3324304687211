import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';

import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { exportToExcel } from 'src/app/shared/helpers/export-to-excel';
import { Language } from 'src/app/core/models/enums/language';
import { TripTiksClaimsService } from '../../services/trip-tiks-claims.service';
import { Gender } from 'src/app/core/models/enums/gender';
import { PrintObject } from 'src/app/core/models/enums/print-object';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { DeleteModal, UpdateModal } from 'src/app/shared/models/modal';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/core/models/enums/role';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CountriesService } from 'src/app/features/lookups/services/countries';
import { CountryDto } from 'src/app/features/lookups/models/country';
import { IntLicensePermitClass } from 'src/app/core/models/enums/license-permit-class';
import { populateAccountsSelect } from 'src/app/shared/helpers/populate-accounts-select';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { AccountsService } from 'src/app/features/accounts/services/accounts.service';
import { Router, ActivatedRoute } from '@angular/router';
//import { StockType } from 'src/app/core/models/enums/stock-type';
import { TripTiksClaimsDataSource } from '../../services/trip-tiks-claims.datasource';
import { PaginationQuery } from 'src/app/features/issuing/_shared/models/issuing.models';
import { TripTikClaimDto } from '../../models/trip-tik-claim.model';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { CustomsPortDto } from 'src/app/features/lookups/models/customs-port';
import { CustomsPortsService } from 'src/app/features/lookups/services/customs-ports.service';
import { PursuingZonesService } from 'src/app/features/lookups/services/pursuing-zones';
import { TripTikClaimsStatusesService } from 'src/app/features/lookups/services/trip-tik-claims-statuses.service';
import { _DocumentType } from 'src/app/core/models/enums/document-type';
import { TripTikClaimStatusDto } from 'src/app/features/lookups/models/trip-tik-claim-status';
import { isArray } from 'lodash';
import { Utils } from 'src/app/shared/services/utils';

@Component({
  selector: 'mac-trip-tiks-claims',
  templateUrl: './trip-tiks-claims.component.html',
  styleUrls: ['./trip-tiks-claims.component.scss'],
  providers: [
    TripTiksClaimsService,
    TripTikClaimsStatusesService,
    AccountsService,
    StockTypesService,
    CustomsPortsService,
    PursuingZonesService
  ]
})
export class TripTiksClaimsComponent implements OnInit {
  moment = moment;
  displayedColumns: string[] = ['entranceID', /*'created',*/ 'entranceDate', 'holderName', 'accountTitles', 'serial', 'stockType', 'vehiclePlateID', 'country', 'customsPort', 'pursuingZone', 'latestStatus', 'tools'];
  dataSource: TripTiksClaimsDataSource;
  lang: Language;
  advancedSearchMode: boolean;
  pursuingZones: LookupDto[] = [];
  accounts: LookupDto[] = [];
  customsPorts: CustomsPortDto[] = [];
  _customsPorts: CustomsPortDto[] = [];
  claimsCountries: LookupDto[] = [];
  stockTypes: StockTypeDto[] = [];
  query: any = null;
  baseStatuses: TripTikClaimStatusDto[] = [];
  nodeStatuses: TripTikClaimStatusDto[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild('filterBtn') filterBtn: ElementRef;

  get Role() { return Role; };
  get Gender() { return Gender; };
  get PrintObject() { return PrintObject; };
  get IntLicensePermitClass() { return IntLicensePermitClass };
  get Object() { return Object; };

  constructor(private loader: LoadingService,
    private tripTiksClaimsService: TripTiksClaimsService,
    public translate: TranslateService,
    private confirmService: ConfirmService,
    private alert: AlertService,
    public auth: AuthService,
    private fb: FormBuilder,
    private stockTypesService: StockTypesService,
    private tripTikClaimsStatusesService: TripTikClaimsStatusesService,
    private pursuingZonesService: PursuingZonesService,
    private customsPortsService: CustomsPortsService,
    private accountsService: AccountsService,
    private router: Router,
    private route: ActivatedRoute) { }

  async ngOnInit() {
    document.querySelector('mat-card').classList.remove('overflow-hidden');
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.loader.load(LoaderType.Nav);
    this.formInit();

    //if system account, accounts will be fetched from server, otherwise current account details will be fetched from local auth service
    if (this.auth.currentUser.isAdministrator) {
      this.accountsService.getChildrenAccountsLookup(0, false)
        .then(result => {
          this.accounts = result.list;// populateAccountsSelect(result.list);
        }).catch(e => this.searchForm.get('accountId').disable());
    } else {
      // this.accounts = [<LookupDto>{ id: this.auth.currentUser.accountId, titles: [this.auth.currentUser.accountTitles[this.lang]] }];
      // this.searchForm.patchValue({ accountId: this.auth.currentUser.accountId });
      // this.searchForm.get('accountId').disable();
    }

    this.route.queryParamMap.subscribe(p => {
      if (!p.has('pageIndex')) {
        this.advancedSearchMode = false;
        this.activeFilters = false;
        this.filter.nativeElement.value = '';
        this.paginator.page.emit({ pageIndex: 0, pageSize: this.paginator.pageIndex, length: this.paginator.length });
      }
    });

    if (this.route.snapshot.queryParams.query) {
      this.query = this.route.snapshot.queryParams.query;
      this.filter.nativeElement.value = this.query;
      this.statusId = this.route.snapshot.queryParams.statusId
      this.fetchData(this.query);
    }
    else if (this.route.snapshot.queryParams.accountId
      || this.route.snapshot.queryParams.stockTypeId
      || this.route.snapshot.queryParams.fromEntranceDate
      || this.route.snapshot.queryParams.toEntranceDate
      || this.route.snapshot.queryParams.entranceID
      || this.route.snapshot.queryParams.holderName
      || this.route.snapshot.queryParams.vehiclePlateID
      || this.route.snapshot.queryParams.countryId
      || this.route.snapshot.queryParams.customsPortId
      || this.route.snapshot.queryParams.pursuingZoneId
      || this.route.snapshot.queryParams.baseStatusId
      || this.route.snapshot.queryParams.statusId
      || this.route.snapshot.queryParams.serial) {
      this.activeFilters = true;

      this.searchForm.patchValue(this.route.snapshot.queryParams);

      const _stockTypeId = this.route.snapshot.queryParams.stockTypeId && this.route.snapshot.queryParams.stockTypeId.indexOf(',') > -1 ?
        this.route.snapshot.queryParams.stockTypeId.split(',') : [this.route.snapshot.queryParams.stockTypeId];

      const _countryId = this.route.snapshot.queryParams.countryId && this.route.snapshot.queryParams.countryId.indexOf(',') > -1 ?
        this.route.snapshot.queryParams.countryId.split(',') : [this.route.snapshot.queryParams.countryId];

      const _customsPortId = this.route.snapshot.queryParams.customsPortId && this.route.snapshot.queryParams.customsPortId.indexOf(',') > -1 ?
        this.route.snapshot.queryParams.customsPortId.split(',') : [this.route.snapshot.queryParams.customsPortId];

      if (_countryId) {
        this.onSelectCountry(_countryId);
      }

      this.searchForm.patchValue({ stockTypeId: _stockTypeId, countryId: _countryId, customsPortId: _customsPortId });

      //fix int for ng-select
      this.searchForm.patchValue({ accountId: !isNaN(this.route.snapshot.queryParams.accountId) ? parseInt(this.route.snapshot.queryParams.accountId) : null });

      this.query = this.searchForm.value;
      this.fetchData(this.query);
    } else this.fetchData(this.query);

    await Promise.all([
      this.stockTypesService.get(this.lang)
        .then(result =>
          this.stockTypes = result.list.filter(x => x.documentType == _DocumentType.TripTikDocument)),
      this.tripTikClaimsStatusesService.get(null, this.lang)
        .then(result => this.baseStatuses = result.list),
      this.pursuingZonesService.get(this.lang)
        .then(result => this.pursuingZones = result.list),
      this.customsPortsService.get(0, this.lang)
        .then(result => {
          this._customsPorts = !this.auth.currentUser.isAdministrator ?
            result.list.filter(x => this.auth.currentUser.claimsCountries.indexOf(x.countryId) > -1)
            : result.list;

          this.claimsCountries = [...new Map(this._customsPorts.map(item =>
            [item['countryId'], item])).values()].map(x => <LookupDto><any>{
              id: x.countryId,
              titles: x.countryTitles
            });
        })
    ]);
  }

  ngAfterViewInit() {
    this.dataSource.counter$
      .pipe(
        tap((count) => {
          this.paginator.length = count;
        })
      ).subscribe();

    merge(this.paginator.page, this.sort.sortChange)
      .pipe(
        tap(() => {
          this.dataSource.load({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction },
            this.advancedSearchMode || this.activeFilters ? this.searchForm.value : this.filter.nativeElement.value.trim(), this.statusId, this.pursuingZoneId)
        })
      ).subscribe(() => this.loader.load(LoaderType.Body));

    merge(fromEvent(this.filter.nativeElement, 'search'), fromEvent(this.filterBtn.nativeElement, 'click'))
      .pipe(
        tap(() => {
          this.advancedSearchMode = false;
          this.activeFilters = false;
          this.searchForm.reset();
          this.paginator.pageIndex = 0;
          this.dataSource.load({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction }, this.filter.nativeElement.value.trim(), this.statusId, this.pursuingZoneId)
        })
      ).subscribe(() => this.loader.load(LoaderType.Body));

    this.dataSource.connect()
      .subscribe(() => {
        let pagination: PaginationQuery = { pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction };
        let query = this.activeFilters ? this.searchForm.value : this.filter.nativeElement.value.trim();
        this.query = query;
        //console.log(this.query);
        if (typeof query == 'object') {
          query.stockTypeId = query.stockTypeId ? query.stockTypeId.toString() : null;
          query.countryId = query.countryId ? query.countryId.toString() : null;
          query.customsPortId = query.customsPortId ? query.customsPortId.toString() : null;
        }
        const statusId = null;//this.status.nativeElement.value;
        this.statusId = statusId;
        const pursuingZoneId = null;// this.pursuingZone.nativeElement.value;
        this.pursuingZoneId = pursuingZoneId;

        if (this.activeFilters)
          this.router.navigate(['.'], { relativeTo: this.route, queryParams: { ...pagination, ...query }, replaceUrl: true });
        else
          this.router.navigate(['.'], { relativeTo: this.route, queryParams: { ...pagination, query, statusId, pursuingZoneId }, replaceUrl: true });
      });
  }

  statusId?: number;
  pursuingZoneId?: number;

  filterWithStatusId(statusId) {
    //console.log(statusId);
    this.statusId = statusId;
    this.paginator.pageIndex = 0;
    this.dataSource.load({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction }, this.filter.nativeElement.value.trim(), this.statusId, this.pursuingZoneId);
  }

  filterWithPursuingZoneId(pursuingZoneId) {
    //console.log(pursuingZoneId);
    this.pursuingZoneId = pursuingZoneId;
    this.paginator.pageIndex = 0;
    this.dataSource.load({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction }, this.filter.nativeElement.value.trim(), this.statusId, this.pursuingZoneId);
  }


  fetchData(query, statusId?: number, pursuingZoneId?: number) {
    // //console.log('try fetch')
    this.paginator.pageIndex = this.route.snapshot.queryParams.pageIndex ? this.route.snapshot.queryParams.pageIndex : 0;
    this.paginator.pageSize = this.route.snapshot.queryParams.pageSize ? this.route.snapshot.queryParams.pageSize : 25;
    this.sort.active = this.route.snapshot.queryParams.sort ? this.route.snapshot.queryParams.sort : 'created';
    this.sort.direction = this.route.snapshot.queryParams.dir ? this.route.snapshot.queryParams.dir : 'desc';
    this.dataSource = new TripTiksClaimsDataSource(this.tripTiksClaimsService, this.loader);
    this.dataSource.load({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction }, query);
  }

  toggleAdvancedSearch() {
    this.advancedSearchMode = !this.advancedSearchMode;
    this.filter.nativeElement.value = '';
  }

  delete(dto: TripTikClaimDto) {
    //  if (dto.accountId != this.auth.accountId
    //     || !this.auth.currentUser.isInRole(Role.INT_LICENSES_DELETE))
    //     return;

    //delete confirm if not yet issued
    //if (!dto.issued) {
    let modal = this.confirmService.confirm(new DeleteModal({
      type: 'LIC_TITLE_LICENSE',
      title: `${dto.entranceID.toString()}/${dto.holderName}`,
      note: 'LIC_CONFIRM_DELETE_NOTE'
    }));

    modal.then(async m => {
      if (m.indexOf('CONFIRMED') > -1)
        this._delete(dto.entranceID);
    });
    // }
  }

  private _delete(id: string) {
    this.loader.load(LoaderType.Body);
    this.tripTiksClaimsService.delete(id)
      .then(result => {
        if (result) {
          this.alert.success(DefaultAlertAction.DELETING);
          this.dataSource.TripTiksClaimsSubject.next(this.dataSource.TripTiksClaimsSubject.value.filter(d => d.entranceID != id));
          this.dataSource.countSubject.next(this.dataSource.countSubject.value - 1);

        } else this.alert.failure(DefaultAlertAction.DELETING);
      })
      .finally(() => this.loader.load(LoaderType.Body, false));
  }

  // onExportToExcel(all: boolean = true) {
  //   this.loader.load(LoaderType.Body);
  //   if (all)
  //     this.tripTiksClaimsService[(this.query == null || typeof this.query == 'string') ? 'getClaims' : 'search']({ pageIndex: 0, pageSize: this.paginator.length, sort: this.sort.active, dir: this.sort.direction }, <any>this.query)
  //       .toPromise()
  //       .then(data => {
  //         this.exportToExcel(data.list);
  //       }).finally(() => this.loader.load(LoaderType.Body, false));
  //   else
  //     this.dataSource.connect().subscribe(data => {
  //       this.exportToExcel(data);
  //       this.loader.load(LoaderType.Body, false);
  //     }).unsubscribe();
  // }

  // onSelectNestedStatus(status: number) {
  //   //console.log(status);
  //   if (status == TripTikClaimStatus.Paid) {
  //     this.paymentFormInit();
  //   } else {
  //     Object.keys(this.payment.controls).forEach(ctrl => {
  //       this.payment.removeControl(ctrl);
  //     });
  //   }
  // }

  onSelectCountry(countries: number[]) {
    // //console.log(countries);
    // //console.log(this._customsPorts);
    // //console.log(this.customsPorts);
    // if (countries) {
    this.searchForm.get('customsPortId').disable();
    this.customsPorts = this._customsPorts.filter(x => countries.map(y => Number(y)).indexOf(x.countryId) > -1)
    this.searchForm.get('customsPortId').enable();

    // //console.log(this.searchForm.get('countryId').value);

    // }
  }


  async onSelectStatus(statusId: number) {
    this.searchForm.get('statusId').disable();
    this.nodeStatuses = await (await this.tripTikClaimsStatusesService.get(statusId, this.lang)).list;
    this.searchForm.get('statusId').enable();
  }


  onExportToExcel(all: boolean = true) {
    this.loader.load(LoaderType.Body);
    if (all)
      this.tripTiksClaimsService[(this.query == null || typeof this.query == 'string') ? 'getClaims' : 'search']({ pageIndex: 0, pageSize: this.paginator.length, sort: this.sort.active, dir: this.sort.direction }, <any>this.query)
        .toPromise()
        .then(data => {
          this.exportToExcel(data.list);
        }).finally(() => this.loader.load(LoaderType.Body, false));
    else
      this.dataSource.connect().subscribe(data => {
        this.exportToExcel(data);
        this.loader.load(LoaderType.Body, false);
      }).unsubscribe();
  }

  private exportToExcel(data: TripTikClaimDto[]) {
    const wscols = [
      { width: 12 },
      // { width: 12 },
      { width: 12 },
      { width: 28 },
      { width: 28 },
      { width: 16 },
      { width: 28 },
      { width: 16 },
      { width: 18 },
      { width: 18 },
      { width: 18 },
      { width: 18 },
    ];
    const autofilter = { ref: 'A1:K1' };
    const rows: string[][] = [];

    const headers = [
      this.translate.instant('TRP_CLM_FIELD_ENTRANCE_ID'),
      // this.translate.instant('TRP_CLM_FIELD_REGISTRATION_DATE'),
      this.translate.instant('TRP_CLM_FIELD_ENTRANCE_DATE'),
      this.translate.instant('TRP_CLM_FIELD_NAME'),
      this.translate.instant('ACC_FIELD_ACCOUNT_TITLE'),
      this.translate.instant('TRP_FIELD_TRIPTIK_SERIAL'),
      this.translate.instant('TRP_FIELD_TRIPTIK_TYPE'),
      this.translate.instant('TRP_FIELD_PLATE_ID'),
      this.translate.instant('TRP_CLM_LABEL_CLAIM_COUNTRY'),
      this.translate.instant('LKP_FIELD_CUSTOMS_PORT'),
      this.translate.instant('LKP_FIELD_PURSUING_ZONE'),
      this.translate.instant('TRP_CLM_FIELD_LATEST_STATUS')
    ];

    rows.push(headers);
    for (let row of data) {
      rows.push([
        row.entranceID,
        moment(row.created).format('DD/MM/YYYY'),
        // moment(row.entranceDate).format('DD/MM/YYYY'),
        row.holderName,
        row.accountTitles ? row.accountTitles[0] : 'N/A',
        row.serial.toString(),
        row.stockType.titles[0],
        row.vehiclePlateID ? row.vehiclePlateID : 'N/A',
        row.country.countryTitles[0],
        row.customsPort ? row.customsPort.titles[0] : 'N/A',
        row.pursuingZone ? row.pursuingZone.titles[0] : 'N/A',
        row.latestStatus ? row.latestStatus.titles[0] : 'N/A'
      ]);
    }

    exportToExcel(rows, wscols, autofilter, this.translate.currentLang == 'ar', 'Trip-Tik-Claims');
  }


  // private exportToExcel(data: TripTikClaimDto[]) {
  //   //  const wscols = [
  //   //     { width: 12 },
  //   //     { width: 28 },
  //   //     { width: 12 },
  //   //     { width: 18 },
  //   //     { width: 28 },
  //   //     { width: 18 },
  //   //     { width: 18 },
  //   //     { width: 18 },
  //   //     { width: 18 }
  //   //  ];
  //   //  const autofilter = { ref: 'A1:I1' };
  //   //  const rows: string[][] = [];

  //   //  const headers = [
  //   //     this.translate.instant('_LABEL_SERIAL'),
  //   //     this.translate.instant('_FIELD_NAME'),
  //   //     this.translate.instant('_LABEL_GENDER'),
  //   //     this.translate.instant('_FIELD_PHONE_NO'),
  //   //     this.translate.instant('ACC_FIELD_ACCOUNT_TITLE'),
  //   //     this.translate.instant('ISS_FIELD_PASSPORT_NUMBER'),
  //   //     this.translate.instant('ISS_LABEL_ISSUE_DATE'),
  //   //     this.translate.instant('ISS_LABEL_PRINT_OBJECT_PRINTED_COUNT', { param: this.translate.instant('LIC_LABEL_PRINT_OBJECT_LICENSE') }),
  //   //     this.translate.instant('ISS_LABEL_PRINT_OBJECT_PRINTED_COUNT', { param: this.translate.instant('LIC_LABEL_PRINT_OBJECT_STICKER') }),
  //   //  ];

  //   //  rows.push(headers);
  //   //  for (let row of data) {
  //   //     rows.push([
  //   //        row.serial,
  //   //        row.name,
  //   //        this.translate.instant(row.gender == Gender.Male ? '_LABEL_MALE' : '_LABEL_FEMALE'),
  //   //        row.phoneNumber,
  //   //        row.accountTitles[0],
  //   //        row.passportID,
  //   //        moment(row.issued).format('DD/MM/YYYY'),
  //   //        this.printObjectsCount(row.printLogs, PrintObject.IntLicenseCard),
  //   //        this.printObjectsCount(row.printLogs, PrintObject.IntLicenseSticker)
  //   //     ]);
  //   //  }

  //   //  exportToExcel(rows, wscols, autofilter, this.translate.currentLang == 'ar', 'Int-Licenses');
  // }

  onSubmit() {
    console.warn(this.searchForm.value);
    //console.warn(this.searchForm.pristine);

    // if (this.fromEntranceDate.value)
    //   this.fromEntranceDate.setValue(this.fromEntranceDate.value.toISOString());

    // if (this.toEntranceDate.value)
    //   this.toEntranceDate.setValue(this.toEntranceDate.value.toISOString());

    if (this.fromEntranceDate.value && typeof this.fromEntranceDate.value == 'object')
      this.fromEntranceDate.setValue(Utils.convertToServerDateTimeString(this.fromEntranceDate.value));

    if (this.toEntranceDate.value && typeof this.toEntranceDate.value == 'object')
      this.toEntranceDate.setValue(Utils.convertToServerDateTimeString(this.toEntranceDate.value));

    //swap dates
    if (this.fromEntranceDate.value && this.toEntranceDate.value && new Date(this.fromEntranceDate.value) > new Date(this.toEntranceDate.value)) {
      let _temp = this.fromEntranceDate.value;
      this.fromEntranceDate.setValue(this.toEntranceDate.value);
      this.toEntranceDate.setValue(_temp);
    }



    this.activeFilters = true;

    this.paginator.pageIndex = 0;
    this.paginator.page.emit({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, length: this.paginator.length });
  }

  // get created() { return this.searchForm.get('created'); };
  // get entranceDate() { return this.searchForm.get('entranceDate'); };

  get fromEntranceDate() { return this.searchForm.get('fromEntranceDate'); };
  get toEntranceDate() { return this.searchForm.get('toEntranceDate'); };

  searchForm: FormGroup;
  activeFilters: boolean;

  resetSearch() {
    this.advancedSearchMode = false;
    if (this.activeFilters) {
      this.activeFilters = false;
      this.paginator.pageIndex = 0;
      this.paginator.page.emit({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, length: this.paginator.length });
    }

    this.activeFilters = false;

    setTimeout(() => {
      this.searchForm.reset();
      this.query = null;
    }, 500);
  }

  private formInit() {
    this.searchForm = this.fb.group({
      accountId: [null],
      stockTypeId: [''],
      // stockTypes: [''],
      serial: [''],
      // created: [''],
      // entranceDate: [''],
      fromEntranceDate: [''],
      toEntranceDate: [''],
      entranceID: [''],
      holderName: [''],
      vehiclePlateID: [''],
      countryId: [''],
      customsPortId: [''],
      // customsPorts: [''],
      pursuingZoneId: [''],
      // latestStatusId: [''],
      baseStatusId: [''],
      statusId: ['']
    });
  }
}







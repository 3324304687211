import { Injectable } from "@angular/core";
import { ApiHandler } from "src/app/core/api-handler";
import { EndPoint } from "src/app/core/models/enums/end-point";
import { PaginationQuery } from "../../_shared/models/issuing.models";
import { CouponDto, CouponsVm, CouponDetailVm } from "../models/coupon";

@Injectable({
  providedIn: "root",
})
export class CoupounService {
  constructor(private api: ApiHandler) {}

  create(coupon: any) {
    return this.api
      .create(EndPoint.E_lICENSE_COUPON_CREATE, coupon)
      .toPromise();
  }

  update(coupon: any) {
    return this.api
      .update(EndPoint.E_lICENSE_COUPON_UPDATE, coupon)
      .toPromise();
  }

  getCoupon(id: string, pagination: PaginationQuery, query: string = "") {
    return this.api.get<CouponsVm>(EndPoint.E_lICENSE_COUPON, [id], {
      ...pagination,
      query,
    });
  }
  getCouponDetails(id: string, pagination: PaginationQuery, query: string = "") {
    return this.api.get<CouponDetailVm>(EndPoint.E_lICENSE_VIEW_COUPON_DETAILS, [id], {
      ...pagination,
      query,
    });
  }
}

import { Component, OnInit, ElementRef } from '@angular/core';
import { Gender } from 'src/app/core/models/enums/gender';
import { IntLicenseDetailsDto } from '../../models/int-license';
import { FileLoaderService } from 'src/app/shared/services/file-loader.service';
import { Utils } from 'src/app/shared/services/utils';
import { Language } from 'src/app/core/models/enums/language';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'mac-commitment-print',
  templateUrl: './commitment-print.component.html',
  styleUrls: ['./commitment-print.component.scss']
})
export class CommitmentPrintComponent implements OnInit {

  dto: IntLicenseDetailsDto;
  // personalPhoto: string;
  // passportPhoto: string;
  // licensePhoto: string;
  issueDate: Date;

  get Gender() { return Gender };
  get Language() { return Language; };

  constructor(private elRef: ElementRef, private fileLoader: FileLoaderService) { }

  ngOnInit(): void {

  }

  async print(dto: IntLicenseDetailsDto) {
    //console.log(dto);
    this.dto = dto;
  
    // this.personalPhoto = (<any> ).changingThisBreaksApplicationSecurity;
    // this.passportPhoto = (<any>await (await this.fileLoader.loadAsync(dto.attachments[1] as string))).changingThisBreaksApplicationSecurity;
    // this.licensePhoto = (<any>await (await this.fileLoader.loadAsync(dto.attachments[2] as string))).changingThisBreaksApplicationSecurity;

    // const x = await (await this.fileLoader.loadAsync(dto.attachments[0] as string))
    this.issueDate = this.dto.issued ? this.dto.issued : new Date();

    setTimeout(() => {
      const printHtmlContent = this.elRef.nativeElement.innerHTML;
      Utils.printHtml(printHtmlContent);
    }, 500);
  }

}

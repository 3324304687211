export class SecurityGroupsVm {
    groups: SecurityGroupDto[];
}

export class SecurityGroupsLookupVm {
    groups: SecurityGroupLookupDto[];
}

export class SecurityRolesVm {
    availableActions: SecurityAction[];
    objectsActions: ObjectActionDto[];
}

export class UsersSecurityGroupsVm {
    availableGroups: SecurityGroup[];
    usersGroups: UserSecurityGroupsDto[];
}

export class UserSecurityGroupsDto {
    user: UserDto;
    securityGroups: string[];
}

export class UserDto {
    id: string;
    names: string[];
}

export class SecurityGroupLookupDto {
    id: string;
    titles: string[];
}

export class SecurityGroupDto {
    id: string;
    titles: string[];
    descriptions: string[];
    availableActions: SecurityAction[];
    objectsActions: ObjectActionDto[];
    isSystemGroup: boolean;
}

export class ObjectActionDto {
    securityObject: SecurityObject;
    actions: number[];
}

export interface SecurityAction {
    id: number;
    titles: string[];
}

export interface SecurityObject {
    id: number;
    titles: string[];
}

export interface SecurityGroup {
    id: string;
    titles: string[];
    descriptions: string[];
}
import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { CityDto, CitiesVm } from '../models/city';
import { EndPoint } from 'src/app/core/models/enums/end-point';

@Injectable()
export class CitiesService {
    constructor(private api: ApiHandler) { }

    get(countryId = 0, lang = null) {
        return this.api.get<CitiesVm>(EndPoint.CITIES, countryId, { lang }).toPromise();
    }

    create(dto: CityDto) {
        return this.api.create<CityDto, number>(EndPoint.CITIES, dto).toPromise();
    }

    update(dto: CityDto) {
        return this.api.update(EndPoint.CITIES, dto).toPromise();
    }

    delete(id: number) {
        return this.api.delete(EndPoint.CITIES, id).toPromise();
    }
}
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CitiesService } from '../../services/cities';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { CountriesService } from '../../services/countries';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DeleteModal } from 'src/app/shared/models/modal';
import { Language } from 'src/app/core/models/enums/language';
import { TranslateService } from '@ngx-translate/core';
import { CountryDto } from '../../models/country';
import { CityDto } from '../../models/city';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { standardLocalizedControlValidators } from 'src/app/shared/services/custom-validators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/core/models/enums/role';
import { Utils } from 'src/app/shared/services/utils';
import * as _ from 'lodash';

@Component({
  selector: 'mac-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss'],
  providers: [CitiesService, CountriesService]
})
export class CitiesComponent implements OnInit {
  displayedColumns: string[] = ['country', 'titles', 'tools'];
  dataSource: MatTableDataSource<CityDto>;
  editingRowId: number = null;
  deletingRowId: number = null;
  //model: CityDto;
  submitted: boolean;
  validators: ElementRef[] = [];
  countries: CountryDto[];
  _countries: CountryDto[];
  form: FormGroup;
  lang: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  get titles() { return this.form.get('titles') as FormArray; }
  get countryId() { return this.form.get('countryId'); }
  get Role() { return Role; };

  constructor(private service: CitiesService,
    public auth: AuthService,
    private countryServie: CountriesService,
    private alert: AlertService,
    private loader: LoadingService,
    private confirmService: ConfirmService,
    private translate: TranslateService,
    private fb: FormBuilder) { }

  ngOnInit() {
    document.querySelector('mat-card').classList.remove('overflow-hidden');
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.loader.load(LoaderType.Nav);
    this.formInit();
    Promise.all([this.countryServie.get(null)
      .then(c => {
        this.countries = c.list;
        this._countries = _.cloneDeep(c.list).map(dto => {
          dto.countryTitles = dto.countryTitles[this.lang];
          return dto;
        })
      }),
    this.service.get(0, null)
      .then(result => {
        this.dataSource = new MatTableDataSource(result.list);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      })])
      .finally(() => this.loader.load(LoaderType.Nav, false));
  }

  add() {
    if (this.dataSource.data.findIndex(i => i.id == 0) > -1)
      return;

    const newDto = { id: 0, countryId: this.countries[0].id, titles: ['', ''] };

    this.dataSource.data.unshift(newDto);

    this.editingRowId = 0;
    this.form.patchValue({ titles: ['', ''] });

    this.countryId.reset();
    this.titles.controls.forEach(c => c.reset());

    this.deletingRowId = null;
    this.dataSource.filter = "";
    this.paginator.firstPage();
  }

  tryDelete(row: CityDto) {
    let modal = this.confirmService.confirm(new DeleteModal({ type: 'LKP_TITLE_CITY', title: row.titles[this.lang] }));
    modal.then(m => {
      if (m.indexOf('CONFIRMED') > -1) {
        //delete handler
        this.delete(row.id);
      }
    });
  }

  delete(id) {
    this.submitted = true;
    this.loader.load(LoaderType.Body);
    if (this.editingRowId == 0) {
      this.dataSource.data.shift();
      this.dataSource.filter = "";
    }
    this.editingRowId = null;
    this.deletingRowId = id;

    this.service.delete(id).then(status => {
      if (status) {
        const i = this.dataSource.data.findIndex(o => o.id == id);
        this.dataSource.data.splice(i, 1);
        this.dataSource.filter = "";
        this.alert.success(DefaultAlertAction.DELETING);
      } else this.alert.failure(DefaultAlertAction.DELETING);
    }).catch(e => {
      ////console.log(e);
      //this.alert.error();
    }).finally(() => {
      this.deletingRowId = null;
      this.loader.load(LoaderType.Body, false);
    });
  }

  edit(id) {
    if (this.editingRowId == 0) {
      this.dataSource.data.shift();
      this.dataSource.filter = "";
    }
    this.editingRowId = id;

    const dto = id > 0 ? [...this.dataSource.data].find(c => c.id == id) : null;

    this.form.patchValue({
      titles: id > 0 ? dto.titles : ['', ''],
      countryId: id > 0 ? dto.countryId : ''
    });


    // this.model = id > 0 ? [...this.dataSource.data].find(c => c.id == id) : null;

    // this.form.patchValue({
    //   titles: id > 0 ? this.model.titles : ['', ''],
    //   countryId: id > 0 ? this.model.countryId : ''
    // });
  }

  onSubmit() {
    if (!this.form.valid)
      return Utils.validateAllFormFields(this.form);

    this.loader.load(LoaderType.Body);

    const cmd = this.form.value as CityDto;
    //console.log(cmd);
    if (this.deletingRowId == null) {
      if (this.editingRowId == 0) {
        this.service.create(cmd).then(id => {
          if (id) {
            cmd.id = id;
            cmd.countryTitles = <[]>this.countries.find(c => c.id == cmd.countryId).countryTitles;
            this.dataSource.data[0] = cmd;
            this.dataSource.filter = "";
            this.alert.success(DefaultAlertAction.ADDING);
          } else this.alert.failure(DefaultAlertAction.ADDING);

        }).catch(e => this.edit(null))
          .finally(() => {
            this.editingRowId = null;
            this.deletingRowId = null;
            this.loader.load(LoaderType.Body, false);
          })
      } else {
        cmd.id = this.editingRowId;
        this.service.update(cmd).then(status => {
          if (status) {
            const i = this.dataSource.data.findIndex(i => i.id == this.editingRowId);
            this.dataSource.data[i].titles = cmd.titles;
            this.alert.success(DefaultAlertAction.UPDATING);
          } else this.alert.failure(DefaultAlertAction.UPDATING);
        }).catch(e => {
          ////console.log(e);
          //this.alert.error();
        }).finally(() => {
          this.editingRowId = null;
          this.loader.load(LoaderType.Body, false);
        });
      }
    }
  }

  applyFilter(e: any) {
    this.dataSource.filter = e.target.value.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.edit(null);
  }

  private formInit() {
    this.form = this.fb.group({
      countryId: ['', [Validators.required]],
      titles: this.fb.array([
        ['', standardLocalizedControlValidators(Language.Arabic)],
        ['', standardLocalizedControlValidators(Language.English)]
      ])
    });
  }
}

import { Component } from '@angular/core';
import { ConfirmState, IModalOptions } from '../../models/modal';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Utils } from '../../services/utils';

@Component({
  selector: 'mac-modal',
  templateUrl: './modal.component.html'
})
export class ConfirmModalComponent {
  options: IModalOptions;
  form: FormGroup;

  get attachment() { return this.form.get('attachment'); };
  get inputNote() { return this.form.get('inputNote'); };
  get isDamaged() { return this.form.get('isDamaged'); };

  constructor(private state: ConfirmState,
    private fb: FormBuilder,
    public translate: TranslateService) {
    this.options = state.options;
  }

  ngOnInit() {
    this.form = this.fb.group({
      inputNote: ['', [Validators.required]],
      isDamaged: ['', [Validators.required]],
      attachment: ['']
    });
  }

  onSubmit() {
    if ((this.options.requestType || this.options.inputNote) && this.form.invalid) {

      if (!this.options.requestType)
        this.isDamaged.setErrors(null);

      if (!this.options.inputNote)
        this.inputNote.setErrors(null);

      if (this.form.invalid)
        return Utils.validateAllFormFields(this.form);
    }

    //console.log(this.form.value);
    this.confirm(this.form.value);
    //this.confirm(this.options.inputNote ? this.inputNote.value : null);
  }

  confirm(data = null) {
    const result = ['CONFIRMED'];
    data ? result.push(data) : undefined;
    this.state.modal.close(result);
  }

  discard() {
    this.state.modal.dismiss('ignored');
  }

  
  //attachments
  preview($event, control: FormControl) {
    const tgt = $event.target || window.event.srcElement,
      files = Array.isArray($event) ? $event : tgt.files;

    if (FileReader && files && files.length) {
      const fr = new FileReader();
      fr.onload = (readerEvt) => {
        //console.log(readerEvt.target.fileName);
        if (fr.result.toString().length <= 2 * 2 ** 20) {
          control.setValue(fr.result.toString());
          //console.log(fr)
          this.fileName = $event.target.files[0].name;
        }
        else
          alert('file limit is 2 MB.');
      };
      fr.readAsDataURL(files[0]);
    }
    else {
      //console.error('not supported!');
    }
  }

  fileName;
  fileDropped(file, control: FormControl): void {
    //console.log(file);
    control.setValue(file.url);
    this.fileName = file.name;
  }

  removeAttachment() {
    //console.log('try remove att!');
    this.fileName = null;
    this.attachment.setValue(null);
  }
}
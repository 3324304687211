import { Component, OnInit, ElementRef } from '@angular/core';
import { TransactionDto } from '../../models/transactions';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'src/app/shared/services/utils';

@Component({
  selector: 'mac-lic-transactions-print',
  templateUrl: './lic-transactions-print.component.html',
  styleUrls: ['./lic-transactions-print.component.scss']
})
export class LicTransactionsPrintComponent implements OnInit {

  transaction: TransactionDto;
  receiptTemplate: string;
  transactionLevel: number;

  constructor(private elRef: ElementRef, public translate: TranslateService) { }

  ngOnInit(): void {
  }

  print(transaction: TransactionDto) {
    this.transaction = transaction;
    this.transactionLevel = transaction.fromAccountId && transaction.toAccountId ? 1 : 0;
    this.receiptTemplate = `/assets/images/StockTransactionsReceipt${this.transactionLevel}-${this.translate.currentLang}.jpeg`;

    setTimeout(() => {
      const printHtmlContent = this.elRef.nativeElement.innerHTML;// document.getElementById('stockReceipt').innerHTML;
      Utils.printHtml(printHtmlContent);
    }, 500);
  }

}

import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Language } from 'src/app/core/models/enums/language';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { ValidationDto } from 'src/app/core/models/validation';
import { SystemObject } from 'src/app/core/models/enums/system-object';
import { UserDto, UsersVm, UserProfileVm, UserMetaVm, UserDataVm } from '../models/user';
import { Observable } from 'rxjs';

@Injectable()
export class UsersService {
    constructor(private api: ApiHandler) { }

    public getUserMeta(lang: Language = null) {
        return this.api.get<UserMetaVm>(EndPoint.SECURITY_USER_META, null, lang).toPromise();
    }

    public getUserProfile(lang: Language = null): Promise<UserProfileVm> {
        return this.api.get<UserProfileVm>(EndPoint.SECURITY_USER_PROFILE, null, lang).toPromise();
    }

    public updateUserProfile(profile: UserProfileVm) {
        return this.api.update(EndPoint.SECURITY_USER_PROFILE, profile).toPromise();
    }

    public getAccountUsers(accountId: number = 0, lang: Language = null): Promise<UsersVm> {
        return this.api.get<UsersVm>(EndPoint.SECURITY_USERS, accountId, { lang }).toPromise();
    }

    public getUsers(pageIndex: number, pageSize: number, sort: string, direction: string, filter: string = '', accountId: number = 0): Observable<UsersVm> {
        return this.api.get<UsersVm>(EndPoint.SECURITY_USERS, accountId, { pageIndex, pageSize, sort, direction, filter });
    }

    public getUserData(id: string, lang: Language = null): Promise<UserDataVm> {
        return this.api.get<UserDataVm>(EndPoint.SECURITY_USER, id, lang).toPromise();
    }

    public updateUserData(user: UserDto) {
        return this.api.update(EndPoint.SECURITY_USER, user).toPromise();
    }

    public createUser(user: UserDto) {
        return this.api.create(EndPoint.SECURITY_USER, user).toPromise();
    }

    public deleteUser(id: string) {
        return this.api.delete(EndPoint.SECURITY_USER, id).toPromise();
    }

    public updateUserStatus(id: string, active: boolean) {
        return this.api.update(EndPoint.SECURITY_USER, null, [id, active]).toPromise();
    }


    private duplicatedUsername(username: string) {
        const dto = new ValidationDto();
        dto.objectType = SystemObject.USER;
        dto.value = username;

        return this.api.validate(dto);
    }

    public validateUsernameNotTaken = (control: AbstractControl) => {
        return this.duplicatedUsername(control.value).pipe(
            map(res => {
                return res ? null : { duplicatedUsername: true };
            }));
    }
}





// export class AccountUserDto {
//     id: string;
//     username: string;
//     name: string;
//     email: string;
//     phoneNumber: string;
//     isActive: boolean;
//     avatar: string;
//     securityGroups: string[];
// }


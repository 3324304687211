import { DefaultAlertAction } from './../../../../shared/models/default-alert-action';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './../../../../core/auth/auth.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UsersService } from 'src/app/features/security/services/users.service';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DeleteModal, UpdateModal } from 'src/app/shared/models/modal';
import { Role } from 'src/app/core/models/enums/role';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDto, UsersVm } from '../../models/user';
import { AccountMetaDto } from 'src/app/features/accounts/models/account';
import { AccountsService } from 'src/app/features/accounts/services/accounts.service';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { populateAccountsSelect } from 'src/app/shared/helpers/populate-accounts-select';
import { Language } from 'src/app/core/models/enums/language';
import { UsersDataSource } from '../../services/users-datasource';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs/internal/observable/merge';
import { MatSelect } from '@angular/material/select';
import { fromEvent } from 'rxjs';
import { Location } from '@angular/common';

@Component({
    selector: 'mac-account-users',
    templateUrl: './account-users.component.html',
    styleUrls: ['./account-users.component.scss'],
    providers: [UsersService, AccountsService]
})
export class AccountUsersComponent implements OnInit {
    displayedColumns: string[] = ['names', 'username', 'email', 'phoneNumber', 'created', 'permissions', 'isActive', 'tools'];
    cashedVm: UsersVm;
    accounts: LookupDto[];
    lang: Language;
    accountId: number = 0;
    dataSource: UsersDataSource;
    parentAccounts: AccountMetaDto[] = [];
    badgeClasses: string[];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('account', { static: true }) account: ElementRef<any>;
    @ViewChild('filter', { static: true }) filter: ElementRef<HTMLInputElement>;
    @ViewChild('filterBtn') filterBtn: ElementRef;

    get Role() { return Role; }

    constructor(private service: UsersService,
        private confirmService: ConfirmService,
        private loader: LoadingService,
        private alert: AlertService,
        public auth: AuthService,
        public translate: TranslateService,
        public route: ActivatedRoute,
        private accountsService: AccountsService,
        private location: Location,
        private router: Router) { }

    ngOnInit() {
        document.querySelector('mat-card').classList.remove('overflow-hidden');
        this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
        this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);
        this.accountId = Number(this.route.snapshot.paramMap.get('accountId'));
        this.loader.load(LoaderType.Nav);

        if (!this.auth.currentUser.isInRole(Role.USERS_SECURITY_GROUPS_READ)) {
            const i = this.displayedColumns.findIndex(c => c == "permissions");
            this.displayedColumns.splice(i, 1);
        }

        if (!this.auth.currentUser.isInRole(Role.USERS_UPDATE)
            && !this.auth.currentUser.isInRole(Role.USERS_DELETE))
            this.displayedColumns.splice(this.displayedColumns.length - 1, 1);

        this.route.params.subscribe(p => {
            if (p.accountId == 0) {
                //console.warn(this.filter.nativeElement.value);
                this.filter.nativeElement.value = "";
                this.accountId = 0;
                //this.account.select(0);
                //this.account.nativeElement.value = 0;
                //this.account.valueChange.emit();
            }
        });

        this.sort.active = 'names';
        this.sort.direction = 'asc';
        this.paginator.pageSize = 10;
        this.dataSource = new UsersDataSource(this.service, this.loader);
        this.dataSource.loadUsers(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, '', this.accountId);

        //this.loader.load(LoaderType.Body);
        // this.accountsService.getChildrenAccountsLookup(!this.auth.isSystemAccount ? this.auth.accountId : 0).then(d => {
        //     this.accounts = populateAccountsSelect(d.list);
        //     this.accounts.unshift({ id: 0, titles: [["الكل", "All"][this.lang]] });
        // }).finally(() => this.loader.load(LoaderType.Body, false));
    }

    filterWithAccount(id) {
        //console.log(id);
        this.accountId = id;
        //this.paginator.pageIndex = 0;
        this.paginator.page.emit({ pageIndex: 0, pageSize: this.paginator.pageSize, length: this.paginator.length });
        // this.dataSource.loadUsers(0, this.paginator.pageSize, this.sort.active, this.sort.direction, '', this.accountId);

    }

    async ngAfterViewInit() {
        this.loader.load(LoaderType.Body);
        this.accounts = /*populateAccountsSelect(*/await (await this.accountsService.getChildrenAccountsLookup(!this.auth.currentUser.isSystemAccount ? this.auth.currentUser.accountId : 0)).list/*)*/;
        this.accounts.unshift({ id: 0, titles: [["الكل", "All"][this.lang]] });
        this.loader.load(LoaderType.Body, false);

        this.dataSource.counter$
            .pipe(
                tap((count) => {
                    this.paginator.length = count;
                })
            ).subscribe();

        merge(this.paginator.page, this.sort.sortChange)
            .pipe(
                tap(() => {
                    ////console.log(this.account.value);
                    this.dataSource.loadUsers(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, this.filter.nativeElement.value.trim(), this.accountId)
                })).subscribe(() => this.loader.load(LoaderType.Body));

        merge(fromEvent(this.filter.nativeElement, 'search'), fromEvent(this.filterBtn.nativeElement, 'click'))
            .pipe(
                tap(() => {
                    this.paginator.pageIndex = 0;
                    this.dataSource.loadUsers(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, this.filter.nativeElement.value.trim(), this.accountId)
                })
            ).subscribe(() => this.loader.load(LoaderType.Body));

        this.dataSource.connect().subscribe(() => {
            this.router.navigate([`/${this.translate.currentLang}/security/users/list/${this.accountId}`], { replaceUrl: true });
            //this.location.replaceState(`/${this.translate.currentLang}/security/users/list/${this.accountId}`);
        });
    }

    tryDelete(row: UserDto) {
        if (row.id == this.auth.currentUser.userId || (!this.auth.currentUser.isSystemAccount && row.accountId != this.auth.currentUser.accountId) || !this.auth.currentUser.isInRole(Role.USERS_DELETE))
            return;

        let modal = this.confirmService.confirm(new DeleteModal({
            type: 'SEC_LINK_USER_ACCOUNT',
            title: row.names ? row.names[0] : row.username, //todo: get with lang index
            note: 'SEC_USER_CONFIRM_DELETE_NOTE'
        }));
        modal.then(m => {
            if (m.indexOf('CONFIRMED') > -1) {
                this.delete(row.id);
            }
        });
    }

    tryUpdateStatus(row: UserDto, active) {
        if (row.id == this.auth.currentUser.userId || (!this.auth.currentUser.isSystemAccount && row.accountId != this.auth.currentUser.accountId) || !this.auth.currentUser.isInRole(Role.USERS_UPDATE))
            return;

        if (!active) {
            let modal = this.confirmService.confirm(new UpdateModal({
                type: 'SEC_LINK_USER_ACCOUNT',
                confirmQuestion: 'SEC_USER_CONFIRM_DEACTIVATE_CONFIRMATION_QUESTION',
                title: row.names ? row.names[0] : row.username,
                action: '_ACTION_DEACTIVATE',
                submitText: '_ACTION_DEACTIVATE',
                note: 'SEC_USER_CONFIRM_DEACTIVATE_NOTE',
                dangerNote: ' '
            }));
            const _data = this.dataSource.UsersSubject.value;
            const i = _data.findIndex(d => d.id == row.id);
            modal.then(m => {
                if (m.indexOf('CONFIRMED') > -1) {
                    this.updateStatus(row.id, active);
                }
            }).catch(e => {
                _data[i].isActive = true;
            });
        } else this.updateStatus(row.id, active);
    }

    private delete(id: string) {
        this.loader.load(LoaderType.Body);
        this.service.deleteUser(id)
            .then(r => {
                if (r) {
                    this.alert.success(DefaultAlertAction.DELETING);
                    this.dataSource.UsersSubject.next(this.dataSource.UsersSubject.value.filter(d => d.id != id));
                    this.dataSource.countSubject.next(this.dataSource.countSubject.value - 1);
                } else this.alert.failure(DefaultAlertAction.DELETING);
            })
            .finally(() => this.loader.load(LoaderType.Body, false));
    }

    private updateStatus(id, active) {
        const _data = this.dataSource.UsersSubject.value;
        const i = _data.findIndex(d => d.id == id);
        this.loader.load(LoaderType.Body);
        this.service.updateUserStatus(id, active)
            .then(r => {
                if (r)
                    this.alert.success(active ? '_ACTION_ACTIVATING' : '_ACTION_DEACTIVATING')
                else {
                    _data[i].isActive = true;
                    this.alert.failure(active ? '_ACTION_ACTIVATING' : '_ACTION_DEACTIVATING');
                }
            })
            .catch(e => {
                _data[i].isActive = true;
            })
            .finally(() => this.loader.load(LoaderType.Body, false));
    }
}
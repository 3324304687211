import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/shared/services/loading';
import { ActivatedRoute, Router } from '@angular/router';
import { Language } from '../../models/enums/language';
import { NgZone } from "@angular/core";


@Component({
  selector: 'mac-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  lang: Language = Language.Arabic;
  loading: boolean = false;
  //DeterminateLoading: { status: boolean, value: number } = { status: false, value: 0 };
  authenticated: boolean;


  get Language() {
    return Language;
  }

  switchLangEnabled = true;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private loader: LoadingService,
    private zone: NgZone,
    public authService: AuthService,
    public translate: TranslateService) {

    this.authenticated = this.authService.isAuthenticated();
    AuthService.authActivity.subscribe(loggedin => this.authenticated = loggedin);
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe(_lang => this.lang = _lang.lang == 'en' ? Language.English : Language.Arabic);
    this.switchLangEnabled = window.location.href.indexOf('/verify/') == -1;
  }

  toggleLang() {
    if (this.router.url.indexOf('/login') > - 1) {
      this.translate.use(this.translate.currentLang == 'ar' ? 'en' : 'ar');
    } else {
      const url = this.router.url.split('/');
      ////console.log(url);
      url[1] = this.translate.currentLang == 'ar' ? 'en' : 'ar';
      //this.router.navigate(url);
      //url[0] = "/";
      const _url = url.join('/');
      ////console.log(_url);
      this.router.initialNavigation();
      //todo: check why component doesn't reload with language change
      //this.router.navigate(url);

      window.location.href = _url;

      //   this.zone.run(() => {
      //     this.router.navigate([_url], { skipLocationChange: true });
      // });
    }

  }
}

import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { PaginationQuery } from '../../_shared/models/issuing.models';
import { CouponDto, CouponSearchQuery, CouponDetailVm } from "../models/coupon";
import { CoupounService } from "./coupoun.service";


export class CouponDetailDataSource implements DataSource<CouponDto> {
    // add variables to hold the data and number of total records retrieved asynchronously
    // BehaviourSubject type is used for this purpose
    public couponDetailSubject = new BehaviorSubject<CouponDto[]>([]);

    // to show the total number of records
    public countSubject = new BehaviorSubject<number>(0);
    public counter$ = this.countSubject.asObservable();

    constructor(private couponService: CoupounService, private loader: LoadingService) { }

    loadCouponDetails(id: string, pagination: PaginationQuery, query: CouponSearchQuery | string = null) {

        this.couponService[(query == null || typeof query == 'string') ? 'getCouponDetails' : 'search'](id, pagination, <any>query)
            .pipe(
                catchError(() => of([])),
                finalize(() => { })
            )
            .subscribe((result: CouponDetailVm) => {
                this.couponDetailSubject.next(result.couponLicense);
                this.loader.load(LoaderType.Nav, false);
                this.loader.load(LoaderType.Body, false);
            });
    }
    connect(): Observable<CouponDto[]> {
        return this.couponDetailSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.couponDetailSubject.complete();
        this.countSubject.complete();
    }
}

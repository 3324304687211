import { DefaultAlertAction } from './../../../../shared/models/default-alert-action';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/features/security/services/users.service';
import { SecurityService } from '../../services/security.service';
import { SecurityGroupLookupDto } from '../../models/security-groups.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { Utils } from 'src/app/shared/services/utils';
import { comparePasswords, usernameValidator, numbersOnlyValidator, nonEnglishCharactersValidator, nonArabicCharactersValidator } from 'src/app/shared/services/custom-validators';
import { Language } from 'src/app/core/models/enums/language';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserDto } from '../../models/user';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { CustomsPortsService } from 'src/app/features/lookups/services/customs-ports.service';

@Component({
  selector: 'mac-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss'],
  providers: [UsersService, SecurityService, CustomsPortsService]
})
export class ManageUserComponent implements OnInit {

  userForm: FormGroup;
  userId: string;
  cashedUserData: UserDto;
  loading: boolean;
  updateMode: boolean = false;
  changingPassword: boolean = false;
  groups: SecurityGroupLookupDto[] = [];
  _claimsCountries: LookupDto[] = [];
  lang: number = 0;
  isClaimsApplicable: boolean;
  claimsSystemGroupId: string = "#";
  isClaimsGroupSelected: boolean;

  get names() { return this.userForm.get('names') as FormArray; }
  get username() { return this.userForm.get('username'); }
  get phoneNumber() { return this.userForm.get('phoneNumber'); }
  get email() { return this.userForm.get('email'); }
  get password() { return this.userForm.get('pass.password'); }
  get passwordConfirm() { return this.userForm.get('pass.passwordConfirm'); }
  get securityGroups() { return this.userForm.get('securityGroups'); }
  get claimsCountries() { return this.userForm.get('claimsCountries'); }



  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private securityService: SecurityService,
    private customsPortsService: CustomsPortsService,
    private loader: LoadingService,
    private alert: AlertService,
    public translate: TranslateService,
    public auth: AuthService) { }

  async ngOnInit() {
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.userId = this.activatedRoute.snapshot.paramMap.get("id");
    this.updateMode = this.userId ? true : false;

    this.loader.load(LoaderType.Nav);
    this.formInit();
    this.groups = await (await this.securityService.getAccountGroupsLookup()).groups;

    if (this.auth.currentUser.isAdministrator)
      this.claimsSystemGroupId = this.groups.find(x => x.titles[1] == "Claims").id;

    const customsPorts = (await this.customsPortsService.get(this.lang)).list;



    this._claimsCountries = [...new Map(customsPorts.map(item =>
      [item['countryId'], item])).values()].map(x => <LookupDto><any>{
        id: x.countryId,
        titles: x.countryTitles
      });



    //console.log(this.groups);
    //todo: filter by lang


    //console.log(this.claimsCountries);
    // const arrayUniqueByKey 


    if (this.userId) {

      //this.isClaimsApplicable = await this.securityService.isClaimsApplicable(this.userId);

      this.usersService.getUserData(this.userId)
        .then(result => {
          //console.log(result);

          this.isClaimsApplicable = result.isClaimsApplicable;

          this.cashedUserData = result.user;
          this.userForm.patchValue(this.cashedUserData);
          this.username.disable();
          this.password.disable();

          if (result.user.accountId != this.auth.currentUser.accountId)
            this.securityGroups.disable();

        }).catch(e => {
          //  //console.log(e);
          //  this.alert.error('_MESSAGE_ERROR_LOADING_DATA');
        }).finally(() => this.loader.load(LoaderType.Nav, false));
    } else {
      //this.isClaimsApplicable = this.auth.currentUser.isAdministrator;
      this.loader.load(LoaderType.Nav, false);
    }
  }

  onGroupSelectionChange(groups) {
    this.isClaimsGroupSelected = groups.indexOf(this.claimsSystemGroupId) > -1;
    if (!this.isClaimsGroupSelected && !this.isClaimsApplicable)
      this.claimsCountries.reset();
  }

  changePassword() {
    this.changingPassword = !this.changingPassword;
    this.changingPassword ?
      this.password.enable() :
      this.password.disable();
  }

  onSubmit() {
    console.warn(this.userForm.value);

    if (this.updateMode && !this.changingPassword) {
      this.userForm.get('pass').setErrors(null);
      this.password.setErrors(null);
      this.passwordConfirm.setErrors(null);
    }

    if (!this.userForm.valid)
      return Utils.validateAllFormFields(this.userForm);

    this.loader.load(LoaderType.Body);
    let user = this.userForm.value as UserDto;

    if (this.updateMode) {

      user.id = this.userId;
      user.password = this.password.value;

      this.usersService.updateUserData(user)
        .then(r => {
          r ?
            this.alert.success(DefaultAlertAction.UPDATING)
            : this.alert.failure(DefaultAlertAction.UPDATING);
        })
        .catch(e => {
          // //console.log(e);
          //   this.alert.error();
        })
        .finally(() => {
          this.loader.load(LoaderType.Body, false);
          this.router.navigate([this.translate.currentLang, 'security', 'users', 'list', this.auth.currentUser.accountId]);
        });
    } else {
      //new
      user.password = this.password.value;

      this.usersService.createUser(user)
        .then(result => {
          //console.log(result)
          result ?
            this.alert.success(DefaultAlertAction.ADDING)
            : this.alert.failure(DefaultAlertAction.ADDING);
        })
        .catch(e => {
          //console.log(e);
          //  this.alert.error();
        })
        .finally(() => {
          this.loader.load(LoaderType.Body, false);
          this.router.navigate([this.translate.currentLang, 'security', 'users', 'list', this.auth.currentUser.accountId]);
        });
    }
  }

  private formInit() {
    this.userForm = this.fb.group({
      names: this.fb.array([
        ['', [Validators.required, nonEnglishCharactersValidator, Validators.minLength(3), Validators.maxLength(100)]],
        ['', [Validators.required, nonArabicCharactersValidator, Validators.minLength(3), Validators.maxLength(100)]]
      ]),
      username: this.fb.control('',
        [Validators.required, usernameValidator, Validators.minLength(5), Validators.maxLength(50)],
        !this.updateMode ? this.usersService.validateUsernameNotTaken.bind(this) : null),
      phoneNumber: ['', [Validators.required, numbersOnlyValidator]],
      email: ['', [Validators.email]],
      pass: this.fb.group({
        password: ['', [Validators.required, Validators.minLength(6)]],  //todo: regex for password pattern
        passwordConfirm: ['']
      }, { validator: comparePasswords }),
      securityGroups: [''],
      claimsCountries: ['']
    });
  }
}
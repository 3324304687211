import { Component } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mac-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  constructor(public service: BreadcrumbService, public translate: TranslateService) { 
    
  }
}

import { ELicenseOrderStatus } from "./e-license";

export const convertELicenseOrderStatusToLocale = (status: ELicenseOrderStatus) => {
    switch (status) {
      case ELicenseOrderStatus.DraftDocument:
        return " طلب معلق - بإنتظار حضور العميل والدفع بالفرع";

      case ELicenseOrderStatus.OrderPlaced:
        return "قيد الإجراء - بإنتظار موافقة النظام";

      case ELicenseOrderStatus.PaymentCompleted:
        return "معاد للإستكمال - بإنتظار مدخلات العميل";

      case ELicenseOrderStatus.LicenseIssued:
        return "مقبول - تم إصدار الرخصة";

      case ELicenseOrderStatus.OrderShipped:
        return "تم شحن الطلب";

      case ELicenseOrderStatus.OrderDelivered:
        return "نم استلام الطلب من الفرع";
        case ELicenseOrderStatus.OrderPicked:
          return "نم استلام الطلب من الفرع";
      case ELicenseOrderStatus.Rejected:
      case ELicenseOrderStatus.Refunded:
        return "مرفوض - تم إعادة المبلغ لحساب العميل";

    }
  }
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, Renderer2, Inject, ChangeDetectionStrategy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatSidenavContent } from '@angular/material/sidenav';
import { SidenavService } from '../../services/sidenav.service';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'mac-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainComponent implements OnInit {
  opened: boolean = true;
  mobileView: boolean = false;
  navLoading: boolean = true;
  bodyLoading: boolean;
  styleLoading: boolean;

  @ViewChild('sidenav') sidenav;
  @ViewChild('content') content: MatSidenavContent;

  constructor(private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private elm: ElementRef,
    private sidenavService: SidenavService,
    private loader: LoadingService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    private titleService: Title,
    private breadcrumbService: BreadcrumbService,
    private auth: AuthService) {

    var ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
      this.mobileView = true;

    this.opened = !this.mobileView;
    this.translate.setDefaultLang('ar');
    this.translate.use(route.snapshot.paramMap.get('lang'));

    ////console.log('router navigated:', this.router.navigated);

    //if(!this.router.navigated)
    this.styleLoading = true;
    // //todo: fix glitch happens when requesting /en directly

    //LoadingService.style.next(true);
    // this.loader.load(null);
    // if (this.translate.currentLang == 'en') {
    //   //setTimeout(() => {
    //     this.loadStyle(this.translate.currentLang);
    //     this.loader.load(null, false);
    //   //}, 1000);
    // }
  }

  private loadStyle(lang: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const body = this.document.getElementsByTagName('body')[0];

    body.setAttribute('dir', lang == 'ar' ? 'rtl' : 'ltr');
    const styleFileName = lang == 'ar' ? 'bootstrap-rtl.css' : 'bootstrap.css';
    let themeLink = this.document.getElementById('bootstrap') as HTMLLinkElement;
    //if (themeLink) {
    themeLink.href = styleFileName;
  }

  ngAfterViewInit() {
    //this.loader.load(null, false);
    // LoadingService.style.next(false);
  }

  getAccountType() {
    const prefix = 'ACC_TITLE_ACCOUNTS_';

    if (this.auth.currentUser.accountTypeId == 1 && this.auth.currentUser.isSystemAccount)
      return prefix + "BRANCHES";

    if (this.auth.currentUser.accountTypeId == 1)
      return prefix + "AGENTS";

    if (this.auth.currentUser.accountTypeId == 2)
      return prefix + "DISTRIBUTORS";

    return prefix + "SUB_DISTRIBUTORS";
  }

  ngOnInit() {

    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          ////console.log('ev');
          let _titles = [];
          let _titlesLinks: string[][] = [];
          let child = this.route;
          let _path = '/';
          let level = 0;

          const _lang = child.snapshot.paramMap.get('lang');
          if (this.translate.currentLang != _lang)
            this.translate.use(_lang);

          if (this.router.url == `/${this.translate.currentLang}/`) {
            _titles.push(this.translate.instant(child.snapshot.firstChild.data['title']));
          } else if (this.router.url == '/login') {
            _titles.push(this.translate.instant(child.snapshot.data['title']));
          }
          else {
            while (child.firstChild) {
              child = child.firstChild;
              if (child.snapshot.data['title']) {

                let title =
                  child.snapshot.data['title'] == "ACC_TITLE_ACCOUNTS" ?
                    this.getAccountType()
                    : child.snapshot.data['title'];

                if (level <= 1)
                  _titles.push(this.translate.instant(title));

                const root = level == 0 && !child.snapshot.url[0];

                _path += root ? '' : child.snapshot.url[0] + '/';
                _titlesLinks.push([root ? '_APP_HOME' : title, _path]);
              }
              level++;
            }
          }
          this.breadcrumbService.setLinks(_titlesLinks);
          return _titles.join(' | ');
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });














    //todo: fix glitch happens when requesting /en directly
    // if (this.translate.currentLang == 'en') {
    //   //this.loader.load(null);
    //   setTimeout(() => {
    //     this.loadStyle(this.translate.currentLang);
    //     this.loader.load(null, false);
    //   }, 1000);
    // }
    ////console.log(this.translate.currentLang);
    if (this.translate.currentLang == 'en')
      this.loadStyle(this.translate.currentLang);
    setTimeout(() => {
      LoadingService.style.next(false);
    }, 500);

    this.translate.onLangChange.subscribe(t => {
      ////console.log('sub', t, this.translate.currentLang);
      //const _lang = t.lang;
      // if (this.translate.currentLang != _lang) {

      //this.loader.load(null);
      LoadingService.style.next(true);
      setTimeout(() => {
        //this.translate.use(_lang);
        this.loadStyle(this.translate.currentLang);
        //this.loader.load(null, false);
        setTimeout(() => {
          LoadingService.style.next(false);
        }, 250);

      }, 1000);
      // }
      // else {
      //   this.loader.load(null, false);
      // }
    });

    //   this.route.params.subscribe(params => {
    // const _lang = params.lang;
    // const _lang = this.route.snapshot.paramMap.get('lang');
    // this.translate.use(_lang);
    // //if (this.translate.currentLang != _lang) {
    // this.loader.load(null);
    // setTimeout(() => {
    //   this.translate.use(_lang);
    //   this.loadStyle(_lang);
    //   this.loader.load(null, false);
    // }, 1000);
    // }
    // else {
    //   this.loader.load(null, false);
    // }
    // });

    // this.loader.styleLoading.subscribe(status => {
    //   this.styleLoading = status;
    // });
    LoadingService.style.subscribe(status => {
      ////console.log('new style', status);
      this.styleLoading = status;
    });

    this.loader.navLoading.subscribe(status => {
      this.navLoading = status;
    });

    this.loader.bodyLoading.subscribe(status => {
      this.bodyLoading = status;
    });
  }

  navToggle() {
    this.sidenav.toggle();

    const elm = this.content.getElementRef().nativeElement;
    if (elm.classList.value.indexOf('mat-drawer-opened') > -1) {
      this.sidenavService.opened.next(false);
      this.renderer.removeClass(elm, 'mat-drawer-opened');
    } else {
      this.sidenavService.opened.next(true);
      this.renderer.addClass(elm, 'mat-drawer-opened');
    }
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

Injectable()
export class ValidationService {
  constructor(private translate: TranslateService) { }

  getValidatorErrorMessage(validatorName: string, validatorValue?: any, locale?: string) {
    let config = {
      required: '_MESSAGE_VALIDATION_REQUIRED',//await this.translate.get('_MESSAGE_VALIDATION_REQUIRED', {field: await this.translate.get(locale).toPromise()}).toPromise(),
      arabicOnly: '_MESSAGE_VALIDATION_ARABIC_ONLY',
      nonEnglishOnly: '_MESSAGE_VALIDATION_NON_ENGLISH_ONLY',
      nonArabicOnly: '_MESSAGE_VALIDATION_NON_ARABIC_ONLY',
      englishAndSpacesOnly: '_MESSAGE_VALIDATION_ENGLISH_AND_SPACES_ONLY',
      englishOnly: '_MESSAGE_VALIDATION_ENGLISH_ONLY',
      invalidNumbersOnly: '_MESSAGE_VALIDATION_NUMBERS_ONLY',
      invalidUsername: '_MESSAGE_VALIDATION_USERNAME_PATTERN',
      duplicatedUsername: '_MESSAGE_VALIDATION_DUPLICATED_USERNAME',
      passwordMismatch: '_MESSAGE_VALIDATION_PASSWORD_MISMATCH',
      invalidPhoneNumber: '_ERR_REGEX_PHONE_NO',
      invalidEmail: '_MESSAGE_VALIDATION_EMAIL_PATTERN',
      email: '_MESSAGE_VALIDATION_EMAIL_PATTERN',
      invalidAddress: '_ERR_REGEX_ADDRESS',
      minlength: ['_MESSAGE_VALIDATION_MIN_CHARACTERS', validatorValue.requiredLength],
      maxlength: ['_MESSAGE_VALIDATION_MAX_CHARACTERS', validatorValue.requiredLength],
      _invalidPassportIdOrIdentityId: '_ERR_OWNER_PASSPORT_OR_IDENTITY_DUPLICATE',
      invalidPassportIdOrIdentityId: '_MESSAGE_VALIDATION_PASSPORT_ID_IDENTITY_ID_PATTERN',
      invalidCreditCard: 'Is invalid credit card number',
      invalidEmailAddress: 'Invalid email address',
      invalidPassword:
        'Invalid password. Password must be at least 6 characters long, and contain a number.',
      //  minlength: `Minimum length ${validatorValue.requiredLength}`
      invalidVehicleWeightInKGRange: '_ERR_OUT_OF_RANGE_VEHICLE_WEIGHT_IN_KG',
      invalidVehicleHorsePowerRange: '_ERR_OUT_OF_RANGE_VEHICLE_HORSE_POWER',
      invalidVehicleCylindersCountRange: '_ERR_OUT_OF_RANGE_VEHICLE_CYLINDERS_COUNT',
      invalidVehicleManufactureYearRange: '_ERR_OUT_OF_RANGE_VEHICLE_MANUFACTURE_YEAR',
      invalidVehicleSeatsCountRange: '_ERR_OUT_OF_RANGE_VEHICLE_SEATS_COUNT',
      invalidVehicleSpareTiresCountRange: '_ERR_OUT_OF_RANGE_VEHICLE_SPARE_TIRES_COUNT',
      invalidVehicleValueRange: '_ERR_OUT_OF_RANGE_VEHICLE_VALUE',
      invalidTriptikName: '_ERR_REGEX_TRIPTIK_NAME'
    };

    return config[validatorName];
  }

  // static creditCardValidator(control) {
  //   // Visa, MasterCard, American Express, Diners Club, Discover, JCB
  //   if (
  //     control.value.match(
  //       /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
  //     )
  //   ) {
  //     return null;
  //   } else {
  //     return { invalidCreditCard: true };
  //   }
  // }

  // static emailValidator(control) {
  //   // RFC 2822 compliant regex
  //   if (
  //     control.value.match(
  //       /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  //     )
  //   ) {
  //     return null;
  //   } else {
  //     return { invalidEmailAddress: true };
  //   }
  // }

  // static passwordValidator(control) {
  //   // {6,100}           - Assert password is between 6 and 100 characters
  //   // (?=.*[0-9])       - Assert a string has at least one number
  //   if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
  //     return null;
  //   } else {
  //     return { invalidPassword: true };
  //   }
  // }
}
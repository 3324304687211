import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { EndPoint } from 'src/app/core/models/enums/end-point';

export class Utils {

  public static joinByDash(arr: string[]) {
    return [...arr].reverse().join(' - ');
  }

  public static validateAllFormFields(formGroup: /*AbstractControl | */FormGroup | FormArray) {
    ((formGroup instanceof FormArray) ? formGroup.controls :
      Object.keys((<FormGroup>formGroup).controls)).forEach((field, i) => {
        const control = formGroup instanceof FormArray ?
          formGroup.controls[i]
          : formGroup.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup
          || control instanceof FormArray) {
          this.validateAllFormFields(control);
        }
      });
  }


  public static _printHTML(content) {
    const html = `
    <html>
      <head>
          <style>
             @page {
                     margin: 0.00mm 0.00mm 0.00mm 0.00mm;
                     padding: 0.00mm 0.00mm 0.00mm 0.00mm;
                   }
                   body{
                     margin: 20;
                     padding: 0; 
                   }
             
          </style>
      </head>
      <body>${content}</body>
     </html>`;

    //var contents = document.getElementById("dvContents").innerHTML;
    var frame1 = document.createElement('iframe');
    frame1.name = "frame1";
    frame1.style.position = "absolute";
    frame1.style.top = "-1000000px";
    document.body.appendChild(frame1);
    var frameDoc = (frame1.contentWindow) ? frame1.contentWindow : ((<any>frame1.contentDocument).document) ? (<any>frame1.contentDocument).document : frame1.contentDocument;
    frameDoc.document.open();
    //frameDoc.document.write('<html><head><title>DIV Contents</title>');
    //frameDoc.document.write('</head><body>');
    frameDoc.document.write(html);
    //frameDoc.document.write('</body></html>');
    frameDoc.document.close();
    setTimeout(function () {
      window.frames["frame1"].focus();
      window.frames["frame1"].print();
      document.body.removeChild(frame1);
    }, 500);
    return false;
  }

  public static printHtml(content) {

    try {
      this._printHTML(content);
      // const html = `
      //              <html>
      //                <head>
      //                    <style>
      //                       @page {
      //                               margin: 0.00mm 0.00mm 0.00mm 0.00mm;
      //                               padding: 0.00mm 0.00mm 0.00mm 0.00mm;
      //                             }
      //                             body{
      //                               margin: 0;
      //                               padding: 0; 
      //                             }

      //                    </style>
      //                </head>
      //                <body onload="window.print();window.close()">${content}</body>
      //               </html>`;

      // //                     const printContent = document.createElement("div");
      // // const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
      // // WindowPrt.document.write(html);
      // // //WindowPrt.document.close();
      // // WindowPrt.focus();
      // // WindowPrt.print();
      // // //WindowPrt.close();

      // const frame1 = document.createElement('iframe');
      // frame1.name = "frame1";

      // frame1.style.position = "absolute";
      // frame1.style.top = "-1000000px";
      // document.body.appendChild(frame1);

      // //   if (window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
      // //     frame1.contentWindow.focus();
      // //     frame1.contentWindow.print();
      // //  }

      // const frameDoc = frame1.contentDocument;
      // frameDoc.write(html);
      // setTimeout(function () {
      //   window.frames["frame1"].focus();
      //   window.frames["frame1"].print();
      //   document.body.removeChild(frame1);
      // }, 1000);


      // var is_chrome = function () { return navigator.userAgent.toLowerCase().indexOf('chrome') > -1; }
      // window.onload = function () {
      //   if (is_chrome) {
      //     /*
      //      * These 2 lines are here because as usual, for other browsers,
      //      * the window is a tiny 100x100 box that the user will barely see.
      //      * On Chrome, it needs to be big enough for the dialogue to be read
      //      * (NB, it also includes a page preview).
      //     */
      //     window.moveTo(0, 0);
      //     window.resizeTo(640, 480);

      //     // This line causes the print dialogue to appear, as usual:
      //     window.print();

      //     /*
      //      * This setTimeout isn't fired until after .print() has finished
      //      * or the dialogue is closed/cancelled.
      //      * It doesn't need to be a big pause, 500ms seems OK.
      //     */
      //     setTimeout(function () {
      //       window.close();
      //     }, 500);
      //   } else {
      //     // For other browsers we can do things more briefly:
      //     window.print();
      //     window.close();
      //   }
      // }
      return false;
    }
    catch (e) {

      console.error(e);
    }
  }

  public static spreadRange(range: number[], reverse: boolean = false) {
    let start = range[0], end = range[1];
    const result: number[] = [];
    while (start <= end)
      result.push(start++);

    return !reverse ?
      result : result.reverse();
  }


  public static convertToServerDateTimeString(str) {
    const date = new Date(str);
    return new Date(date.setHours(date.getHours() + 3)).toISOString()
  }

  public static spreadRanges(ranges: number[][]) {
    const result = [];

    ranges.forEach(s => {
      if (s.length > 1) {
        let _start = s[0];
        const _end = s[1];
        while (_start <= _end)
          result.push(_start++);
      }
      else
        result.push(s[0]);
    });
    return result;
  }

  public static convertToMillimeter(px: number) {
    return px * 0.2645833333;
  }

  public static convertToPixel(mm: number) {
    return mm / 0.2645833333;
  }

  public static generateQrCodeUrl(hash: string) {
    //return `https://idl.mac.org.sa/verify-license/${hash}`;
    return `${window.location.origin}/verify/${hash}`;
  }

  public static exportToExcel(data: any, translate: TranslateService) {

    var output = data.map(function (obj) {
      return Object.keys(obj).map(function (key) {
        return obj[key];
      });
    });

    var wscols = [
      { width: 10 },  // first column
      { width: 10 },
      { width: 12.5 },
      { width: 30 },
      { width: 30 },
      { width: 12.5 },
      { width: 10 },
      { width: 10 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 10 },
      { width: 15 },
      { width: 10 },
      { width: 12.5 },
      { width: 12.5 },
      { width: 10 }
    ];

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(output);
    ws["!cols"] = wscols;
    ws["!autofilter"] = { ref: 'A1:R1' };

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    if (!wb.Workbook) wb.Workbook = {};
    if (!wb.Workbook.Views) wb.Workbook.Views = [];
    if (!wb.Workbook.Views[0]) wb.Workbook.Views[0] = {};
    wb.Workbook.Views[0].RTL = true;

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `Licenses_Export_${moment(new Date()).format("L")}.xlsx`);
  }
}
import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { Language } from 'src/app/core/models/enums/language';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { LookupVm, LookupDto } from '../models/lookup';

@Injectable()
export class VehicleTypesService {
    constructor(private api: ApiHandler) { }

    get(lang: Language = null) {
        return this.api.get<LookupVm>(EndPoint.VEHICLE_TYPES, null, { lang }).toPromise();
    }

    create(dto: LookupDto) {
        return this.api.create<LookupDto, number>(EndPoint.VEHICLE_TYPES, dto).toPromise();
    }

    update(dto: LookupDto) {   
        return this.api.update(EndPoint.VEHICLE_TYPES, dto).toPromise();
    }

    delete(id: number) {                     
        return this.api.delete(EndPoint.VEHICLE_TYPES, id).toPromise();
    }
}
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from '../components/alert/alert.component';
import { isArray } from 'util';

@Injectable()
export class AlertService {

  constructor(private _snackBar: MatSnackBar, private translate: TranslateService) { }

  success(message: string, part: boolean = true) {
    const param = this.translate.instant(message);

    const _message = part ? this.translate.instant('_MESSAGE_ALERT_SUCCESS', { param: param }) : param;
    this._snackBar.open(_message, null, {
      duration: 5000,
      panelClass: 'success',
      verticalPosition: 'top'
    });
  }

  failure(message: string, part: boolean = true) {
    const param = this.translate.instant(message);
    const _message = part ? this.translate.instant('_MESSAGE_ALERT_FAILURE', { param: param }) : param;
    this._snackBar.open(_message, null, {
      duration: 5000,
      panelClass: 'error',
      verticalPosition: 'top'
    });
  }

  _error(messages: string []) {
    //const _message = this.translate.instant(message ? message : '_MESSAGE_ALERT_ERROR');

    const duration = messages.length * 5000;

    // let content = '';
    // let duration = 5000;
    // if (!isArray(message) && message.length > 1) {
    //   content += '<ul>';

    //   // <mat-list-item role="listitem">Item 1</mat-list-item>
    //   // <mat-list-item role="listitem">Item 2</mat-list-item>
    //   // <mat-list-item role="listitem">Item 3</mat-list-item>

    //   for (let i = 0; i < message.length; i++) {
    //     content += `<li>${message[i]}</li>`;
    //     duration = 5000 * (i + 1);
    //   }
    //   content += '</ul>';
    // } else {
    //   content = `<span>${message}</span>`;
    // }

    // //console.log(content);
    this._snackBar.openFromComponent(AlertComponent, {
      panelClass: 'mat-snack-bar-failure',
      verticalPosition: 'top',
      data: messages,

      duration: duration
    });

    // this._snackBar.open(_message, null, {
    //   duration: 5000,
    //   panelClass: 'mat-snack-bar-failure',
    //   verticalPosition: 'top'
    // });

  }

  error(message: string | any[] = null) {
    const _message = this.translate.instant(message ? message : '_MESSAGE_ALERT_ERROR');

    // if (!isArray(message))
    //   message = [message];

    if (typeof message == 'string')
      message = [message];
      
    const duration = (message.length + 1) * 5000;

    // let content = '';
    // let duration = 5000;
    // if (!isArray(message) && message.length > 1) {
    //   content += '<ul>';

    //   // <mat-list-item role="listitem">Item 1</mat-list-item>
    //   // <mat-list-item role="listitem">Item 2</mat-list-item>
    //   // <mat-list-item role="listitem">Item 3</mat-list-item>

    //   for (let i = 0; i < message.length; i++) {
    //     content += `<li>${message[i]}</li>`;
    //     duration = 5000 * (i + 1);
    //   }
    //   content += '</ul>';
    // } else {
    //   content = `<span>${message}</span>`;
    // }

    // //console.log(content);
    this._snackBar.openFromComponent(AlertComponent, {
      panelClass: 'mat-snack-bar-failure',
      verticalPosition: 'top',
      data: message,

      duration: duration
    });

    // this._snackBar.open(_message, null, {
    //   duration: 5000,
    //   panelClass: 'mat-snack-bar-failure',
    //   verticalPosition: 'top'
    // });

  }
}

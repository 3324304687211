export enum EndPoint {
  //__BASE_URL = 'https://localhost:44349',
  //__BASE_URL = "https://api-sys.mac.org.sa",
  //  __BASE_URL = "https://api-stg.mac.org.sa",
  AUTH = "security/auth",
  FORGOT_PASSWORD = "security/forgot-password",
  RESET_PASSWORD = "security/reset-password",
  CONFIG = "config",
  UPLOADS = "uploads",
  ASYNC_VALIDATION = "validation/validate",
  STATISTICS_ISSUINGS_PER_MONTH = "statistics/issuings-per-month",
  COUNTRIES = "lookups/countries",
  CITIES = "lookups/cities",
  VEHICLE_COLORS = "lookups/vehicle-colors",
  VEHICLE_MAKES = "lookups/vehicle-makes",
  VEHICLE_MODELS = "lookups/vehicle-models",
  VEHICLE_TYPES = "lookups/vehicle-types",
  ACCOUNT_TYPES = "lookups/account-types",
  CURRENCIES = "lookups/currencies",
  CUSTOMS_PORTS = "lookups/customs-ports",
  TRIPTIK_CLAIMS_PURSUING_ZONES = "lookups/pursuing-zones",
  TRIPTIK_CLAIMS_STATUSES = "lookups/trip-tik-claims-statuses",
  TRIPTIK_CLAIMS_STATUSES_BASE = "lookups/trip-tik-claims-statuses/base",
  ACCOUNT_TYPES_ALLOWED = "lookups/account-types/allowed",
  ACCOUNT_STATUSES = "lookups/account-statuses",
  STOCK_TYPES = "lookups/stock-types",
  STOCK_TYPES_ALLOWED = "lookups/stock-types/allowed",
  STOCK_TYPES_URO = "lookups/stock-types/uro",
  ACCOUNTS = "accounts",
  ACCOUNTS_BY_ACCOUNT_TYPE = "accounts/by-type",
  ACCOUNTS_CHILDREN = "accounts/children",
  ACCOUNTS_CHILDREN_LOOKUP = "accounts/children/lookup",
  SECURITY_GROUPS = "security/groups",
  SECURITY_GROUPS_LOOKUP = "security/groups/lookup",
  SECURITY_ROLES = "security/roles",
  SECURITY_USERS = "security/users",
  SECURITY_USERS_GROUPS = "security/users/groups",
  SECURITY_USER = "security/user",
  SECURITY_USER_META = "security/user/meta",
  SECURITY_USER_PROFILE = "security/user/profile",
  SECURITY_CLAIMS_APPLICABLE = "security/claims-applicable",
  STOCKS_TRANSACTIONS = "stocks/transactions",
  STOCKS_TRANSACTIONS_SEARCH = "stocks/transactions/search",
  STOCKS_TOTALS = "stocks/totals",
  STOCKS_UNDO_REQUESTS = "stocks/undo/requests",
  STOCKS_AVAILABLE = "stocks/available",
  STOCKS_SYSTEM_AVAILABLE = "stocks/system-available",
  STOCKS_TRANSACT = "stocks/transact",
  STOCKS_POPULATE = "stocks/populate",
  STOCKS_DEPOPULATE = "stocks/depopulate",
  STOCKS_UNDO_TRY = "stocks/undo/try",
  STOCKS_UNDO_CANCEL = "stocks/undo/cancel",
  STOCKS_UNDO_RESOLVE = "stocks/undo/resolve",
  ISSUING_VERIFY = "issuing/verify",
  ISSUING_REVOKE_REQUESTS = "issuing/revoke/requests",
  ISSUING_REVOKE_TRY = "issuing/revoke/try",
  ISSUING_REVOKE_CANCEL = "issuing/revoke/cancel",
  ISSUING_REVOKE_RESOLVE = "issuing/revoke/resolve",
  ISSUING_CREATE_UPS_SHIPMENT = "issuing/ups/create",
  // ISSUING_EXPIRED_DOCUMENTS_QUERY = "issuing/expired",
  ISSUING_ISSUED_DOCUMENTS_QUERY = "issuing/query",
  ISSUING_DOCUMENT_CANCEL = "issuing/cancel",
  INT_lICENSE = "int-licenses",
  INT_lICENSE_DEATILS = "int-licenses/details",
  E_lICENSE = "e-licenses",
  E_lICENSE_ORDER_STATUS = "e-licenses/order/status",
  INT_lICENSE_GET_BY_HASHED_SERIAL = "int-licenses/hash",
  INT_lICENSE_PRINT = "int-licenses/print",
  INT_lICENSE_SEARCH = "int-licenses/search",
  TRIPTIK = "trip-tiks",
  TRIPTIK_GET_BY_HASHED_SERIAL = "trip-tiks/hash",
  TRIPTIK_SEARCH = "trip-tiks/search",
  TRIPTIK_PRINT = "trip-tiks/print",
  TRIPTIK_CLAIMS = "trip-tiks/claims",
  TRIPTIK_CLAIMS_SEARCH = "trip-tiks/claims/search",
  TRIPTIK_CLAIMS_LOGS = "trip-tiks/claims/logs",
  TRIPTIK_CLAIMS_LOGS_SEARCH = "trip-tiks/claims/logs/search",
  TRIPTIK_CLAIMS_ATTACHMENTS = "trip-tiks/claims/attachments",
  E_lICENSE_COUPON = "e-licenses/coupon",
  E_lICENSE_COUPON_CREATE = "e-licenses/coupon/create",
  E_lICENSE_COUPON_UPDATE = "e-licenses/coupon/update",
  E_lICENSE_VIEW_COUPON_DETAILS = "e-licenses/couponlicense",
  // VALIDATION_USERS_USERNAME = '',
  // VALIDATION_ACCOUNTS_ACCOUNT_TITLE = ''
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
import * as _ from 'lodash';
import { TripTikVm, TripTikDetailsDto } from '../../models/trip-tik';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { Language } from 'src/app/core/models/enums/language';
import { FileLoaderService } from 'src/app/shared/services/file-loader.service';
import { TripTiksService } from '../../services/trip-tiks.service';
import { PrintObject } from 'src/app/core/models/enums/print-object';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { UpdateModal } from 'src/app/shared/models/modal';
import { Role } from 'src/app/core/models/enums/role';
import { AuthService } from 'src/app/core/auth/auth.service';
import { TripTikPrintComponent } from '../../components/trip-tik-print/trip-tik-print.component';
import { ConfigObject } from 'src/app/core/models/enums/config-object';
import { ReceiptPrintComponent } from '../../components/receipt-print/receipt-print.component';
import { StaticConfig } from 'src/app/features/lookups/services/config';
import { Utils } from 'src/app/shared/services/utils';
import { PrintLogDto } from '../../../_shared/models/issuing.models';
import { StringJoinPipe } from 'src/app/shared/pipes/string-join.pipe';
import { LookupDto } from 'src/app/features/lookups/models/lookup';

@Component({
  selector: 'mac-view-trip-tik',
  templateUrl: './view-trip-tik.component.html',
  styleUrls: ['./view-trip-tik.component.scss'],
  providers: [TripTiksService, FileLoaderService]
})
export class ViewTripTikComponent implements OnInit {
  cashedVm: TripTikVm;
  dto: TripTikDetailsDto = new TripTikDetailsDto();
  moment = moment;
  lang: Language;
  dateFormat = 'L';
  qRCodeImg: string = 'assets/images/qr.png';
  qRCodeUrl: string = '';
  maxPrintLimit: number;
  empty: string;
  drivers = [];
  vUpholsteryType: string[];
  vOptions: LookupDto[] = [];
  attachments: { title: string, parent: string, src: string }[] = [];
  confirmed: boolean;
  defaultImage = 'assets/images/default.jpg';

  @ViewChild('printElm') printElm: TripTikPrintComponent;
  @ViewChild('receiptPrintElm') receiptPrintElm: ReceiptPrintComponent;

  get PrintObject() { return PrintObject; };
  get Role() { return Role; };
  get Language() { return Language; };
  get ConfigObject() { return ConfigObject; };
  get Math() { return Math; };

  constructor(private route: ActivatedRoute,
    private tripTiksService: TripTiksService,
    private loader: LoadingService,
    public translate: TranslateService,
    private fileLoader: FileLoaderService,
    private confirmService: ConfirmService,
    private stringJoin: StringJoinPipe,
    public auth: AuthService,
    private router: Router) { }

  async ngOnInit() {
    moment.locale('en-gb');
    document.querySelector('mat-card').classList.remove('overflow-hidden');
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.loader.load(LoaderType.Nav);
    this.maxPrintLimit = Number(StaticConfig.getConfigKeyValuePairs(ConfigObject.TripTikMaxPrintLimit)[0].value);
    this.empty = '';//StaticConfig.getConfigKeyValuePairs(ConfigObject.TripTikPrintEmptyValue)[0].value.toString();

    const tripTikId = this.route.snapshot.paramMap.get('id');
    if (tripTikId) {
      this.tripTiksService.get(tripTikId)
        .then(async result => {
          //console.log(result);
          if (result.item) {
            this.dto = result.item;
            this.drivers = this.dto.customer.drivers.map(d => d.name);

            if (this.dto.vehicle.upholsteryType)
              this.vUpholsteryType = <[]>StaticConfig.getConfigValue(ConfigObject.VehicleUpholsteryTypes, this.dto.vehicle.upholsteryType);

            if (this.dto.vehicle.options) {
              this.vOptions = [];
              this.dto.vehicle.options.forEach(opt => {
                this.vOptions.push({ id: opt, titles: <[]>StaticConfig.getConfigValue(ConfigObject.VehicleOptions, opt/*, this.lang*/) });
              });
            }

            //console.log(this.vUpholsteryType,  this.vOptions);

            //console.log(this.dto);
            this.fetchAttachments();

            if (this.dto.issued && this.dto.hashedSerial) {
              this.qRCodeUrl = Utils.generateQrCodeUrl(this.dto.hashedSerial);
              setTimeout(() => {
                this.qRCodeImg = document.getElementsByTagName('ngx-kjua')[0]
                  .getElementsByTagName('div')[0]
                  //.getElementsByTagName('img')[0].src;
                  .getElementsByTagName('canvas')[0].toDataURL("image/png");

                //console.log(this.qRCodeImg);
              });

              //console.log(this.qRCodeUrl);
            }

            if (this.isPrintable())
              this.displayPreview();
          } else this.router.navigate([`/${this.translate.currentLang}/not-found`]);
        })
        .finally(() => this.loader.load(LoaderType.Nav, false));
    }
  }

  isPrintable() {
    return !this.dto.isMigratedData && (!this.dto.issued
      || this.printObjectsCount(this.dto.printLogs, PrintObject.TripTikFrontCover) < this.maxPrintLimit
      || this.printObjectsCount(this.dto.printLogs, PrintObject.TripTikBackCover) < this.maxPrintLimit
      || this.printObjectsCount(this.dto.printLogs, PrintObject.TripTikInnerPages) < this.maxPrintLimit)
  }

  isExpired(expiryDate) {
    return moment(expiryDate).isBefore(Date.now());
  }

  printObjectsCount(list: PrintLogDto[], printObject: PrintObject) {
    if (list)
      return list.filter(l => l.printObject == printObject).length;
  }

  print(printObject: PrintObject) {
    if (printObject == PrintObject.TriptikReceipt) {

      this.receiptPrintElm.print(this.dto);
      return;
    }

    if (this.dto.accountId != this.auth.currentUser.accountId
      || !this.auth.currentUser.isInRole(Role.TRIPTIKS_UPDATE)
      || this.dto.serial < 0)
      return;

    //confirm if not yet issued
    if (!this.dto.issued) {
      let modal = this.confirmService.confirm(new UpdateModal({
        title: this.dto.serial.toString(),
        type: 'TRP_TITLE_TRIPTIK',
        action: '_ACTION_ISSUE',
        confirmQuestion: 'TRP_CONFIRM_ISSUE_CONFIRMATION_QUESTION',
        note: 'TRP_CONFIRM_ISSUE_NOTE',
        dangerNote: 'TRP_CONFIRM_ISSUE_DANGER_NOTE',
        submitText: '_ACTION_ISSUE'
      }));

      modal.then(async m => {
        //console.log(m);
        if (m.indexOf('CONFIRMED') > -1)
          await this._print(printObject);
      });
    } else this._print(printObject);
  }

  private fetchAttachments() {
    this.dto.customer.owner.attachments.forEach((att, i) => {
      const _title = i == 0 ? 'TRP_FIELD_PASSPORT_COPY'
        : i == 1 ? 'TRP_FIELD_IDENTITY_COPY' : 'TRP_FIELD_VREGISTRATION_COPY';
      this.attachments.push({ title: _title, parent: 'TRP_LABEL_OWNER', src: att });
    });

    this.dto.customer.drivers.forEach((driver, i) => {
      driver.attachments.forEach((att, _i) => {
        const _title = _i == 0 ? 'TRP_FIELD_PASSPORT_COPY' : 'TRP_FIELD_DRIVING_LICENSE_COPY';
        this.attachments.push({ title: _title, parent: `${this.translate.instant('TRP_LABEL_ADDITIONAL_DRIVER')} ${i + 1}`, src: att });
      });
    });

    this.attachments.forEach(async att => {
      const file = await this.fileLoader.loadAsync(att.src);
      att.src = file ? file as string : this.defaultImage;
    });
  }


  async _print(printObject: PrintObject) {
    if (this.printObjectsCount(this.dto.printLogs, printObject) < this.maxPrintLimit) {
      this.loader.load(LoaderType.Body);
      //request to server print
      this.tripTiksService.print({ id: this.dto.id, printObject })
        .then(async (hash) => {
          ////console.log(hash);

          //just issued
          if (hash && hash.value) {
            this.dto.hashedSerial = hash.value
            //this.qRCodeUrl = `${this._baseUrl}/verify-license/${this.dto.hashedSerial}`;
            this.qRCodeUrl = Utils.generateQrCodeUrl(this.dto.hashedSerial);
            setTimeout(() => {
              this.qRCodeImg = document.getElementsByTagName('ngx-kjua')[0]
                .getElementsByTagName('div')[0]
                //.getElementsByTagName('img')[0].src;
                .getElementsByTagName('canvas')[0].toDataURL("image/png");
            });

            this.dto.issued = new Date();
            this.dto.expiryDate = moment(this.dto.issued).add(1, 'year').subtract(1, 'day').toDate();
          }

          //increment print objects
          this.dto.printLogs.push({ created: new Date(), printObject: printObject });

          //update ui
          await this.displayPreview();

          //open printing object
          await this.printElm.print(this.dto, printObject);
        })
        .finally(() => this.loader.load(LoaderType.Body, false));
    }
  }

  //get stringJoin.transform() { return Utils.stringJoin.transform; };

  private async displayPreview() {
    const _expiryDate = this.dto.issued ? moment(this.dto.expiryDate).format(this.dateFormat)
      : moment(new Date()).add(1, 'year').subtract(1, 'day').format(this.dateFormat);

    const cOwnerInfo = [this.dto.customer.owner.name, this.dto.customer.owner.address.item1, this.dto.customer.owner.address.item2]
      .filter(d => d);

    //front-cover
    let fcCanvas = document.getElementById('trip-fc-canvas') as HTMLCanvasElement;
    if (fcCanvas.getContext) {
      let ctx = fcCanvas.getContext('2d');

      const fcImage = new Image();

      fcImage.onload = () => {
        ctx.textAlign = 'end';
        ctx.font = `bold ${Utils.convertToPixel(4)}px arial`;
        ctx.drawImage(fcImage, 0, 0);

        let x = Utils.convertToPixel(100), y = Utils.convertToPixel(26);
        const rh = Utils.convertToPixel(6);

        ctx.fillText(this.dto.customer.owner.name ? this.dto.customer.owner.name : 'N/A', x, y);
        // ctx.fillText(this.dto.customer.owner.address.item1 ? this.dto.customer.owner.address.item1 : 'N/A', x, y += rh);
        // ctx.fillText(this.dto.customer.owner.address.item2 ? this.dto.customer.owner.address.item2 : 'N/A', x, y += rh);
        if (this.dto.customer.drivers && this.dto.customer.drivers.length > 0) {
          this.dto.customer.drivers.forEach(driver => {
            ctx.fillText(`سائق إضافي: ${driver.name}`, x, y += rh);
          });
        }


        //todo: check second line margin, even with align-right

        ctx.textAlign = 'center';
        ctx.fillText('12 Months', Utils.convertToPixel(160), Utils.convertToPixel(18));
        ctx.fillText(_expiryDate, Utils.convertToPixel(160), Utils.convertToPixel(26));
        ctx.fillText(this.dto.vehicle.registrationCountryTitles ? this.stringJoin.transform(this.dto.vehicle.registrationCountryTitles) : 'N/A', Utils.convertToPixel(70), Utils.convertToPixel(112));
        ctx.fillText(this.dto.vehicle.plateID ? this.dto.vehicle.plateID : 'N/A', Utils.convertToPixel(170), Utils.convertToPixel(112));

        const qr = new Image();
        qr.onload = () => {
          ctx.drawImage(qr, Utils.convertToPixel(8), Utils.convertToPixel(66), 88, 88);
        }
        qr.src = this.dto.issued ? this.qRCodeImg : 'assets/images/qr.png';

        const ptt = new Image();
        ptt.onload = () => {
          ctx.save();
          ctx.globalAlpha = 0.15;
          const ptrn = ctx.createPattern(ptt, 'repeat');
          ctx.fillStyle = ptrn;
          ctx.fillRect(0, 0, Utils.convertToPixel(210), Utils.convertToPixel(297));
          ctx.restore();

          //draw thumb
          let fcCanvasThumb = document.getElementById('trip-fc-canvas-thumb') as HTMLCanvasElement;
          if (fcCanvasThumb.getContext) {
            let bcThumbCtx = fcCanvasThumb.getContext('2d');
            bcThumbCtx.drawImage(ctx.canvas, 0, 0);
          }
        }
        ptt.src = 'assets/images/sample-stamp.png';
      }
      fcImage.src = 'assets/images/trip-front-cover-preview.jpg';
    }

    //inner-page
    let ipCanvas = document.getElementById('trip-ip-canvas') as HTMLCanvasElement;
    if (ipCanvas.getContext) {
      let ctx = ipCanvas.getContext('2d');

      const ipImage = new Image();

      ipImage.onload = () => {
        ctx.textAlign = 'center';
        ctx.font = `bold ${Utils.convertToPixel(4)}px arial`;
        ctx.drawImage(ipImage, 0, 0);

        let x = Utils.convertToPixel(65), y = Utils.convertToPixel(26);
        const rh = Utils.convertToPixel(5.5);

        ctx.fillText(this.dto.vehicle.registrationCountryTitles ? this.stringJoin.transform(this.dto.vehicle.registrationCountryTitles) : 'N/A', x, y);


        // ctx.fillText(this.dto.vehicle.make ? this.stringJoin.transform(this.dto.vehicle.make.titles) : 'N/A', x, y += rh);


        if (this.dto.vehicle.model) {
          let makeModel: string[][] = [];
          makeModel[0] = [this.dto.vehicle.model.titles[0], this.dto.vehicle.make.titles[0]];
          makeModel[1] = [this.dto.vehicle.model.titles[1], this.dto.vehicle.make.titles[1]];

          ctx.fillText(this.dto.vehicle.make ? this.stringJoin.transform(makeModel.map(d => this.stringJoin.transform(d)), '/') : 'N/A', x, y += rh);
        }
        else
          ctx.fillText(this.dto.vehicle.make ? this.stringJoin.transform(this.dto.vehicle.make.titles) : 'N/A', x, y += rh);



        ctx.fillText(this.dto.vehicle.manufactureYear ? this.dto.vehicle.manufactureYear.toString() : 'N/A', x, y += rh);
        ctx.fillText(this.dto.vehicle.chassisID ? this.dto.vehicle.chassisID : 'N/A', x, y += rh);
        ctx.fillText(this.dto.vehicle.engineID ? this.dto.vehicle.engineID : this.empty, x, y += rh);
        ctx.fillText(this.dto.vehicle.cylindersCount ? this.dto.vehicle.cylindersCount.toString() : 'N/A', x, y += rh);
        //ctx.fillText(this.dto.vehicle.color ? this.stringJoin.transform(this.dto.vehicle.color.titles) : 'N/A', x, y += rh);
        ctx.fillText(this.dto.vehicle.colors ? this.stringJoin.transform(this.dto.vehicle.colors.map(d => this.stringJoin.transform(d.titles)), '+') : 'N/A', x, y += rh);

        ctx.fillText(this.dto.vehicle.type ? this.stringJoin.transform(this.dto.vehicle.type.titles) : this.empty, x, y += rh);
        ctx.fillText(this.dto.vehicle.horsePower ? this.dto.vehicle.horsePower.toString() : this.empty, x, y += rh);

        x = Utils.convertToPixel(160);
        y = Utils.convertToPixel(26);

        ctx.fillText(this.dto.vehicle.plateID ? this.dto.vehicle.plateID : 'N/A', x, y);
        ctx.fillText(this.dto.vehicle.seatsCount ? this.dto.vehicle.seatsCount.toString() : 'N/A', x, y += rh);
        ctx.fillText(this.dto.vehicle.spareTiresCount.toString(), x, y += rh);
        //ctx.fillText(this.vOptions ? this.vOptions.join(' + ') : this.empty, x, y += rh);
        ctx.fillText(this.vOptions ? this.stringJoin.transform(this.vOptions.map(d => this.stringJoin.transform(d.titles)), '+') : this.empty, x, y += rh);

        ctx.fillText(this.vUpholsteryType ? this.stringJoin.transform(this.vUpholsteryType) : this.empty, x, y += rh);
        ctx.fillText(this.dto.vehicle.equipments ? this.dto.vehicle.equipments : this.empty, x, y += rh);
        ctx.fillText(this.dto.vehicle.extras ? this.dto.vehicle.extras : this.empty, x, y += rh);
        ctx.fillText(this.dto.vehicle.weightInKG ? this.dto.vehicle.weightInKG.toString() : 'N/A', x, y += rh);
        ctx.fillText(`${this.dto.vehicle.currency ? this.dto.vehicle.currency.abbreviation : ''} ${this.dto.vehicle.value ? this.dto.vehicle.value.toString() : 'N/A'}`, x, y += rh); //todo: include currency

        ctx.fillText(cOwnerInfo.join(', '), Utils.convertToPixel(65), Utils.convertToPixel(15));
        ctx.fillText(this.drivers.join(', '), Utils.convertToPixel(160), Utils.convertToPixel(15));

        ctx.textAlign = 'left';
        ctx.font = `bold ${Utils.convertToPixel(6)}px consolas`;
        ctx.fillText(Math.abs(this.dto.serial).toString(), Utils.convertToPixel(138), Utils.convertToPixel(81.5));
        ctx.font = `bold ${Utils.convertToPixel(3)}px arial`;
        ctx.fillText(_expiryDate, Utils.convertToPixel(180), Utils.convertToPixel(83));

        const ptt = new Image();
        ptt.onload = () => {
          ctx.save();
          ctx.globalAlpha = 0.15;
          const ptrn = ctx.createPattern(ptt, 'repeat');
          ctx.fillStyle = ptrn;
          ctx.fillRect(0, 0, Utils.convertToPixel(210), Utils.convertToPixel(297));
          ctx.restore();

          //draw thumb
          let bcCanvasThumb = document.getElementById('trip-ip-canvas-thumb') as HTMLCanvasElement;
          if (bcCanvasThumb.getContext) {
            let bcThumbCtx = bcCanvasThumb.getContext('2d');
            bcThumbCtx.drawImage(ctx.canvas, 0, 0);
          }
          //thumbCtx.scale(0.2, 0.2);
        }
        ptt.src = 'assets/images/sample-stamp.png';
      }
      ipImage.src = "assets/images/trip-inner-page-preview.jpg";
    }

    //back-cover
    let bcCanvas = document.getElementById('trip-bc-canvas') as HTMLCanvasElement;
    if (bcCanvas.getContext) {
      let ctx = bcCanvas.getContext('2d');

      const bcImage = new Image();

      bcImage.onload = () => {
        ctx.textAlign = 'center';
        ctx.font = `bold ${Utils.convertToPixel(4)}px arial`;
        ctx.drawImage(bcImage, 0, 0);

        let x = Utils.convertToPixel(55), y = Utils.convertToPixel(37);
        const rh = Utils.convertToPixel(6.85);



        ctx.fillText(this.dto.vehicle.registrationCountryTitles ? this.stringJoin.transform(this.dto.vehicle.registrationCountryTitles) : 'N/A', x, y);

        if (this.dto.vehicle.model) {
          let makeModel: string[][] = [];
          makeModel[0] = [this.dto.vehicle.model.titles[0], this.dto.vehicle.make.titles[0]];
          makeModel[1] = [this.dto.vehicle.model.titles[1], this.dto.vehicle.make.titles[1]];

          ctx.fillText(this.dto.vehicle.make ? this.stringJoin.transform(makeModel.map(d => this.stringJoin.transform(d)), '/') : 'N/A', x, y += rh);
        }
        else
          ctx.fillText(this.dto.vehicle.make ? this.stringJoin.transform(this.dto.vehicle.make.titles) : 'N/A', x, y += rh);



        ctx.fillText(this.dto.vehicle.manufactureYear ? this.dto.vehicle.manufactureYear.toString() : 'N/A', x, y += rh);
        ctx.fillText(this.dto.vehicle.chassisID ? this.dto.vehicle.chassisID : 'N/A', x, y += rh);
        ctx.fillText(this.dto.vehicle.engineID ? this.dto.vehicle.engineID : this.empty, x, y += rh);
        ctx.fillText(this.dto.vehicle.cylindersCount ? this.dto.vehicle.cylindersCount.toString() : 'N/A', x, y += rh);
        // ctx.fillText(this.dto.vehicle.color ? this.stringJoin.transform(this.dto.vehicle.color.titles) : 'N/A', x, y += rh);
        ctx.fillText(this.dto.vehicle.colors ? this.stringJoin.transform(this.dto.vehicle.colors.map(d => this.stringJoin.transform(d.titles)), '+') : 'N/A', x, y += rh);
        ctx.fillText(this.dto.vehicle.type ? this.stringJoin.transform(this.dto.vehicle.type.titles) : this.empty, x, y += rh);
        ctx.fillText(this.dto.vehicle.horsePower ? this.dto.vehicle.horsePower.toString() : this.empty, x, y += rh);

        x = Utils.convertToPixel(160);
        y = Utils.convertToPixel(37);

        ctx.fillText(this.dto.vehicle.plateID ? this.dto.vehicle.plateID : 'N/A', x, y);
        ctx.fillText(this.dto.vehicle.seatsCount ? this.dto.vehicle.seatsCount.toString() : 'N/A', x, y += rh);
        ctx.fillText(this.dto.vehicle.spareTiresCount.toString(), x, y += rh);
        //ctx.fillText(this.vOptions ? this.vOptions.join(' + ') : this.empty, x, y += rh);
        //ctx.fillText(this.vOptions ? this.stringJoin.transform(this.vOptions.map(d => d.titles.toString()), '+') : this.empty, x, y += rh);
        ctx.fillText(this.vOptions ? this.stringJoin.transform(this.vOptions.map(d => this.stringJoin.transform(d.titles)), '+') : this.empty, x, y += rh);
        ctx.fillText(this.vUpholsteryType ? this.stringJoin.transform(this.vUpholsteryType) : this.empty, x, y += rh);

        ctx.fillText(this.dto.vehicle.equipments ? this.dto.vehicle.equipments : this.empty, x, y += rh);
        ctx.fillText(this.dto.vehicle.extras ? this.dto.vehicle.extras : this.empty, x, y += rh);
        ctx.fillText(this.dto.vehicle.weightInKG ? this.dto.vehicle.weightInKG.toString() : 'N/A', x, y += rh);
        ctx.fillText(`${this.dto.vehicle.currency ? this.dto.vehicle.currency.abbreviation : ''} ${this.dto.vehicle.value ? this.dto.vehicle.value.toString() : 'N/A'}`, x, y += rh);

        const ptt = new Image();
        ptt.onload = () => {
          ctx.save();
          ctx.globalAlpha = 0.15;
          const ptrn = ctx.createPattern(ptt, 'repeat');
          ctx.fillStyle = ptrn;
          ctx.fillRect(0, 0, Utils.convertToPixel(210), Utils.convertToPixel(297));
          ctx.restore();

          //draw thumb
          let bcCanvasThumb = document.getElementById('trip-bc-canvas-thumb') as HTMLCanvasElement;
          if (bcCanvasThumb.getContext) {
            let bcThumbCtx = bcCanvasThumb.getContext('2d');
            bcThumbCtx.drawImage(ctx.canvas, 0, 0);
          }
        }
        ptt.src = 'assets/images/sample-stamp.png';
      }
      bcImage.src = "assets/images/trip-back-cover-preview.jpg";
    }
  }
}

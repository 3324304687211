import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { ChartDataSets, ChartOptions, ChartLayoutOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { StatisticsService } from '../../services/statistics.service';

@Component({
  selector: 'mac-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [StatisticsService]
})
export class DashboardComponent implements OnInit {

  lineChartData: ChartDataSets[] = [{ data: [] }];
  // [
  //   { data: [133, 59, 280, 81, 56, 55, 40, 59, 280, 81, 120, 552], label: 'رخصة دولية' },
  //   { data: [59, 280, 81, 56, 55, 40, 59, 280, 81, 120, 552, 133], label: 'دفتر تربتيك يمني' },
  //   { data: [280, 81, 56, 55, 40, 59, 280, 81, 120, 552, 133, 59], label: 'دفتر تربتيك دولي' },

  // ];
  lineChartLabels: Label[] = []; //['July-19', 'August-19', 'September-19', 'October-19', 'November-19', 'December-19', 'January 20', 'February-20', 'March-20', 'April-20', 'May-20', 'June-20'];
  lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    annotation: null
  };
  lineChartColors: Color[] = [
    {
      borderColor: '#038fde',
      backgroundColor: '#038fdebf'
    },
    {
      borderColor: '#dc3545'
    },
    {
      borderColor: '#28a745'
    },
  ];
  lineChartLegend = true;
  lineChartType: ChartType = 'bar';
  lineChartPlugins = [];

  constructor(private loader: LoadingService,
    private statisticsService: StatisticsService) { }

  ngOnInit() {
    this.loader.load(LoaderType.Nav, false);
    // //this.loader.load(LoaderType.Body);
    // this.statisticsService
    //   .getIssuingsPerMonth()
    //   .then(result => {
    //     this.lineChartLabels = result.lineChartLabels.map(d => d.monthLabel);
    //     this.lineChartData = result.dataSets.map(d => { return { data: d.issuings, label: d.stockType.titles[0] } });
    //   })
    //   .finally(() => {
    //     //setTimeout(() => {
    //     this.loader.load(LoaderType.Nav, false);
    //     //document.getElementsByTagName('mac-dashboard')[0].parentElement.parentElement.style.display = 'none';
    //     //}, 1000);
    //   });
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      document.getElementsByTagName('mac-dashboard')[0].parentElement.parentElement.setAttribute('hidden', 'hidden');
    });
  }
}

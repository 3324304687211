import { Component, OnInit, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StockSliderComponent } from './../../components/stock-slider/stock-slider.component';
import { AvailableStock, AvailableStocksVm } from './../../models/available-stocks';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { AuthService } from 'src/app/core/auth/auth.service';
import { StocksService } from '../../services/stocks.service';
import { PopulateCmd } from '../../models/populate.cmd';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { DeleteModal, DisplayModal } from 'src/app/shared/models/modal';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { Language } from 'src/app/core/models/enums/language';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Utils } from 'src/app/shared/services/utils';
import { _DocumentType } from 'src/app/core/models/enums/document-type';
import { TransactionLabelDetails } from '../../models/transaction-label-details';
import { numbersOnlyValidator } from 'src/app/shared/services/custom-validators';

@Component({
  selector: 'mac-populate',
  templateUrl: './populate.component.html',
  styleUrls: ['./populate.component.scss'],
  providers: [StockTypesService, StocksService]
})
export class PopulateComponent implements OnInit {
  populate: boolean;
  selectedStock: number[];
  availableStock: AvailableStock;
  stockTypes: StockTypeDto[];
  documentType: _DocumentType;
  form: FormGroup;
  documentTemplateVersions = [1, 2];
  lang: Language;
  availableStockHTML: string;
  reasons: LookupDto[] = [
    { id: -1, titles: ['حذف نهائي', 'Permanent Delete'] },
    { id: 1, titles: ['آخر', 'Other'] }
  ];
  transactionDetails: TransactionLabelDetails = {};

  @ViewChild(StockSliderComponent) slider: StockSliderComponent;

  get _DocumentType() { return _DocumentType };
  get customReason() { return this.form.get('customReason') }
  get reason() { return this.form.get('reason') }
  get documentTemplateVersion() { return this.form.get('documentTemplateVersion') }
  get fromRange() { return this.form.get('fromRange') }
  get quantity() { return this.form.get('quantity') }
  get stockType() { return this.form.get('stockTypeId') }

  constructor(private loader: LoadingService,
    private alert: AlertService,
    private router: Router,
    private translate: TranslateService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private stockTypesService: StockTypesService,
    private stocksService: StocksService,
    private confirmation: ConfirmService) { }

  ngOnInit() {
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.populate = this.route.snapshot.data.mode == 'POPULATE';
    this.loader.load(LoaderType.Nav);
    this.formInit();

    this.transactionDetails.alertClass = this.populate ? 'success' : 'danger';
    this.transactionDetails.operation = this.populate ? 'STK_TITLE_POPULATE' : 'STK_TITLE_DEPOPULATE';

    this.stockTypesService.get(this.lang)
      .then(result => {
        this.stockTypes = result.list;
      }).finally(() => this.loader.load(LoaderType.Nav, false));

    if (!this.auth.currentUser.isSystemAccount)
      console.warn('not allowed!');
  }

  viewInfo() {
    this.confirmation.confirm(new DisplayModal({
      type: `${this.translate.instant('STK_TITLE_AVAILABLE_STOCKS')} - (${this.stockTypes.find(x => x.id == this.stockType.value).titles})`,
      innerHTML: this.availableStockHTML,
      note: ' '
    }))

  }

  onChangeFromRange() {
    this.transactionDetails.fromRange = this.fromRange.value;
    this.transactionDetails.toRange = Number(this.fromRange.value) + Number(this.quantity.value) - 1;
  }

  onChangeQuantity() {
    this.transactionDetails.count = this.quantity.value;
    this.transactionDetails.toRange = Number(this.fromRange.value) + Number(this.quantity.value) - 1;
  }

  onSelectStockType() {
    this.loadSystemAvailableStock();

    const stockType = this.stockTypes.find(x => x.id == this.stockType.value)
    this.transactionDetails.stockType = stockType.titles[0];
    this.documentType = stockType.documentType;
  }

  onSubmit() {
    if (this.populate) {
      this.reason.setErrors(null);
      this.customReason.setErrors(null);
      if (this.documentType != _DocumentType.TripTikDocument)
        this.documentTemplateVersion.setErrors(null);
    } else {
      this.documentTemplateVersion.setErrors(null);
      if (this.reason.value == -1)
        this.customReason.setErrors(null);
    }

    if (!this.form.valid)
      return Utils.validateAllFormFields(this.form);

    const cmd: PopulateCmd = {
      stockTypeId: this.stockType.value,
      fromRange: this.fromRange.value,
      toRange: Number(this.fromRange.value) + Number(this.quantity.value) - 1,
      populate: this.populate,
      reason: !this.populate ? this.reason.value && Number(this.reason.value) > 0 ? this.reasons.find(x => x.id == Number(this.reason.value)).titles[this.lang] : this.customReason.value : null,
      isPermanentDelete: !this.populate && this.reason && Number(this.reason.value) < 0,
      documentTemplateVersion: this.populate ? (this.documentTemplateVersion.value ? this.documentTemplateVersion.value : 1) : 0 //todo: check
    }

    if (!this.populate) {
      this.confirmation.confirm(new DeleteModal({
        type: 'STK_TITLE_STOCK',
        confirmQuestion: 'STK_DEPOPULATE_CONFIRM_DELETE_CONFIRMATION_QUESTION',
        title: `${cmd.fromRange} - ${cmd.toRange} (${this.stockTypes.find(x => x.id == this.stockType.value).titles})`, // node.titles[this.lang].toString(),
        note: JSON.stringify(this.availableStock)
      })).then(m => {
        if (m.indexOf('CONFIRMED') > -1) {
          this._populate(cmd);
        }
      });
    } else this._populate(cmd);
  }

  private formInit() {
    this.form = this.fb.group({
      stockTypeId: ['', [Validators.required]],
      fromRange: ['', [Validators.required, Validators.min(1), numbersOnlyValidator]],
      quantity: ['', [Validators.required, Validators.min(1), numbersOnlyValidator]],
      documentTemplateVersion: ['', [Validators.required]],
      reason: ['', [Validators.required]],
      customReason: ['', [Validators.required]]
    });
  }

  private _populate(cmd: PopulateCmd) {
    this.loader.load(LoaderType.Body);
    this.stocksService.populate(cmd)
      .then(result => {
        result ?
          this.alert.success(this.populate ? 'STK_LABEL_TRANSACTION_TYPE_POPULATE' : 'STK_LABEL_TRANSACTION_TYPE_DEPOPULATE')
          : this.alert.failure(this.populate ? 'STK_LABEL_TRANSACTION_TYPE_POPULATE' : 'STK_LABEL_TRANSACTION_TYPE_DEPOPULATE');

        if (result)
          this.router.navigate(['/', this.translate.currentLang, 'stocks', 'transactions']);
      })
      .finally(() => this.loader.load(LoaderType.Body, false));
  }

  private loadSystemAvailableStock() {
    this.loader.load(LoaderType.Body)
    this.stocksService
      .getSystemAvailableStocks(this.stockType.value, this.populate, false)
      .then(data => {
        this.availableStockHTML = this.renderAvailableStockAsHTML(data);
      }).finally(() => this.loader.load(LoaderType.Body, false))
  }

  private renderAvailableStockAsHTML(vm: AvailableStocksVm) {
    let ranges = '', singles = '', html = '';

    vm.stockTypesAvailableStocks[0].availableStock.forEach(x => {
      if (x[0] > 0)
        x.length > 1 ? ranges += `<li>${x[0]} - ${x[1]} (${x[1] - x[0] + 1})</li>`
          : singles += `<li>${x[0]}</li>`;
    });

    if (ranges)
      html += `<h4>${this.translate.instant('STK_LABEL_RANGES')}:</h4><ul>${ranges}</ul>`;

    if (singles)
      html += `<h4>${this.translate.instant('STK_LABEL_SINGLES')}:</h4><ul>${singles}</ul>`;

    return html;
  }
}

export interface StockGroup {
  group: string, label: string, value: number[]
}
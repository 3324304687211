import { BloodGroup } from 'src/app/core/models/enums/blood-group';
import { Gender } from 'src/app/core/models/enums/gender';
import { PrintObject } from 'src/app/core/models/enums/print-object';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { VehicleModelDto } from 'src/app/features/lookups/models/vehicle-model';
import { CurrencyDto } from 'src/app/features/lookups/models/currency';
import { PrintLogDto } from '../../_shared/models/issuing.models';

export interface TripTikVm {
    item: TripTikDetailsDto
}

export interface TripTiksVm {
    list: TripTikDto[],
    recordsCount: number
}

export interface TripTikDto {
    id: string,
    serial: number,
    cName: string,
    vPlateID: string,
    cPhoneNumber: string,
    accountId: number,
    accountTitles: string[],
    stockType: LookupDto,
    issued?: Date,
    printLogs: PrintLogDto[],
    revokeRequestId?: number,
    isMigratedData: boolean,
    parentAccounts: LookupDto;
    accountTypeTitle: string;
}

export class TripTikDetailsDto {
    id: string;
    serial: number;
    stockType: LookupDto;
    issued?: Date;
    expiryDate?: Date;
    hashedSerial?: string;
    accountId: number;
    accountTitles: string[];
    printLogs: PrintLogDto[];
    //value: number;
    //currency: CurrencyDto;
    isMigratedData: boolean;
    customer: TripTikCustomerDto = new TripTikCustomerDto();
    vehicle: TripTikVehicleDto = new TripTikVehicleDto();
    documentTemplateVersion: number;
}

export class TripTikCustomerDto {
    id: string;
    owner: TripTikOwnerDto = new TripTikOwnerDto();
    drivers: TripTikDriverDto[] = [];
}

export class TripTikOwnerDto {
    name: string;
    residenceCountryId: number;
    residenceCountryTitles: string[] = [];
    nationalityId?: number;
    nationalityTitles: string[] = [];
    passportID: string;
    identityID: string;
    address: { item1: string, item2: string } = { item1: null, item2: null };
    phoneNumber: { item1: string, item2: string } = { item1: null, item2: null };
    attachments: string[];
}

export interface TripTikDriverDto {
    name: string,
    address: string,
    phoneNumber: string,
    passportIDorIdentityID: string,
    attachments: string[]
}

export class TripTikVehicleDto {
    plateID: string;
    registrationCountryId: number;
    registrationCountryTitles: string[] = [];
    seatsCount: number;
    spareTiresCount: number;
    equipments: string;
    value: number;
    currency: CurrencyDto = { id: 0, titles: [], abbreviation: null };
    weightInKG: number;
    horsePower: number
    //color: LookupDto = { id: 0, titles: [] };
    colors: LookupDto[] = [];
    cylindersCount: number;
    engineID: string;
    chassisID: string;
    manufactureYear: number;
    type: LookupDto = { id: 0, titles: [] };
    make: LookupDto = { id: 0, titles: [] };
    model: VehicleModelDto = { id: 0, titles: [], makeId: 0, makeTitles: [], stockTypes: [] };
    extras: string;
    upholsteryType: number;
    options: number[]; //number or string
}

export interface TripTiksSearchQuery /* extends PaginationQuery */ {
    accountId?: number,
    serial?: number,
    fromDate?: string,
    toDate?: string,
    stockTypeId?: number,
    cName?: string,
    cResidenceCountryId?: number,
    cIdentityOrPassportID?: string,
    cPhoneNumber?: string,
    vPlateID?: string,
    vRegistrationCountryId?: number[],
    vTypeId?: number,
    vMakeId?: number,
    vChassisID?: string,
    vManufactureYear?: number,
    isIssued?: boolean,
    isDamaged?: boolean
}



import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LookupDto } from "src/app/features/lookups/models/lookup";
import { populateAccountsSelect } from "src/app/shared/helpers/populate-accounts-select";
import { FileLoaderService } from "src/app/shared/services/file-loader.service";
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { DocumentRevokeRequestDto, PaginationQuery, DocumentsRevokeRequestsVm } from '../models/issuing.models';
import { IssuingService } from './issuing.service';

export class DocumentsRevokeRequestsDataSource implements DataSource<DocumentRevokeRequestDto> {

    public revokeRequestsSubject = new BehaviorSubject<DocumentRevokeRequestDto[]>([]);
    private countSubject = new BehaviorSubject<number>(0);
    public counter$ = this.countSubject.asObservable();

    constructor(private issuingService: IssuingService,
        private loader: LoadingService,
        private fileLoader: FileLoaderService) {
    }

    loadDocumentsRevokeRequests(pagination: PaginationQuery, stockTypeId = 0, query: string = null) {
        this.issuingService.getDocumentsRevokeRequests(pagination, stockTypeId, query)
            .pipe(
                catchError(() => of([])),
                finalize(() => { })
            )
            .subscribe((result: DocumentsRevokeRequestsVm) => {
                //console.log(result);
                result.revokeRequests.map(async x => {
                    if (x.attachment)
                        x.attachment = (<any>await this.fileLoader.loadAsync(x.attachment as string)).changingThisBreaksApplicationSecurity;

                    return x;
                });

                // result.revokeRequests.forEach(request => {
                //     request.accountParentAccounts = populateAccountsSelect([request.accountParentAccounts])[0];
                // });

                result.revokeRequests.map(request => {
                    request.accountParentAccounts = populateAccountsSelect([request.accountParentAccounts])[0];
                    return request;
                });
                this.revokeRequestsSubject.next(result.revokeRequests);
                this.countSubject.next(result.recordsCount);
                this.loader.load(LoaderType.Nav, false);
                this.loader.load(LoaderType.Body, false);
            });
    }

    connect(): Observable<DocumentRevokeRequestDto[]> {
        return this.revokeRequestsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.revokeRequestsSubject.complete();
        this.countSubject.complete();
    }
}

import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray, FormControl } from '@angular/forms';
import { CountryDto } from 'src/app/features/lookups/models/country';
import { CountriesService } from 'src/app/features/lookups/services/countries';
import { Language } from 'src/app/core/models/enums/language';
import { TranslateService } from '@ngx-translate/core';
import { BloodGroup } from 'src/app/core/models/enums/blood-group';
import { IntLicensePermitClass } from 'src/app/core/models/enums/license-permit-class';
import { Gender } from 'src/app/core/models/enums/gender';
import { StocksService } from 'src/app/features/stock/services/stocks.service';
//import { StockType } from 'src/app/core/models/enums/stock-type';
import * as moment from 'moment';
import { Utils } from 'src/app/shared/services/utils';
//import { isArray } from 'util';
import { IntLicensesService } from '../../services/int-licenses.service';
import { PostIntLicenseCmd } from '../../models/create.cmd';
import { ActivatedRoute, Router } from '@angular/router';
import { IntLicenseVm, IntLicenseDetailsDto } from '../../models/int-license';
import { FileLoaderService } from 'src/app/shared/services/file-loader.service';
import * as _ from 'lodash';
import { englishAndSpacesValidator, numbersOnlyValidator, passportIdOrIdentityIdValidator, phoneNumberValidator } from 'src/app/shared/services/custom-validators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/core/models/enums/role';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FileHandle } from 'src/app/shared/directives/drag.directive';

@Component({
  selector: 'mac-int-manage-license',
  templateUrl: './manage-int-license.component.html',
  styleUrls: ['./manage-int-license.component.scss'],
  providers: [CountriesService, StocksService, IntLicensesService, FileLoaderService]
})

export class ManageIntLicenseComponent implements OnInit {
  lang: Language;
  form: FormGroup;
  countries: CountryDto[];
  availableSerials: number[];
  cashedVm: IntLicenseVm;
  dto: IntLicenseDetailsDto = new IntLicenseDetailsDto();
  updateMode: boolean;
  _loadingGif: string = 'assets/images/Ring-Preloader.gif';
  dateFormat = 'L';
  mode: 'ADD' | 'UPDATE' | 'CLONE';
  videoConstraints = {
    video: {
      facingMode: "environment",
      width: { ideal: 4096 },
      height: { ideal: 2160 }
    }
  };
  
  videoWidth = 0;
  videoHeight = 0;
  isCamStreaming: boolean;
  camStream: MediaStream;
  objectId: string;

  keyword: string = 'name';
  key: string;


  @ViewChild('video') videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;

  get moment() { return moment };
  get Object() { return Object };
  get BloodGroup() { return BloodGroup };
  get IntLicensePermitClass() { return IntLicensePermitClass };
  get Gender() { return Gender };
  get serial() { return this.form.get('serial') };
  get name() { return this.form.get('name') };
  get dateOfBirth() { return this.form.get('dateOfBirth') };
  get nationality() { return this.form.get('nationalityId') };
  get country() { return this.form.get('countryId') };
  get passportID() { return this.form.get('passportID') };
  get bloodGroup() { return this.form.get('bloodGroup') };
  get gender() { return this.form.get('gender') };
  get accordingTo() { return this.form.get('accordingToId') };
  get permitClasses() { return this.form.get('permitClasses') };
  get phoneNumber() { return this.form.get('phoneNumber') };
  get attachments() { return this.form.get('attachments') as FormArray };
  get dataAccuracyAcknowledgment() { return this.form.get('dataAccuracyAcknowledgment') };
  get validityYears() { return this.form.get('validityYears') };

  _validityYears: number[] = [];



  constructor(private loader: LoadingService,
    private fb: FormBuilder,
    private countriesService: CountriesService,
    private stocksService: StocksService,
    public translate: TranslateService,
    private intLicenseService: IntLicensesService,
    private route: ActivatedRoute,
    public fileLoader: FileLoaderService,
    private alert: AlertService,
    private router: Router,
    private renderer: Renderer2,
    public auth: AuthService) { }



  async ngOnInit() {

    if (this.auth.currentUser.intLicenseAllowedValidity > 1)
      this._validityYears = new Array(this.auth.currentUser.intLicenseAllowedValidity);

    moment.locale('en-gb');
    document.querySelector('mat-card').classList.remove('overflow-hidden');
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.loader.load(LoaderType.Nav);
    this.formInit();
    this.countries = await (await this.countriesService.get(this.lang)).list;
    // this.countries = this.countries.map(result => {
    //   result.countryTitles = result.countryTitles[0];
    //   result.nationalityTitles = result.nationalityTitles[0];
    //   return result;
    // })
    this.availableSerials = this.spreadRanges((await this.stocksService.getAvailableStocks(10)).stockTypesAvailableStocks[0].availableStock);

    this.mode = this.route.snapshot.data.mode;

    this.objectId = this.route.snapshot.paramMap.get('id');
    this.key = this.route.snapshot.paramMap.get('key');

    if (this.objectId || this.key) {
      this.updateMode = this.mode == 'UPDATE';

      if (this.mode == 'CLONE') {
        this.dto = await (await this.intLicenseService.getByHashedSerial(this.key)).intLicense;
      } else
        this.dto = await (await this.intLicenseService.get(this.objectId)).intLicense;

      //this.dto = await (await this.intLicenseService.get(this.objectId)).intLicense;

      if (!this.dto || (this.updateMode && this.dto.issued)) {    //will ignore if cloning
        this.router.navigate([`/${this.translate.currentLang}/not-found`]);
        return;
      }
      if (this.updateMode) {
        this.availableSerials.unshift(this.dto.serial);
      } else {
        if (this.availableSerials.length > 0)
          this.form.patchValue({ serial: this.availableSerials[0] });
        else {
          this.alert.failure("LIC_ALERT_NO_STOCK_AVAILABLE", false);
          this.form.disable();
          return;
        }
      }

      this.dto.serial = this.updateMode ? this.dto.serial : this.availableSerials[0];
      this.form.patchValue(this.dto);
      // this.attachments.controls.forEach(async (ctrl: FormControl, i) => {
      //   ctrl.setValue(await this.fileLoader.loadAsync(ctrl.value));
      // });

      this.attachments.controls.forEach(async (ctrl: FormControl, i) => {
        let path = ctrl.value;
        if (this.key)
          path = `${path}?key=${this.key}`;
        ctrl.setValue(await this.fileLoader.loadAsync(path));
      });

    } else {
      if (this.availableSerials.length > 0)
        this.form.patchValue({ serial: this.availableSerials[0] });
      else {
        this.alert.failure("LIC_ALERT_NO_STOCK_AVAILABLE", false);
        this.form.disable();
      }
    }
    this.loader.load(LoaderType.Nav, false);

    // this.filteredCountries = this.form.get('_nationality').valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filter(value))
    //   );
  }


  //myControl = new FormControl();
  //options: string[] = ['One', 'Two', 'Three'];
  // filteredCountries: Observable<CountryDto[]>;

  // private _filter(value: string): CountryDto[] {
  //   //console.log(value);
  //   const filterValue = value.toLowerCase();

  //   return this.countries.filter(c => c.nationalityTitles[this.lang].toLowerCase().includes(filterValue));
  // }

  preview($event, control: FormControl | AbstractControl) {
    const tgt = $event.target || window.event.srcElement,
      files = Array.isArray($event) ? $event : tgt.files;

    if (FileReader && files && files.length) {
      const fr = new FileReader();
      fr.onload = () => {
        if (fr.result.toString().length <= 2 * 2 ** 20)
          control.setValue(fr.result.toString());
        else
          alert('file limit is 2 MB.');
      };
      fr.readAsDataURL(files[0]);
    }
    else {
      //console.error('not supported!');
    }
  }

  fileDropped(file: FileHandle, control: FormControl | AbstractControl): void {

    control.setValue(file.url);

    // if (file.url.length <= 2 * 2 ** 20)
    //   control.setValue(file.url);
    // else
    //   alert('file limit is 2 MB.');
  }

  removeImage(control: FormControl | AbstractControl) {
    control.reset();
    control.markAsTouched();
  }

  startCamera() {
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      navigator.mediaDevices.getUserMedia(this.videoConstraints)
        .then(stream => {
          this.camStream = stream;
          this.attachVideo(stream);
        }).catch(this.handleError);
      this.isCamStreaming = true;
    } else {
      alert('Sorry, camera not available.');
    }
  }

  capture() {
    const control = this.attachments.get([0]);
    this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
    this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
    this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
    const imageData = this.canvas.nativeElement.toDataURL("image/png", 1);
    control.setValue(imageData);
    this._stopCamera();
  }

  stopCamera() {
    this._stopCamera();
    const control = this.attachments.get([0]);
    control.reset();
    control.markAsTouched();
  }

  private _stopCamera() {
    this.isCamStreaming = false;
    this.videoElement.nativeElement.pause();
    this.videoElement.nativeElement.removeAttribute('src');
    this.camStream.getTracks().forEach(function (track) {
      track.stop();
    });
  }

  handleError(error) {
    //console.log('Error: ', error);
  }

  attachVideo(stream: MediaStream) {

    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
    this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
      this.videoHeight = this.videoElement.nativeElement.videoHeight;
      this.videoWidth = this.videoElement.nativeElement.videoWidth;
    });
  }

  isExpired(expiryDate) {
    return moment(expiryDate).isBefore(Date.now());
  }

  private zeroPad = (num, places) => String(num).padStart(places, '0');

  toISODate(date) {
    return `${date.getFullYear()}-${this.zeroPad(date.getMonth() + 1, 2)}-${this.zeroPad(date.getDate(), 2)}T00:00:00.000Z`;
  }

  onSubmit() {
    //console.warn(this.form.value);

    if (this.dateOfBirth.value) {
      //this.dateOfBirth.setValue(moment(this.dateOfBirth.value).format());
      this.dateOfBirth.setValue(this.toISODate(new Date(this.dateOfBirth.value)));
     // console.warn(this.form.value);
    }

    if (!this.form.valid)
      return Utils.validateAllFormFields(this.form);

    if (!this.dataAccuracyAcknowledgment.value) {
      this.alert.failure("LIC_ALERT_DATA_ACCURACY_ACKNOWLEDGMENT", false);
      return;
    }

    this.attachments.controls.forEach((ctrl, i) => {
      //const _attachments = this.attachments.controls[i];
      //for (let ctrl of Object.keys((<FormGroup>grp).controls))
      if (ctrl.value instanceof Object)
        ctrl.setValue(ctrl.value.changingThisBreaksApplicationSecurity)
    });

    this.loader.load(LoaderType.Body);

    const cmd = this.form.value as PostIntLicenseCmd;
    cmd.id = this.dto.id;

    if (!this.updateMode) {
      cmd.id = null;
      if (this.auth.currentUser.isInRole(Role.INT_LICENSES_CREATE))
        this.intLicenseService.create(cmd)
          .then(id => {
            if (id) {
              this.alert.success(DefaultAlertAction.ADDING);
              setTimeout(() => {
                this.router.navigate(['/', this.translate.currentLang, 'issuing', 'int-licenses', 'view', id]);
              }, 1000);
            } else this.alert.failure(DefaultAlertAction.ADDING);
          })
          .finally(() => this.loader.load(LoaderType.Body, false));
    }
    else {
      if (this.auth.currentUser.isInRole(Role.INT_LICENSES_UPDATE))
        this.intLicenseService.update(cmd)
          .then(result => {
            if (result) {
              this.alert.success(DefaultAlertAction.UPDATING);

              setTimeout(() => {
                this.router.navigate(['/', this.translate.currentLang, 'issuing', 'int-licenses', 'view', cmd.id]);
              }, 1000);

            } else this.alert.failure(DefaultAlertAction.UPDATING);
          })
          .finally(() => this.loader.load(LoaderType.Body, false));
    }
  }


  private formInit() {
    this.form = this.fb.group({
      serial: ['', [Validators.required]],
      name: ['', [Validators.required, englishAndSpacesValidator, Validators.minLength(10), Validators.maxLength(25)]],
      dateOfBirth: ['', [Validators.required]],
      nationalityId: [null, [Validators.required]],
      countryId: [null, [Validators.required]],
      passportID: ['', [Validators.required, passportIdOrIdentityIdValidator, Validators.minLength(6), Validators.maxLength(15)]],
      bloodGroup: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      accordingToId: [null, [Validators.required]],
      permitClasses: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required, phoneNumberValidator]],
      attachments: this.fb.array([
        ['', [Validators.required]],    //personalPhoto
        ['', [Validators.required]],    //passportPhoto
        ['', [Validators.required]]     //licensePhoto
      ]),

      dataAccuracyAcknowledgment: [false],
      validityYears: [1, [Validators.required]]
    })
  }

  private spreadRanges(ranges: number[][]) {
    const result = [];

    ranges.forEach(s => {
      if (s.length > 1) {
        let _start = s[0];
        const _end = s[1];
        while (_start <= _end)
          result.push(_start++);
      }
      else
        result.push(s[0]);
    });
    return result;
  }

  

// Example usage


}
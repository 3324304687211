import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Role } from 'src/app/core/models/enums/role';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AccountType } from '../models/enums/account-type';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, private router: Router, private alert: AlertService) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.isAuthenticated()) {
      let securityRoles = _route.data.securityRoles ? _route.data.securityRoles as Role[] : [];
      if (securityRoles.length > 0) {
        for (let role of securityRoles) 
          if (this.auth.currentUser.isInRole(role))
            return true;

        //this.alert.error('test');
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //this.router.navigate(['/login']);
        return false;
      }
      return true;
    }

    //todo: check if the return url is ar or en only, should skip
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { PaginationQuery } from '../../issuing/_shared/models/issuing.models';
import { TripTiksClaimsVm, PostTripTikClaimCommand, TripTiksClaimsSearchQuery, TripTikClaimVm, UpdateStatusTripTikClaimCommand, TripTiksClaimsLogsVm, TripTikClaimDetailsVm, TripTikClaimAttachmentsVm } from '../models/trip-tik-claim.model';

@Injectable()
export class TripTiksClaimsService {
    constructor(private api: ApiHandler) { }

    delete(id: string) {
        return this.api.delete(EndPoint.INT_lICENSE, id).toPromise();
    }

    updateStatus(cmd: UpdateStatusTripTikClaimCommand) {
        return this.api.update(EndPoint.TRIPTIK_CLAIMS, cmd).toPromise();
    }

    create(cmd: PostTripTikClaimCommand) {
        return this.api.create<PostTripTikClaimCommand, string>(EndPoint.TRIPTIK_CLAIMS, cmd).toPromise();
    }

    get(id: string) {
        return this.api.get<TripTikClaimDetailsVm>(EndPoint.TRIPTIK_CLAIMS, id).toPromise();
    }

    getClaims(pagination: PaginationQuery, query: string = '', statusId?: number, pursuingZoneId?: number) {
        return this.api.get<TripTiksClaimsVm>(EndPoint.TRIPTIK_CLAIMS, null, { ...pagination, query, statusId, pursuingZoneId });
    }

    search(pagination: PaginationQuery, query: TripTiksClaimsSearchQuery, statusId?: number, pursuingZoneId?: number) {
        return this.api.get<TripTiksClaimsVm>(EndPoint.TRIPTIK_CLAIMS_SEARCH, null, { ...pagination, ...query });
    }

    getClaimsLogs(pagination: PaginationQuery, query: string = '') {
        return this.api.get<TripTiksClaimsLogsVm>(EndPoint.TRIPTIK_CLAIMS_LOGS, null, { ...pagination, query });
    }

    searchClaimsLogs(pagination: PaginationQuery, query: TripTiksClaimsSearchQuery) {
        return this.api.get<TripTiksClaimsLogsVm>(EndPoint.TRIPTIK_CLAIMS_LOGS_SEARCH, null, { ...pagination, ...query });
    }

    getClaimAttachments(tripTikClaimId: string) {
        return this.api.get<TripTikClaimAttachmentsVm>(EndPoint.TRIPTIK_CLAIMS_ATTACHMENTS, tripTikClaimId).toPromise();
    }
}


import { AuthService } from '../../auth/auth.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Utils } from 'src/app/shared/services/utils';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'src/app/shared/services/alert.service';
import { comparePasswords } from 'src/app/shared/services/custom-validators';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';

@Component({
  selector: 'mac-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {
  styleLoading: boolean;
  form: FormGroup;
  //error: any;
  //success: any;
  view: 'RECOVER' | 'RESET';
  userId: string;
  token: string;

  get email() { return this.form.get('email'); };
  get password() { return this.form.get('pass.password'); };
  get passwordConfirm() { return this.form.get('pass.passwordConfirm'); };

  constructor(private auth: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private alert: AlertService,
    private router: Router,
    private loader: LoadingService,
    private fb: FormBuilder) {


    this.translate.setDefaultLang('ar');
    this.translate.get("last.dummy");
    LoadingService.style.next(false);
  }

  private loadStyle(lang: string) {

    const head = this.document.getElementsByTagName('head')[0];
    const body = this.document.getElementsByTagName('body')[0];

    body.setAttribute('dir', lang == 'ar' ? 'rtl' : 'ltr');
    const styleFileName = lang == 'ar' ? 'bootstrap-rtl.css' : 'bootstrap.css';
    let themeLink = this.document.getElementById('bootstrap') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleFileName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'bootstrap';
      style.rel = 'stylesheet';
      style.href = styleFileName;
      //will be always overriden by global styles.scss
      head.prepend(style);
    }
  }

  ngOnInit() {
    //AuthService.logout();
    this.userId = this.route.snapshot.queryParamMap.get('id');
    this.token = this.route.snapshot.queryParamMap.get('token');

    //console.log(this.userId, this.token);

    if (!this.userId || !this.token) {
      this.view = 'RECOVER'
      this.form = this.fb.group({
        email: ['', [Validators.required, Validators.email]]
      });
    } else {
      //console.log(this.token.trim().replace(/\s/g, ''))
      this.view = 'RESET';
      this.form = this.fb.group({
        pass: this.fb.group({
          password: ['', [Validators.required, Validators.minLength(6)]],  //todo: regex for password pattern
          passwordConfirm: ['']
        },
          { validator: comparePasswords })
      });
    }

    this.translate.get("last.dummy");

    //this.translate.use('ar');
    this.translate.onLangChange.subscribe(l => {
      //this.loader.load(null);
      this.loadStyle(l.lang);
      // this.loader.load(null, false);
    });

    // this.loader.styleLoading.subscribe(status => {
    //   this.styleLoading = status;
    // });

    LoadingService.style.subscribe(status => {
      this.styleLoading = status;
    });




  }

  // resetForm: FormGroup;
  // recoverForm: FormGroup;



  ngAfterViewInit() {
    setTimeout(() => {
      this.loader.load(LoaderType.Nav, false);
      //LoadingService.style.next(false);
    }, 500);

    //this.loader.load(null, false);
  }

  onSubmit() {
    //this.error = null;
    //const form = this.view == 'RECOVER' ? this.recoverForm : this.resetForm;

    if (!this.form.valid)
      return Utils.validateAllFormFields(this.form);

    this.loader.load(LoaderType.Nav);
    this.form.disable();

    if (this.view == 'RECOVER') {
      this.auth.forgotPassword(this.form.value)
        .then(result => {
          //console.log(result);
          //LoadingService.style.next(true);
          if (result) {
            this.alert.success('SEC_ALERT_PASSWORD_RECOVERY_REQUEST', false);
            // setTimeout(() => {
            //   //redirect to login
            //   this.router.navigate(['/', this.translate.currentLang ? this.translate.currentLang : 'ar']);
            // }, 1000);
          } else {
            this.alert.failure('_MESSAGE_CANNOT_PROCESS', false);
            this.form.enable();
          }
        })
        .catch(e => {
          this.alert.failure('_MESSAGE_CANNOT_PROCESS', false);
          this.form.enable();
          // this.error = '_MESSAGE_ALERT_ERROR';
        })
        .finally(() => this.loader.load(LoaderType.Nav, false));
    }
    else {
      this.auth.resetPassword({ id: this.userId, token: this.token.trim().replace(/\s/g, '+'), password: this.password.value })
        .then(result => {
          //console.log(result);
          if (result) {
            this.alert.success('_ACTION_RESETTING');
            setTimeout(() => {
              //redirect to login
              this.router.navigate(['/', this.translate.currentLang ? this.translate.currentLang : 'ar']);
            }, 1000);
          } else {
            this.alert.failure('_MESSAGE_CANNOT_PROCESS', false);
            this.form.enable();
          }
        }).catch(e => {
          this.alert.failure('_MESSAGE_CANNOT_PROCESS', false);
          this.form.enable();
          //this.error = '_MESSAGE_CANNOT_PROCESS';
        })
        .finally(() => this.loader.load(LoaderType.Nav, false));
    }
  }




}

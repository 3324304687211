import {
    Directive,
    HostBinding,
    HostListener,
    Output,
    EventEmitter,
    ElementRef
} from "@angular/core";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface FileHandle {
    url: string,
    name: string,
    // url: SafeUrl,
    container: any
}

@Directive({
    selector: "[macDrag]"
})
export class DragDirective {
    // @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();
    @Output() file: EventEmitter<FileHandle> = new EventEmitter();



    @HostBinding("style.background") private background = "#eee";
    @HostBinding("style.border") private border = "none";

    constructor(private sanitizer: DomSanitizer) { }

    @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
        ////console.warn(evt);
        evt.preventDefault();
        //evt.stopPropagation();

        // //console.warn('dragover', this.border);
        ////console.log(this.border);
        if (this.border == "none") {
            this.border = "2px dashed #82c8ef";
            ////console.warn('dragover', 'style applied')
        }
        //this.b
        //this.sanitizer.bypassSecurityTrustStyle('border: 5px dashed #82c8ef');
        //  this.background = "#999";
    }

    @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
        ////console.warn(evt);
        evt.preventDefault();
        //evt.stopPropagation();
        ////console.warn('dragleave', this.border);
        ////console.log(this.border);
        if (this.border != "none") {
            this.border = "none";
            ////console.warn('dragleave', 'style applied')
        }
        //this.background = "#eee";
        //this.sanitizer.bypassSecurityTrustStyle('border: none');
    }

    @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
        ////console.warn(evt);
        evt.preventDefault();
        //evt.stopPropagation();
        //this.background = '#eee';
        // //console.warn('drop', this.border);
        ////console.log(this.border);
        if (this.border != "none") {
            this.border = "none";
            ////console.warn('drop', 'style applied');
        }

        ////console.log(evt);
        ////console.log(evt.currentTarget);
        // //console.log(evt.target)

        //  const elm = <ElementRef><any>evt.currentTarget;
        // //console.warn(elm.nativeElement);

        //  const _index = 0;// (<any>evt.currentTarget).tabindex;
        ////console.log(evt.target);
        // this.file.emit({ file: null, url: 'assets/images/Ring-Preloader.gif', container: evt.currentTarget });

        const currentTarget = evt.currentTarget;
        //console.log(evt);
        //this.file.emit({ url: 'assets/images/Ring-Preloader.gif', name: null, container: currentTarget });

        // if (evt.dataTransfer.files.length) {
        //     this.file.emit({
        //         file: evt.dataTransfer.files[0],
        //        // url: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(evt.dataTransfer.files[0])),
        //         container: evt.currentTarget
        //     });
        // }

        if (FileReader && evt.dataTransfer.files.length) {
            const fr = new FileReader();
            // //console.log(evt.dataTransfer.files.item(0))
            const _name = evt.dataTransfer.files.item(0).name;
            fr.onload = () => {
                if (fr.result.toString().length <= 2 * 2 ** 20)
                    this.file.emit({
                        url: fr.result.toString(),
                        name: _name,
                        //url: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(evt.dataTransfer.files[0])),
                        container: currentTarget
                    });
                else
                    alert('file limit is 2 MB.');
                // this[`_${this.images[index]}`] = fr.result.toString();
                //(<AbstractControl>this[this.images[index]]).setValue(fr.result.toString());
                //img.setAttribute("src", fr.result.toString());

                // this.licensePhoto.setValue()
                // this.form.patchValue({
                //   [this.images[index]]: fr.result.toString()
                // })
            };
            fr.readAsDataURL(evt.dataTransfer.files.item(0));
        }
        // Not supported
        else {
            //console.error('not supported!');
            //this[`_${this.images[index]}`] = oldValue;
            //(<AbstractControl>this[this.images[index]]).setValue(oldValue);
        }
    }
}

import { BrowserModule, Title } from '@angular/platform-browser';
import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService, TranslateParser } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UrlSerializer } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { LightboxModule } from 'ngx-lightbox';
import { ChartsModule } from 'ng2-charts';
import { NgxKjuaModule } from 'ngx-kjua';
import { NgxPrintModule } from 'ngx-print';
import { MomentDateModule, MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorPickerModule } from 'ngx-color-picker';

//material
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule, MatSnackBar, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

//pipes
import { StringJoinPipe } from './shared/pipes/string-join.pipe';
import { ArrayMapPipe } from './shared/pipes/array-map.pipe';
import { ParamsTranslatePipe } from './shared/pipes/params-translate.pipe';

//directives
import { DragDirective } from './shared/directives/drag.directive';
import { ConfirmTemplateDirective } from './shared/directives/modal.directive';

//services
import { UrlSerializerService } from './core/url-serializer.service';
import { ConfirmService } from './shared/services/modal.service';
import { AlertService } from './shared/services/alert.service';
import { ValidationService } from './shared/services/validation.service';
import { LoadingService } from './shared/services/loading';
import { AuthGuardService } from './core/auth/auth-guard.service';
import { BreadcrumbService } from './shared/services/breadcrumb.service';
import { SidenavService } from './core/services/sidenav.service';
import { AuthService } from './core/auth/auth.service';
import { ConfirmState } from './shared/models/modal';
import { LangMatPaginatorIntl } from './shared/helpers/lang-paginator-intl';
import { httpInterceptorProviders, AuthInterceptor } from './core/auth/auth-interceptor';
import { ApiHandler } from './core/api-handler';

//components
import { BodyLoaderComponent } from './shared/components/body-loader/body-loader.component';
import { CountriesComponent } from './features/lookups/containers/countries/countries.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { HeaderComponent } from './core/components/header/header.component';
import { LoginComponent } from './core/components/login/login.component';
import { MainComponent } from './core/components/main/main.component';
import { SidenavComponent } from './core/components/sidenav/sidenav.component';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { AccountsComponent } from './features/accounts/containers/accounts/accounts.component';
import { SecurityGroupsComponent } from './features/security/containers/security-groups/security-groups.component';
import { UsersGroupsComponent } from './features/security/containers/users-groups/users-groups.component';
import { TransactionsComponent } from './features/stock/containers/transactions/transactions.component';
import { UndoRequestsComponent } from './features/stock/containers/undo-requests/undo-requests.component';
import { UndoTryComponent } from './features/stock/containers/undo-try/undo-try.component';
import { AccountUsersComponent } from './features/security/containers/account-users/account-users.component';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { UserProfileComponent } from './features/accounts/containers/user-profile/user-profile.component';
import { ConfirmModalComponent } from './shared/components/modal/modal.component';
import { ManageAccountComponent } from './features/accounts/containers/manage-account/manage-account.component';
import { UnderConstructionComponent } from './shared/components/under-construction/under-construction.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { ManageUserComponent } from './features/security/containers/manage-user/manage-user.component';
import { StockSliderComponent } from './features/stock/components/stock-slider/stock-slider.component';
import { ValidationErrorComponent } from './shared/components/validation-error/validation-error.component';
import { AlertComponent } from './shared/components/alert/alert.component';
import { ManageIntLicenseComponent } from './features/issuing/int-licenses/containers/manage-int-license/manage-int-license.component';
import { IntLicensesComponent } from './features/issuing/int-licenses/containers/int-licenses/int-licenses.component';
import { ViewIntLicenseComponent } from './features/issuing/int-licenses/containers/view-int-license/view-int-license.component';
import { IntLicensePrintComponent } from './features/issuing/int-licenses/components/int-license-print/int-license-print.component';
import { TripTiksComponent } from './features/issuing/trip-tik/containers/trip-tiks/trip-tiks.component';
import { ManageTripTikComponent } from './features/issuing/trip-tik/containers/manage-trip-tik/manage-trip-tik.component';
import { ViewTripTikComponent } from './features/issuing/trip-tik/containers/view-trip-tik/view-trip-tik.component';
import { CommitmentPrintComponent } from './features/issuing/int-licenses/components/commitment-print/commitment-print.component';
import { TripTikPrintComponent } from './features/issuing/trip-tik/components/trip-tik-print/trip-tik-print.component';
import { ReceiptPrintComponent } from './features/issuing/trip-tik/components/receipt-print/receipt-print.component';
import { VehicleTypesComponent } from './features/lookups/containers/vehicle-types/vehicle-types.component';
import { VehicleModelsComponent } from './features/lookups/containers/vehicle-models/vehicle-models.component';
import { CurrenciesComponent } from './features/lookups/containers/currencies/currencies.component';
import { TripTiksClaimsComponent } from './features/trip-tiks-claims/containers/trip-tiks-claims/trip-tiks-claims.component';
import { ViewTripTikClaimComponent } from './features/trip-tiks-claims/containers/view-trip-tik-claim/view-trip-tik-claim.component';
import { DocumentsRevokeRequestsComponent } from './features/issuing/_shared/containers/documents-revoke-requests/documents-revoke-requests.component';
import { VerificationComponent } from './features/issuing/_shared/containers/verification/verification.component';
import { TransactComponent } from './features/stock/containers/transact/transact.component';
import { StocksTotalsComponent } from './features/stock/containers/stocks-totals/stocks-totals.component';
import { StocksAvailableComponent } from './features/stock/containers/stocks-available/stocks-available.component';
import { PopulateComponent } from './features/stock/containers/populate/populate.component';
import { VehicleMakesComponent } from './features/lookups/containers/vehicle-makes/vehicle-makes.component';
import { VehicleColorsComponent } from './features/lookups/containers/vehicle-colors/vehicle-colors.component';
import { StockTypesComponent } from './features/lookups/containers/stock-types/stock-types.component';
import { AccountTypesComponent } from './features/lookups/containers/account-types/account-types.component';
import { AccountStatusesComponent } from './features/lookups/containers/account-statuses/account-statuses.component';
import { CitiesComponent } from './features/lookups/containers/cities/cities.component';
import { DashboardComponent } from './features/statistics/containers/dashboard/dashboard.component';
import { UpdateStatusTripTikClaimComponent } from './features/trip-tiks-claims/containers/update-status-trip-tik-claim/update-status-trip-tik-claim.component';
import { ManageTripTikClaimComponent } from './features/trip-tiks-claims/containers/manage-trip-tik-claim/manage-trip-tik-claim.component';
import { TripTiksClaimsLogsComponent } from './features/trip-tiks-claims/containers/trip-tiks-claims-logs/trip-tiks-claims-logs.component';
import { TranslationResolver } from './shared/helpers/translation.resolver';
import { ConfigResolver } from './shared/helpers/config.resolver';
import { CustomDateAdapter } from './shared/helpers/custom-date-adapter';
import { TripTikClaimAttachmentsComponent } from './features/trip-tiks-claims/containers/trip-tik-claim-attachments/trip-tik-claim-attachments.component';
import { RecoverPasswordComponent } from './core/components/recover-password/recover-password.component';
import { PursuingZonesComponent } from './features/lookups/containers/pursuing-zones/pursuing-zones.component';
import { CustomsPortsComponent } from './features/lookups/containers/customs-ports/customs-ports.component';
import { LicTransactionsPrintComponent } from './features/stock/components/lic-transactions-print/lic-transactions-print.component';
import { TrpTransactionsPrintComponent } from './features/stock/components/trp-transactions-print/trp-transactions-print.component';
import { EndPoint } from './core/models/enums/end-point';
import { ExpiredDocumentsQueryComponent } from './features/issuing/_shared/containers/expired-documents-query/expired-documents-query.component';
import { ViewAccountComponent } from './features/accounts/containers/view-account/view-account.component';
import { IssuedDocumentsQueryComponent } from './features/issuing/_shared/containers/issued-documents-query/issued-documents-query.component';
import { environment } from 'src/environments/environment';
import { TransactionDetailsLabelComponent } from './features/stock/components/transaction-details-label/transaction-details-label.component';
import { ELicensesComponent } from './features/issuing/int-licenses/containers/e-licenses/e-licenses.component';
import { CouponManagementComponent } from './features/issuing/coupon-management/containers/coupon-management/coupon-management.component';
import { ViewCouponManagementComponent } from './features/issuing/coupon-management/containers/view-coupon-management/view-coupon-management.component';
import { ManageCouponManagementComponent } from './features/issuing/coupon-management/containers/manage-coupon-management/manage-coupon-management.component';
import { ViewLicenseDetailComponent } from './features/issuing/coupon-management/containers/view-license-detail/view-license-detail.component';

//import { AppConfigResolver } from './shared/helpers/app-config.resolver';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  //return new TranslateHttpLoader(http);
  //return new TranslateHttpLoader(http, './assets/_lang/', '.json');
  return new TranslateHttpLoader(http, `${environment.apiBaseUrl}/resources/`, '.json');


}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'ar'
      translate.setDefaultLang('ar');
      translate.use(langToSet).subscribe(() => {
        //console.info(`Successfully initialized '${langToSet}' language.'`);
      }, err => {
        //console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

@NgModule({
  declarations: [
    AppComponent,
    CountriesComponent,
    HeaderComponent,
    LoginComponent,
    MainComponent,
    SidenavComponent,
    FooterComponent,
    BreadcrumbComponent,
    AccountsComponent,
    ManageAccountComponent,
    SecurityGroupsComponent,
    UsersGroupsComponent,
    CitiesComponent,
    AccountStatusesComponent,
    AccountTypesComponent,
    StockTypesComponent,
    VehicleColorsComponent,
    VehicleMakesComponent,
    PopulateComponent,
    StocksAvailableComponent,
    StocksTotalsComponent,
    TransactComponent,
    TransactionsComponent,
    UndoRequestsComponent,
    UndoTryComponent,
    AccountUsersComponent,
    DialogComponent,
    DashboardComponent,
    UserProfileComponent,
    UnderConstructionComponent,
    NotFoundComponent,
    ConfirmTemplateDirective,
    ConfirmModalComponent,
    ManageUserComponent,
    BodyLoaderComponent,
    StockSliderComponent,
    ValidationErrorComponent,
    AlertComponent,
    ParamsTranslatePipe,
    ManageIntLicenseComponent,
    IntLicensesComponent,
    LicTransactionsPrintComponent,
    TrpTransactionsPrintComponent,
    DragDirective,
    ViewIntLicenseComponent,
    IntLicensePrintComponent,
    TripTiksComponent,
    ManageTripTikComponent,
    ViewTripTikComponent,
    CommitmentPrintComponent,
    TripTikPrintComponent,
    ReceiptPrintComponent,
    VehicleTypesComponent,
    VehicleModelsComponent,
    CurrenciesComponent,
    TripTiksClaimsComponent,
    ViewTripTikClaimComponent,
    VerificationComponent,
    StringJoinPipe,
    ArrayMapPipe,
    DocumentsRevokeRequestsComponent,
    UpdateStatusTripTikClaimComponent,
    ManageTripTikClaimComponent,
    TripTiksClaimsLogsComponent,
    TripTikClaimAttachmentsComponent,
    RecoverPasswordComponent,
    PursuingZonesComponent,
    CustomsPortsComponent,
    ExpiredDocumentsQueryComponent,
    ViewAccountComponent,
    IssuedDocumentsQueryComponent,
    TransactionDetailsLabelComponent,
    ELicensesComponent,
    CouponManagementComponent,
    ViewCouponManagementComponent,
    ManageCouponManagementComponent,
    ViewLicenseDetailComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    AppRoutingModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MomentDateModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatAutocompleteModule,
    PortalModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    LightboxModule,
    ChartsModule,
    LoggerModule.forRoot({ serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'ar'
    }),
    NgxPrintModule,
    NgxKjuaModule,
    NgSelectModule,
    ColorPickerModule
    // AutocompleteLibModule
  ],
  providers: [
    ApiHandler,
    AuthService,
    httpInterceptorProviders,
    {
      provide: UrlSerializer,
      useClass: UrlSerializerService
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['L', 'l']
        },
        display: {
          dateInput: ['L', 'l'],
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        }
      }
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    // {
    //   provide: APP_INITIALIZER,
    //   multi: true,
    //   deps: [AppConfigService],
    //   useFactory: (appConfigService: AppConfigService) => {
    //     return () => {
    //       //Make sure to return a promise!
    //       return appConfigService.loadAppConfig();
    //     };
    //   }
    // },
    AuthGuardService,
    LoadingService,
    StringJoinPipe,
    SidenavService,
    { provide: MatPaginatorIntl, useClass: LangMatPaginatorIntl },
    { provide: ValidationService, deps: [TranslateService] },
    TranslationResolver,
    //AppConfigResolver,
    ConfigResolver,
    Title,
    BreadcrumbService,
    ConfirmService,
    ConfirmState,
    AlertService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


